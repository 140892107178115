import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { useFormValidation } from "@app/hooks";
import { GenericModalProperties } from "@app/providers/store/genericModal/models";
import { ISessionLoginWithEmailActionParams } from "@app/providers/store/session/models";
import { closeGenericModalAction, openGenericModalAction } from "@app/providers/store/genericModal";
import {
  getSessionLoading,
  loginWithEmailAction,
  verifyLoginWithEmailAction,
} from "@app/providers/store/session";
import messages from "./messages";

import { MagicLinkButton, EmailInput, FormContainer } from "./EmailLogin.style";
import { Config } from "@app/configs";

/**
 * Component that renders the email login functionality in the login page {@see Login}
 * It renders an email input field along with a login button. If the email tha the user
 * passes to the input field is invalid {@see emailValidator} the login button is inactive
 */
const EmailLogin: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const { emailValidation } = useFormValidation();
  const isLoading = useSelector(getSessionLoading);
  const [captcha, setCaptcha] = useState<string | null>(null);

  /**
   * Get the token and the email from the query params
   */
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISessionLoginWithEmailActionParams>();

  /**
   * Use effect that dispatches the verify login email to login the user
   * if the are token and email query params in url
   */
  React.useEffect(() => {
    if (token && email) {
      dispatch(verifyLoginWithEmailAction({ email, token }));
    }
  }, [dispatch, email, searchParams, token]);

  /**
   * Helper function that opens the info modal on success send login email
   * action to inform the user that an email containing the magic link has been
   * sent to his email address {@see loginWithEmailAction}
   */
  const onSuccess = () => {
    const modalParams: GenericModalProperties = {
      isTransparent: true,
      title: formatMessage(messages.title),
      primaryLabel: formatMessage(messages.accept),
      description: formatMessage(messages.description),
      primaryOnPress: () => dispatch(closeGenericModalAction()),
    };

    dispatch(openGenericModalAction(modalParams));
  };

  const onSubmit: SubmitHandler<ISessionLoginWithEmailActionParams> = ({ email }) => {
    dispatch(loginWithEmailAction({ email, app: "webApp" }, onSuccess));
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        rules={emailValidation}
        render={({ field }) => (
          <EmailInput
            error_icon="error"
            value={field.value}
            error={errors.email}
            onChange={field.onChange}
            placeholder={formatMessage(messages.placeholder)}
          />
        )}
      />

      <MagicLinkButton icon="spaceship" isLoading={isLoading} disabled={!captcha}>
        <FormattedMessage {...messages.send_magic_link} />
      </MagicLinkButton>

      <ReCAPTCHA
        className="recaptcha_container"
        onChange={(value) => setCaptcha(value)}
        sitekey={String(Config.RECAPTCHA_SITE_KEY)}
      />
    </FormContainer>
  );
};

export default EmailLogin;
