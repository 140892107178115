import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";

import { Footer } from "@app/components/molecules";

export const GlobalStyle = createGlobalStyle`
  html, body, #root{
    overflow-x: hidden
  }
`;

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-top: 8.83rem;
  padding-bottom: 4.4rem;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40% 50%;
  background-image: url("/assets/svg/login-background.svg");

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    padding-bottom: 1rem;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  width: 90%;
  display: flex;
  max-width: 35rem;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    width: 80%;
  }
`;

export const Title = styled.h2`
  margin-top: 3rem;
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 4.5rem;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.WHITE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 4rem;
  }
`;

export const SubTitle = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  line-height: 32px;
  margin-top: 1.45rem;
  font-family: Signika Negative;
  letter-spacing: -0.00642857px;
  color: ${({ theme }) => theme.colors.CARDINAL_TEAL_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 2.6rem;
  }
`;

export const Login = styled(Title)`
  font-size: 2rem;
  margin-top: 6rem;
  line-height: 2.7rem;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 2.4rem;
  }
`;

export const DividerWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4.6rem;
  align-items: center;
`;

export const DividerText = styled(Login)`
  margin: 0 0.7rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: Raleway;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.SPACE_DEEP_COLOR};
`;

export const Hr = styled.div`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.SPACE_DEEP_COLOR};
`;

export const SocialButtonsWrapper = styled(DividerWrapper)`
  margin-top: 6.3rem;

  button {
    height: 4.8rem;
    .button__icon {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0px -2px 9px rgba(255, 255, 255, 0.47),
        inset 0px 0px 8px 1px rgba(255, 255, 237, 0.51);
      background: ${({ theme }) => theme.colors.WHITE_COLOR};
    }
  }
`;

export const SignupFooter = styled(Footer)`
  margin-top: 5rem;
`;

export const GoToRedeem = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-direction: column;
`;
