import React from "react";
import { FormattedMessage } from "react-intl";

import { priceResolver } from "@app/helpers";
import messages from "./messages";
import { shallowEqual, useSelector } from "react-redux";

import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import {
  Divider,
  ServiceFeeTitle,
  GrandTotalTitle,
  GrandTotalValue,
  ServiceFeesValue,
  SellingPriceTitle,
  SellingPriceValue,
  PriceDetailsWrapper,
  PriceSectionContainer,
} from "./PriceSection.style";

const PriceSection: React.FC<{}> = () => {
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);
  const nftPrice = userSelectedNftDetails?.price;
  const serviceFee = userSelectedNftDetails?.serviceFee;
  const calculateGrandTotalPrice = () =>
    serviceFee
      ? Math.round((priceResolver(nftPrice) + serviceFee * priceResolver(nftPrice)) * 100)/100
      : priceResolver(nftPrice);

  return (
    <PriceSectionContainer>
      <PriceDetailsWrapper>
        <SellingPriceTitle>
          <FormattedMessage {...messages.price_section} />
        </SellingPriceTitle>
        <SellingPriceValue>{`€${priceResolver(nftPrice)}`}</SellingPriceValue>
      </PriceDetailsWrapper>
      <PriceDetailsWrapper>
        <ServiceFeeTitle>
          <FormattedMessage {...messages.service_fee_section} />
        </ServiceFeeTitle>
        <ServiceFeesValue>{`${serviceFee ? serviceFee * 100 : 0}%`}</ServiceFeesValue>
      </PriceDetailsWrapper>
      <PriceDetailsWrapper>
        <GrandTotalTitle>
          <FormattedMessage {...messages.grand_total} />
        </GrandTotalTitle>
        <GrandTotalValue>{`€${calculateGrandTotalPrice()}`}</GrandTotalValue>
      </PriceDetailsWrapper>
      <Divider />
    </PriceSectionContainer>
  );
};

export default PriceSection;
