import { defineMessages } from "react-intl";

const messages = defineMessages({
  pinch_zoom_in_out: {
    id: "nft_details.image_modal.pinch_zoom_in_out",
    defaultMessage: "Pinch to Zoom In & Out",
  },
});

export default messages;
