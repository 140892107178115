import { defineMessages } from "react-intl";

const messages = defineMessages({
  session_termination: {
    id: "profile.session.session_termination",
    defaultMessage: "Session Termination",
  },
  log_out: {
    id: "profile.session.log_out",
    defaultMessage: "Log Out",
  },
  delete_account: {
    id: "profile.session.delete_account",
    defaultMessage: "Delete Your Account",
  },
});

export default messages;
