import React, { useCallback, useEffect } from "react";
import ReactGA from "react-ga";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { translator } from "@app/providers/internationalization/IntlProvider";

import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";
import { openGenericServerErrorModalAction } from "@app/providers/store/genericModal";
import {
  getUserSelectedNftDetails,
  getUserSelectedUserNftDetailsAction,
} from "@app/providers/store/nft";
import { isWhiteListedAction } from "@app/providers/store/redeem";
import { Timer, PriceSection, SummarySection, CardDetailsSection } from "./components";
import messages from "./messages";

import {
  Contents,
  Container,
  GlobalStyle,
  HeaderWrapper,
  CheckoutTitle,
  HeaderBackTitle,
  HeaderBackButton,
} from "./BuyNFTs.style";

const BuyNFTs: React.FC<{}> = () => {
  const { nftId, eventId, nftCategoryId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);

  const isFreeNFT = userSelectedNftDetails?.price === "0.01 COME";

  React.useEffect(() => {
    ReactGA.pageview(`${APP_URL_LIST.BUY_NFTS}/${eventId}/${nftCategoryId}/${nftId}`);
  }, []);

  const onCallback = useCallback(
    (error?: Error) => {
      if (error) {
        dispatch(openGenericServerErrorModalAction({ description: error.message }));
        navigate(APP_URL_LIST.REDEEM_URL);
      }
    },
    [dispatch, navigate]
  );

  /**
   * request for a specific NFT when the component mounts
   */
  useEffect(() => {
    dispatch(getUserSelectedUserNftDetailsAction({ nftId: Number(nftId) }));
  }, [dispatch, nftId]);

  const isWhiteListedCallback = useCallback(
    (error?: Error) => {
      if (error) {
        dispatch(
          openGenericServerErrorModalAction({
            title: translator.formatMessage(messages.not_white_listed),
            description: error?.message,
          })
        );
        navigate(APP_URL_LIST.REDEEM_URL);
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    dispatch(
      isWhiteListedAction(
        {
          eventId: Number(eventId),
          nftId: Number(nftId),
          nftCategoryId: Number(nftCategoryId),
        },
        isWhiteListedCallback
      )
    );
  }, [dispatch, nftId, isWhiteListedCallback, userSelectedNftDetails]);

  useEffect(() => {
    if (isFreeNFT) {
      navigate(APP_URL_LIST.REDEEM_URL);
    }

    return () => {
      if (isFreeNFT) {
        navigate(APP_URL_LIST.REDEEM_URL);
      }
    };
  }, [dispatch, isFreeNFT, navigate, nftId, onCallback]);

  return (
    <Container>
      <GlobalStyle />
      <Contents>
        <HeaderBackButton
          to={APP_URL_LIST.REDEEM_URL}
          onClick={(event) => {
            event.stopPropagation();
            // stop the browser from changing the URL
            event.preventDefault();
            // navigate a step back to redeem screen to avoid errors, if some user already bought this NFT
            navigate(APP_URL_LIST.REDEEM_URL);
          }}
        >
          <Icon name="go-back" />
          <HeaderBackTitle>
            <FormattedMessage {...messages.go_back_to_redeem} />
          </HeaderBackTitle>
        </HeaderBackButton>
        <HeaderWrapper>
          <CheckoutTitle>
            <FormattedMessage {...messages.checkout_title} />
          </CheckoutTitle>
          <Timer />
        </HeaderWrapper>
        <SummarySection />
        <PriceSection />
        <CardDetailsSection />
      </Contents>
    </Container>
  );
};

export default BuyNFTs;
