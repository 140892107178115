import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import InApp from "detect-inapp";

import { Config } from "@app/configs";
import { reportError } from "@app/helpers";
import { getSettingsLocal } from "@app/providers/store/settings";
import { loginWithGoogleAction } from "@app/providers/store/session";
import { ISessionLoginWithGoogleParams } from "@app/providers/store/session/models";

import { GoogleButton } from "./Google.style";
import messages from "../../messages";

/**
 * Component that renders the Google login in Sign up page {@see Signup}
 * along with login with google functionality
 */
const Google: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const locale = useSelector(getSettingsLocal);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);

  /**
   * Helper function that is called with google
   * redirect to complete the login with Google request
   */
  const loginWithGoogleRedirect = useCallback(() => {
    try {
      const url = window.location.href;
      let id_token: ISessionLoginWithGoogleParams["id_token"] = url.match(/id_token=([^&]+)/);

      if (id_token && id_token.length > 0) {
        id_token = id_token[1];
      }

      if (id_token) {
        dispatch(loginWithGoogleAction({ id_token: id_token, language: locale }));
      }
    } catch (error) {
      reportError(error as Error);
    }
  }, [dispatch, locale]);

  /**
   * Use effect that runs the login with google redirect in
   * the signup screen every time the component mounts
   */
  React.useEffect(() => {
    loginWithGoogleRedirect();
  }, [loginWithGoogleRedirect]);

  const handleToaster = () => {
    return toast(formatMessage(messages.inAppBrowserError, { inAppBrowserName: inapp.browser }));
  };

  return inapp.isMobile && inapp.isInApp ? (
    <GoogleButton onClick={handleToaster} />
  ) : (
    <GoogleLogin
      uxMode="redirect"
      clientId={String(Config.GOOGLE_ID)}
      redirectUri={`${Config.FRONTEND_URL}/signup`}
      render={(props) => <GoogleButton {...props} />}
    />
  );
};

export default Google;
