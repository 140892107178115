import styled from "styled-components";

import { Button, Card, Image } from "@app/components/atoms";

export const Container = styled(Card)<{ isSwipeable: boolean }>`
  flex: none;
  width: 94%;
  display: flex;
  height: 40rem;
  max-width: 34rem;
  overflow: hidden;
  position: relative;
  margin-top: 2.4rem;
  align-self: center;
  align-items: center;
  border-radius: 4.2rem;
  flex-direction: column;
  backdrop-filter: blur(33px);
  margin-right: ${({ isSwipeable }) => (isSwipeable ? 1.29 : 0)}rem;
  box-shadow: 5px 3px 8px rgba(28, 30, 45, 0.44), inset 0px 0px 3px rgba(50, 59, 92, 0.47),
    inset 0px 0px 6px rgba(137, 148, 196, 0.1);

  &:hover {
    /* border: 0.2rem ${({ theme }) => theme.colors.IRIS_DARK_COLOR} solid; */

    /* img {
      transform: scale(1.5);
    } */

    .image__backdrop::before {
      opacity: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X}) {
    height: 42.4rem;
  }
`;

export const Title = styled.h3`
  z-index: 1;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
  line-height: 3.4rem;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.WHITE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    font-size: 3.4rem;
    line-height: 3.8rem;
  }
`;

export const NFTImageBackground = styled(Image)`
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: center center;
  transition: transform 1.5s, filter 2s ease-in-out;
`;

export const NFTImageBackgroundContainer = styled.div`
  width: 100%;
  height: 33rem;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-radius: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X}) {
    height: 35.33rem;
  }
`;

export const NFTImageBackDrop = styled.div`
  width: 100%;
  height: 33rem;
  padding: 2.35rem;
  position: absolute;
  padding-top: 5.5rem;
  border-radius: 3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X}) {
    height: 35.33rem;
  }

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    border-radius: 3rem;
    transition: all 1.5s ease-in-out;
    background: linear-gradient(0deg, rgba(10, 11, 20, 0.6), rgba(10, 11, 20, 0.6));
  }
`;

export const NFTNumber = styled.h4`
  z-index: 1;
  font-weight: 600;
  font-size: 1.4rem;
  position: relative;
  width: fit-content;
  margin-top: 1.6rem;
  line-height: 1.8rem;
  border-radius: 2.3rem;
  padding: 0.8rem 2.2rem;
  backdrop-filter: blur(11.7528px);
  background: rgba(50, 59, 92, 0.29);
  color: ${({ theme }) => theme.colors.IRIS_PURE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
`;

export const NFTButton = styled(Button)`
  width: 87%;
  height: 5.6rem;
  bottom: 1.5rem;
  position: relative;
`;
