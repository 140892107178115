import { AxiosResponse } from "axios";
import { ResponseInterfaceWithoutPayload } from "typings";
import {
  ISessionLoginWithAppleParams,
  ISessionLoginWithGoogleParams,
  ISessionLoginWithFacebookParams,
  ISessionLoginWithEmailActionParams,
  ISessionVerifyLoginWithEmailActionParams,
} from "@app/providers/store/session/models";
import { SEND_LOGIN_EMAIL, VERIFY_LOGIN_EMAIL } from "@app/network/urls/login";
import {
  LOGIN_APPLE_URL,
  LOGIN_GOOGLE_URL,
  LOGOUT_A_USER_URL,
  LOGIN_FACEBOOK_URL,
} from "@app/network/urls/auth";
import { network } from "./api";

/**
 *
 * @description verify and login a uniqtogether user with the login via email
 * @function verifyLoginWithEmailApi
 * @property ISessionVerifyLoginWithEmailActionParams
 * @returns Promise<{ jwtToken: string }>
 */
export const verifyLoginWithEmailApi = async (
  payload: ISessionVerifyLoginWithEmailActionParams
): Promise<{ jwtToken: string }> => {
  const { data } = await network.post<{ jwtToken: string }>(VERIFY_LOGIN_EMAIL, { ...payload });

  return data;
};

/**
 *
 * @description login a uniqtogether user via email. To distinguish the mobile app
 * from the web in the web app we send an "app=webApp" parameter to construct the different
 * urlLinks {@see sendEmail service in nftServer for more details}
 * @function loginWithEmailApi
 * @property Omit<ISessionLoginWithEmailActionParams, "callback"> - payload
 * @returns Promise<Omit<ISession, "user">>
 */
export const loginWithEmailApi = async (
  payload: ISessionLoginWithEmailActionParams
): Promise<AxiosResponse<ResponseInterfaceWithoutPayload>> => {
  const response = await network.post<ResponseInterfaceWithoutPayload>(SEND_LOGIN_EMAIL, {
    ...payload,
  });

  return response;
};

/**
 * @description login a uniqtogether user with facebook api
 * @function loginWithFacebookApi
 * @property ISessionLoginWithFacebookParams
 * @returns Promise<{ token: string }>
 */
export const loginWithFacebookApi = async (
  payload: ISessionLoginWithFacebookParams
): Promise<{ token: string }> => {
  const { data } = await network.post<{ token: string }>(LOGIN_FACEBOOK_URL, { ...payload });

  return data;
};

/**
 * @description login a uniqtogether user with google api
 * @function loginWithGoogleRedirectApi
 * @property ISessionLoginWithGoogleParams
 * @returns Promise<{ token: string }>
 */
export const loginWithGoogleRedirectApi = async (
  payload: ISessionLoginWithGoogleParams
): Promise<{ token: string }> => {
  const { data } = await network.post<{ token: string }>(LOGIN_GOOGLE_URL, { ...payload });

  return data;
};

/**
 * @description login a uniqtogether user with apple api
 * @function loginWithAppleApi
 * @property ISessionLoginWithAppleParams
 * @returns Promise<{ token: string }>
 */
export const loginWithAppleApi = async (
  payload: ISessionLoginWithAppleParams
): Promise<{ token: string }> => {
  const { data } = await network.post<{ token: string }>(LOGIN_APPLE_URL, { ...payload });

  return data;
};

/**
 * @description logout a uniqtogether user
 * @function loginOuteApi
 * @property ISessionLoginWithAppleParams
 * @returns Promise<object>
 */
export const loginOuteApi = async (): Promise<ResponseInterfaceWithoutPayload> => {
  const { data } = await network.post<ResponseInterfaceWithoutPayload>(LOGOUT_A_USER_URL);

  return data;
};
