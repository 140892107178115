import { IGenericModalState, GenericModalActionTypes } from "../models";
import { CLOSE_GENERIC_MODAL, OPEN_GENERIC_MODAL } from "../types";

export const __SESSION__REDUX__STATE_KEY__ = "session";

const initialState: IGenericModalState = {
  size: "sm",
  show: false,
};

const genericModalReducer = (
  state: IGenericModalState = initialState,
  action: GenericModalActionTypes
): IGenericModalState => {
  switch (action.type) {
    case OPEN_GENERIC_MODAL:
      return { ...state, show: true, ...action.payload };

    case CLOSE_GENERIC_MODAL:
      return { ...initialState };

    default:
      return state;
  }
};

export default genericModalReducer;
