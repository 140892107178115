import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { Icon } from "@app/components/atoms";
import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import { getNftDetailImageOverviewInstruction } from "@app/providers/store/settings";
import messages from "./messages";
import { ImageModalProps } from "./ImageModal.interface";

import {
  PinchTitle,
  PinchLeftIcon,
  TransformImage,
  PinchRightIcon,
  ZoomOutContainer,
  PinchIconsContainer,
  PinchInstructionContainer,
  ImageTransformComponentContainer,
} from "./ImageModal.style";

const ImageModal: React.FC<ImageModalProps> = ({ setShowImageScreen }) => {
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);
  const isNftDetailImageOverviewInstructionDone = useSelector(getNftDetailImageOverviewInstruction);

  return (
    <Fragment>
      <TransformWrapper disabled={!isNftDetailImageOverviewInstructionDone}>
        <TransformComponent>
          <TransformImage
            nftType={userSelectedNftDetails?.nftType}
            srcList={[
              String(userSelectedNftDetails?.nftAsset),
              String(userSelectedNftDetails?.nftThumbnail),
            ]}
            container={(children) => (
              <ImageTransformComponentContainer
                isNftDetailImageOverviewInstructionDone={isNftDetailImageOverviewInstructionDone}
              >
                {children}

                {!isNftDetailImageOverviewInstructionDone ? (
                  <PinchInstructionContainer>
                    <PinchTitle>
                      <FormattedMessage {...messages.pinch_zoom_in_out} />
                    </PinchTitle>

                    <PinchIconsContainer>
                      <PinchLeftIcon />
                      <PinchRightIcon />
                    </PinchIconsContainer>
                  </PinchInstructionContainer>
                ) : null}
              </ImageTransformComponentContainer>
            )}
          />
        </TransformComponent>
      </TransformWrapper>

      <ZoomOutContainer onClick={setShowImageScreen}>
        <Icon name="zoom-out" />
      </ZoomOutContainer>
    </Fragment>
  );
};

export default ImageModal;
