import { MONTHS } from "@app/constants";

/**
 * Util function that takes a Date of an nft
 * @params Date | the date
 */
const formatNFTCreatedDate = (date?: string | null): string | null => {
  if (!date) return null;
  const [day, month, year] = date.split("-");
  return `${MONTHS[Number(month) - 1]} ${day}, ${year}`;
};

export default formatNFTCreatedDate;
