import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {
  CreatePaymentMethodCardData,
  StripeCardCvcElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from "@stripe/stripe-js";
import { Form } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { reportError } from "@app/helpers";
import { APP_URL_LIST } from "@app/routes/urls";
import {
  openSuccessfulBuyNftModalAction,
  openGenericServerErrorModalAction,
} from "@app/providers/store/genericModal";
import {
  cancelNFTOrderAction,
  createNFTOrderAction,
  createPaymentIntentAction,
} from "@app/providers/store/redeem";
import { GenericModalProperties } from "@app/providers/store/genericModal/models";
import { getUserData } from "@app/providers/store/session";
import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import messages from "./messages";

import {
  Divider,
  EmptyDiv,
  CardInfo,
  Container,
  CardTitle,
  CancelButton,
  CheckoutButton,
  ButtonsContainer,
  CardNumberWrapper,
  PaymentCardContainer,
  StripeCVCStyleOptions,
  StripeCardStyleOptions,
  CardMonthAndCVVWrapper,
  StripeExpiryDateStyleOptions,
} from "./CardDetailsSection.style";

type CardElementStatusType = {
  status: boolean;
  error?: any;
};

type CardElementsStatusType = {
  cardCvc: CardElementStatusType;
  payment?: CardElementStatusType; // for errors that may occur on card payment
  cardNumber: CardElementStatusType;
  cardExpiry: CardElementStatusType;
};

const CardDetailsSection: React.FC<{}> = () => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const user = useSelector(getUserData, shallowEqual);
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);

  const [isCheckoutLoading, setIsCheckoutLoading] = useState<boolean>(false);
  const [cardElementsStatus, setCardElementsStatus] = useState<CardElementsStatusType>({
    cardCvc: { status: false },
    cardNumber: { status: false },
    cardExpiry: { status: false },
  });

  // we store the error messages from all elements but only show the first on the UI
  const cardStatus = React.useMemo(() => {
    const errorMessages: string[] = [];
    let overallStatus = true;
    for (const [, value] of Object.entries(cardElementsStatus)) {
      if (value) {
        // get the error message for each element
        if (value.error && value.error.message) {
          errorMessages.push(value.error.message);
        }
        // chain the completion status of each element to calculate the overall status
        overallStatus = overallStatus && value.status;
      }
    }

    return { complete: overallStatus, errorMessages };
  }, [cardElementsStatus]);

  /**
   * Function that is triggered when the user presses the cancel button.
   * The NFT is unlocked because the BuyNFTs component amounts.
   */
  const handleCancel = () => navigate(APP_URL_LIST.REDEEM_URL);

  /**
   * Util function that is called after the createPaymentIntent
   * request to verify the payment or trigger an error modal in case
   * of error
   * @param data   | The payment intent data from stripe
   * @param error? | The error from server in error response to trigger an error modal
   */
  const onCallback = (data: Record<string, any> | null, error?: Error) => {
    if (error) {
      dispatch(openGenericServerErrorModalAction({ description: error?.message }));
      setIsCheckoutLoading(false);
    } else {
      dispatch(
        createNFTOrderAction(Number(userSelectedNftDetails?.nftId), async (error?: Error) => {
          try {
            if (error) throw error;

            const payment = await stripe?.confirmCardPayment(data?.client_secret, {
              payment_method: {
                card: elements?.getElement("cardNumber") as CreatePaymentMethodCardData["card"],
              },
            });

            if (payment?.error) {
              dispatch(cancelNFTOrderAction(Number(userSelectedNftDetails?.nftId)));
              dispatch(openGenericServerErrorModalAction());
              return setCardElementsStatus({
                ...cardElementsStatus,
                payment: { status: true, error },
              });
            }

            if (payment?.paymentIntent && payment?.paymentIntent?.status === "succeeded") {
              setCardElementsStatus({
                ...cardElementsStatus,
                payment: { status: true, error: undefined },
              });

              const metaData: GenericModalProperties = {
                primaryOnPress: () => navigate(APP_URL_LIST.REDEEM_URL, { replace: true }),
                bottomLinkProps: {
                  onClick: () => navigate(APP_URL_LIST.COLLECTION_URL, { replace: true }),
                },
              };

              if (userSelectedNftDetails?.utility) {
                metaData.description = formatMessage(messages.description, { email: user?.email });
              }

              dispatch(openSuccessfulBuyNftModalAction(metaData));
            }
          } catch (error: any) {
            reportError(error as Error);
            dispatch(openGenericServerErrorModalAction({ description: error?.message }));
          } finally {
            setIsCheckoutLoading(false);
          }
        })
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements || !cardStatus.complete) {
      return;
    }

    setIsCheckoutLoading(true);

    dispatch(createPaymentIntentAction(onCallback));
  };

  const handleCardElementChange:
    | ((
        event:
          | StripeCardNumberElementChangeEvent
          | StripeCardCvcElementChangeEvent
          | StripeCardExpiryElementChangeEvent
      ) => any)
    | undefined = (event) => {
    setCardElementsStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      const currentElementStatus = { status: event.complete, error: event.error };
      newStatus[event.elementType as keyof CardElementsStatusType] = currentElementStatus;
      // clear any errors that may occured during payment
      newStatus["payment" as keyof CardElementsStatusType] = { status: true, error: undefined };
      return newStatus;
    });
  };

  return (
    <Container>
      <CardTitle>
        <FormattedMessage {...messages.title} />
      </CardTitle>
      <CardInfo>
        <FormattedMessage {...messages.info} />
      </CardInfo>
      <Form onSubmit={handleSubmit}>
        <PaymentCardContainer>
          <CardNumberWrapper inputType="cardNumber">
            <CardNumberElement
              className="field"
              options={StripeCardStyleOptions}
              onChange={handleCardElementChange}
            />
          </CardNumberWrapper>
          <CardMonthAndCVVWrapper>
            <CardNumberWrapper inputType="CardExpiry">
              <CardExpiryElement
                className="field"
                options={StripeExpiryDateStyleOptions}
                onChange={handleCardElementChange}
              />
            </CardNumberWrapper>
            <CardNumberWrapper inputType="CVC">
              <CardCvcElement
                className="field"
                options={StripeCVCStyleOptions}
                onChange={handleCardElementChange}
              />
            </CardNumberWrapper>
            <EmptyDiv />
          </CardMonthAndCVVWrapper>
        </PaymentCardContainer>
        <Divider />
        <ButtonsContainer>
          <CancelButton onClick={handleCancel}>
            <FormattedMessage {...messages.cancel} />
          </CancelButton>
          <CheckoutButton
            disabled={!stripe || !elements || !cardStatus.complete}
            isLoading={isCheckoutLoading}
          >
            <FormattedMessage {...messages.checkout} />
          </CheckoutButton>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};

export default CardDetailsSection;
