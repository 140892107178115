/**
 * @description Util function that converts the default price format "10.00 COME"
 * to a real price
 * @function priceResolver
 * @property price {string} kind of "8.00 COME"
 * @returns number
 */
const priceResolver = (price: string | undefined): number => {
  return Number(price?.split(" ")[0] || 0);
};

export default priceResolver;
