/**
 * Constant that denotes the url for getting user redeem nft collection
 */
export const USER_REDEEM_COLLECTION_URL = "/redeem/getRedeemEvents";

/**
 * Constant that denotes the url for getting user redeem nft catergories
 */
export const REDEEM_CATEGORY_NFT_URL = "/redeem/getCategoryNFTs";

/**
 * Constant that denotes the url for adding user redeem nft to his/her collection
 */
export const ADD_REDEEM_TO_USER_COLLECTION_URL = "vn/redeem/issueFreeNft";

/**
 * Constant that denotes the url for getting the details of a user selected nft
 */
export const GET_USER_SELECTED_REDEEM_NFT_DETAIL_URL = "/redeem/getSelectedNft";

/**
 * Constant that denotes the url for checking if a user is whitelisted for a specific nft category
 */
export const IS_WHITE_LISTED_URL = "/vn/redeem/isWhiteListed";

/**
 * Constant that denotes the url for canceling an NFT order
 * Typically it unlocks the NFT
 */
export const CANCEL_ORDER_NFT_URL = "vn/redeem/unlockNFT";

/**
 * Constant that denotes the url for creating an NFT order
 * Typically it locks the NFT for current user, in order to buy it
 */
export const CREATE_ORDER_NFT_URL = "vn/redeem/lockNFT";

/**
 * Constant that denotes the url for create payment intent
 */
export const CREATE_PAYMENT_INTENT_URL = "stripe/createPaymentIntent";
