import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const ZoomOut = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3667 8.11836C19.362 7.5661 18.9105 7.12224 18.3582 7.12697L15.3054 7.15315L19.9995 2.45906C20.39 2.06854 20.39 1.43537 19.9995 1.04485C19.609 0.654322 18.9758 0.654322 18.5853 1.04485L13.9549 5.67527L13.9177 2.66562C13.9109 2.11337 13.4577 1.67122 12.9055 1.67804C12.3532 1.68486 11.9111 2.13807 11.9179 2.69031L11.9857 8.18543C11.9925 8.7362 12.4434 9.17777 12.9942 9.17304L18.3753 9.1269C18.9276 9.12216 19.3715 8.67062 19.3667 8.11836ZM1.63369 12.8824C1.63842 13.4347 2.08996 13.8785 2.64223 13.8738L5.69499 13.8476L1.0009 18.5417C0.610377 18.9322 0.610377 19.5654 1.0009 19.9559C1.39143 20.3464 2.02459 20.3464 2.41511 19.9559L7.04554 15.3255L7.0827 18.3351C7.08952 18.8874 7.54273 19.3295 8.09497 19.3227C8.64721 19.3159 9.08937 18.8627 9.08255 18.3104L9.0147 12.8153C9.0079 12.2646 8.55699 11.823 8.0062 11.8277L2.62508 11.8739C2.07281 11.8786 1.62895 12.3301 1.63369 12.8824Z"
        fill={colors[color || "WHITE_COLOR"]}
      />
    </svg>
  );
};

export default ZoomOut;
