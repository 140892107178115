import * as Sentry from "@sentry/react";

import Routes from "@app/routes";
import Providers from "@app/providers";
import { ErrorFallback } from "@app/components/atoms";

export default function App() {
  return (
    <Sentry.ErrorBoundary fallback={(props) => <ErrorFallback {...props} />}>
      <Providers>
        <Routes />
      </Providers>
    </Sentry.ErrorBoundary>
  );
}
