import styled, { DefaultTheme } from "styled-components";

export const Container = styled.div`
  align-items: flex-start;
`;

export const Title = styled.p`
  font-size: 3.4rem;
  line-height: 4rem;
  font-weight: bold;
  margin-top: 1.6rem;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    display: none;
  }
`;

export const NFTNumber = styled(Title)`
  display: inherit;
  font-weight: 600;
  font-size: 2.4rem;
  margin-top: 1.2rem;
  line-height: 2.7rem;
  color: ${({ theme }) => theme.colors.SPACE_DARK_COLOR};
`;

export const Collection = styled(Title)`
  display: inherit;
  font-size: 2rem;
  margin-top: 1.2rem;
  line-height: 2.2rem;
  text-transform: capitalize;
`;

export const Description = styled.span<{ color?: keyof DefaultTheme["colors"] }>`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 3rem;
  padding-right: 3rem;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  margin-left: ${({ color }) => (color ? 0.5 : 0)}rem;
  color: ${({ theme, color }) => theme.colors[color || "SPACE_PURE_COLOR"]};
`;
