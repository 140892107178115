import { defineMessages } from "react-intl";

const messages = defineMessages({
  account: {
    id: "profile.account.account",
    defaultMessage: "Account",
  },
  referral_link: {
    id: "profile.account.referral_link",
    defaultMessage: "Referral Link",
  },
  copy: {
    id: "profile.account.copy",
    defaultMessage: "Copy",
  },
  copied_referral_link: {
    id: "profile.account.copied_referral_link",
    defaultMessage: "🦄 Referral Link Copied Successfully",
  },
});

export default messages;
