import styled, { css, DefaultTheme } from "styled-components";
import { Link as __Link } from "react-router-dom";

export const Container = styled.footer`
  z-index: 999;
  display: flex;
  min-width: 90%;
  max-width: 80%;
  align-items: center;
  padding-bottom: 2rem;
  flex-direction: column;
  justify-content: flex-end;

  .desktop_uniq_together {
    display: none;
  }

  .mobile_uniq_together {
    margin-top: 5.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X_PLUS}) {
    min-width: 35rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    height: 7rem;
    min-width: 90%;
    padding-bottom: 0;
    flex-direction: row;
    justify-content: space-between;

    .desktop_uniq_together {
      display: block;
    }

    .mobile_uniq_together {
      display: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    min-width: 80%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_DESKTOP_VIEW}) {
    min-width: 70%;
  }
`;

export const FooterRowWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    margin-right: 2rem;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;

    p {
      margin: 0 1.6rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_DESKTOP_VIEW}) {
    p {
      margin: 0 3.6rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    margin-right: 8.7rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    margin: 0;
    justify-content: center;
  }
`;

export const FooterText = styled.p<{ color?: keyof DefaultTheme["colors"]; isActive?: boolean }>`
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 1.2rem;
  line-height: 1.8rem;
  font-family: Raleway;
  letter-spacing: -0.02em;
  color: ${({ theme, color }) => theme.colors[color || "SPACE_DARK_COLOR"]};

  ${({ color }) => (!color ? `cursor: pointer` : undefined)};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    margin: 0;
    padding: 0;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.colors.SPACE_PURE_COLOR};
    `}
`;

export const Link = styled(__Link)`
  text-decoration: none;

  &:link,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }
`;

export const LanguageWrapper = styled.div`
  width: 100%;
  display: flex;
  width: fit-content;
  margin-top: 4.4rem;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    margin: 0;
    flex-direction: row;

    div:first-child {
      p {
        margin-right: 3.2rem;
      }
    }
  }
`;

export const Language = styled.div<{ isSelected: boolean }>`
  margin: auto;
  padding: 0 3rem;
  width: fit-content;
  line-height: 1.7rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    margin: 0;
    padding: 0;
  }

  ${({ isSelected, theme }) => {
    if (isSelected) {
      return css`
        p {
          color: ${theme.colors.SPACE_PURE_COLOR};
        }
      `;
    }
  }}
`;
