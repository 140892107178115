import { defineMessages } from "react-intl";

const messages = defineMessages({
  price_section: {
    id: "price.price_section",
    defaultMessage: "Selling Price",
  },
  service_fee_section: {
    id: "price.service_fee_section",
    defaultMessage: "Service Fees",
  },
  grand_total: {
    id: "price.grand_total",
    defaultMessage: "Grand Total",
  },
});

export default messages;
