import { IStateTree } from "typings/store";

import { __REDEEM__REDUX__STATE_KEY__ } from "../types";

export const getReduxStateSlice = (state: IStateTree) => state[__REDEEM__REDUX__STATE_KEY__];

export const getUserRedeemNfts = (
  state: IStateTree
): Pick<IStateTree["redeem"], "redeemNFTs" | "isLoading"> => {
  const { isLoading, redeemNFTs } = getReduxStateSlice(state);
  return { isLoading, redeemNFTs };
};

export const getUserSelectedRedeemNftDetails = (
  state: IStateTree
): IStateTree["redeem"]["userSelectedRedeemNFTDetails"] => {
  return getReduxStateSlice(state)?.userSelectedRedeemNFTDetails;
};

export const getRedeemCollectionNfts = (
  state: IStateTree
): Pick<IStateTree["redeem"], "collectionNFTs" | "isLoading"> => {
  const { isLoading, collectionNFTs } = getReduxStateSlice(state);
  return { isLoading, collectionNFTs };
};
