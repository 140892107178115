import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { getUserToken } from "@app/providers/store/session/selectors/selectors";
import { APP_URL_LIST, PRIVATE_ROUTES, PUBLIC_AND_PRIVATE_ROUTES } from "./urls";

export const isPrivateRoute = (route: APP_URL_LIST, routeList?: APP_URL_LIST[]): boolean => {
  const paths = routeList || PRIVATE_ROUTES;
  const hasPartialMatch = paths.some((routeFromList) => route.startsWith(routeFromList)); // for routes that contain parameters example: `${APP_URL_LIST.REDEEM_URL}/:id/:name`
  const match = paths.includes(route);
  return hasPartialMatch || match;
};

export const isRedeemRoute = (route: APP_URL_LIST): boolean => {
  // check if the route is a redeem route also if that path contain parameters example: `${APP_URL_LIST.REDEEM_URL}/:id/:name`
  return route === APP_URL_LIST.REDEEM_URL || route.startsWith(APP_URL_LIST.REDEEM_URL);
};

export const isCollectionRoute = (route: APP_URL_LIST): boolean => {
  // check if the route is a collection route also if that path contain parameters example: `${APP_URL_LIST.COLLECTION_URL}/:id/:name`
  return route === APP_URL_LIST.COLLECTION_URL || route.startsWith(APP_URL_LIST.COLLECTION_URL);
};

export const PrivateRoute = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  const userToken = useSelector(getUserToken);
  const isRedeem = isRedeemRoute(location.pathname as APP_URL_LIST);
  const isCollection = isCollectionRoute(location.pathname as APP_URL_LIST);

  location.state = { ...(location?.state as object), isRedeem, isCollection };

  // user is logged in and trying to navigate to root directory [/]
  if (location.pathname === APP_URL_LIST.BASE_URL) {
    return <Navigate to={APP_URL_LIST.REDEEM_URL} replace />;
  }

  // user is logged in and try's to access authenticated routes
  if (userToken && isPrivateRoute(location.pathname as APP_URL_LIST)) {
    return children;
  }

  // user try's to access redeem routes
  if (isPrivateRoute(location.pathname as APP_URL_LIST, PUBLIC_AND_PRIVATE_ROUTES)) {
    return children;
  }

  return <Navigate to={APP_URL_LIST.SIGNUP_URL} replace state={{ path: location.pathname }} />;
};

export const PublicRoute = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation() as CTLocation;
  const userToken = useSelector(getUserToken);

  const statePathname = localStorage.getItem("state_pathname");
  const path = statePathname || APP_URL_LIST.REDEEM_URL;

  // user is logged in and trying to navigate to non-logged-in routes
  if (userToken && location.pathname === APP_URL_LIST.SIGNUP_URL) {
    setTimeout(() => {
      // Wait for the navigation to complete before clearing the storage of the previous route
      localStorage.removeItem("state_pathname");
    }, 2000);

    return <Navigate replace to={path} state={{ replace: true, isRedeem: true }} />;
  }

  // user is not logged in and try's to navigate to root directory [/]
  if (location.pathname === APP_URL_LIST.BASE_URL) {
    return <Navigate to={APP_URL_LIST.REDEEM_URL} replace />;
  }

  return children;
};
