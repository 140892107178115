import { useEffect, useState } from "react";

import { INftTypes, IUserNftType } from "@app/providers/store/nft/models";
import { responseToFile, arrayBufferToBlob, webShareFiles } from "@app/helpers";

export const useWebShare = ({ nft, src }: { nft: IUserNftType | null; src?: string }) => {
  const [files, setFiles] = useState<File[]>([]);

  const fileType =
    nft?.nftType === INftTypes.SOUND || nft?.nftType === INftTypes.VIDEO
      ? "video/mp4"
      : "image/png";

  const source =
    nft?.nftType === INftTypes.SOUND || nft?.nftType === INftTypes.VIDEO
      ? nft?.sampleVideo
      : String(src);

  useEffect(() => {
    const fetchFile = async (url: string) => {
      if (!nft) return;

      try {
        const responseMap: Record<INftTypes, keyof Response> = {
          [INftTypes.GIF]: "blob",
          [INftTypes.IMAGE]: "blob",
          [INftTypes.VIDEO]: "arrayBuffer",
          [INftTypes.SOUND]: "arrayBuffer",
        };

        const callbackName = responseMap[nft.nftType];

        const response = await fetch(url);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const data = await response[callbackName]();

        const [, ext] = fileType.split("/");
        const fileName = `${nft.nftName}.${ext}`;

        const responseToFileMap = {
          [INftTypes.GIF]: responseToFile(data, fileName, fileType),
          [INftTypes.IMAGE]: responseToFile(data, fileName, fileType),
          [INftTypes.VIDEO]: responseToFile(arrayBufferToBlob(data), fileName, fileType),
          [INftTypes.SOUND]: responseToFile(arrayBufferToBlob(data), fileName, fileType),
        };

        const files = [responseToFileMap[nft.nftType]];
        setFiles(files);
      } catch (error) {
        console.log(error);
      }
    };

    if (nft && src) {
      fetchFile(source);
    }
  }, [src, nft, fileType, source]);

  const share = () => webShareFiles({ files });

  return share;
};
