import { Fragment } from "react";
import { useImage } from "react-image";
import { useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { useWebShare } from "@app/hooks";
import { Icon } from "@app/components/atoms";
import { imageDownload } from "@app/helpers";
import { INftTypes } from "@app/providers/store/nft/models";
import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import { NFTDetailsActionsProps } from "./NFTDetailsActions.interface";

import {
  Container,
  PlayContainer,
  ShareContainer,
  ZoomInContainer,
  DownloadContainer,
} from "./NFTDetailsActions.style";

const NFTDetailsActions: React.FC<NFTDetailsActionsProps> = () => {
  const { state } = useLocation() as CTLocation;

  const isShareAvailable = !!navigator.share && !!navigator.canShare;
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);
  const srcList = [
    String(userSelectedNftDetails?.nftAsset),
    String(userSelectedNftDetails?.nftThumbnail),
  ];

  const { src } = useImage({ srcList, useSuspense: false });

  const webShareFiles = useWebShare({ nft: userSelectedNftDetails, src });

  const handleShareNFT = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    // stop the browser from changing the URL
    event.preventDefault();

    webShareFiles();
  };

  const handleImageDownload = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    // stop the browser from changing the URL
    event.preventDefault();

    const fileName = `${userSelectedNftDetails?.nftName}.${
      userSelectedNftDetails?.nftType === INftTypes.GIF ? "gif" : "png"
    }`;

    imageDownload(String(src), fileName);
  };

  return (
    <Container>
      {userSelectedNftDetails?.nftType === INftTypes.IMAGE ||
      userSelectedNftDetails?.nftType === INftTypes.GIF ? (
        <Fragment>
          {isShareAvailable && state?.isCollection ? (
            <ShareContainer onClick={handleShareNFT}>
              <Icon name="share" color="WHITE_COLOR" />
            </ShareContainer>
          ) : null}

          {state?.isCollection ? (
            <DownloadContainer onClick={handleImageDownload}>
              <Icon name="download" color="WHITE_COLOR" />
            </DownloadContainer>
          ) : null}

          <ZoomInContainer>
            <Icon name="zoom-in" />
          </ZoomInContainer>
        </Fragment>
      ) : null}

      {userSelectedNftDetails?.nftType === INftTypes.SOUND ||
      userSelectedNftDetails?.nftType === INftTypes.VIDEO ? (
        <Fragment>
          {isShareAvailable && state?.isCollection ? (
            <ShareContainer onClick={handleShareNFT}>
              <Icon name="share" color="WHITE_COLOR" />
            </ShareContainer>
          ) : null}

          {state?.isCollection ? (
            <DownloadContainer onClick={handleImageDownload}>
              <Icon name="download" color="WHITE_COLOR" />
            </DownloadContainer>
          ) : null}
          <PlayContainer>
            <Icon name="play" />
          </PlayContainer>
        </Fragment>
      ) : null}
    </Container>
  );
};

export default NFTDetailsActions;
