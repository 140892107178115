import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Timer = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74952 4.49512C9.74952 5.0474 10.1972 5.49512 10.7495 5.49512C11.3018 5.49512 11.7495 5.0474 11.7495 4.49512V1.51768C11.7495 0.9654 11.3018 0.517685 10.7495 0.517685C10.726 0.517685 10.7027 0.518496 10.6796 0.520091C10.6576 0.518483 10.6352 0.517725 10.6127 0.517853C10.5418 0.518254 10.471 0.519406 10.4003 0.521306C10.2347 0.525754 10.0695 0.534301 9.90475 0.546908C8.16009 0.680435 6.47053 1.26945 5.01068 2.26817C3.09701 3.57736 1.69649 5.5096 1.04774 7.73564C0.398999 9.96169 0.542177 12.3438 1.45288 14.4761C2.36358 16.6084 3.98546 18.359 6.04217 19.4296C8.09888 20.5001 10.4632 20.8244 12.7322 20.3472C15.0012 19.87 17.0345 18.6208 18.4858 16.8125C19.937 15.0041 20.7163 12.7486 20.6909 10.43C20.6716 8.66137 20.1848 6.93956 19.2934 5.43389C19.2092 5.29173 19.1215 5.15148 19.0301 5.01332C18.9911 4.95429 18.9514 4.89563 18.9111 4.83736C18.6186 4.41453 18.0273 4.36536 17.6334 4.69588C17.2844 4.98873 17.2083 5.48117 17.3927 5.88797C17.4165 5.94029 17.4445 5.9912 17.4768 6.04009C17.5427 6.13977 17.6063 6.24079 17.6676 6.34303C18.4084 7.5783 18.8131 8.99487 18.829 10.4504C18.8497 12.3382 18.2152 14.1747 17.0336 15.6471C15.852 17.1194 14.1964 18.1365 12.3489 18.5251C10.5015 18.9136 8.57648 18.6496 6.90189 17.7779C5.2273 16.9063 3.90675 15.4809 3.16525 13.7448C2.42374 12.0086 2.30717 10.0691 2.83538 8.25662C3.36359 6.44415 4.50391 4.87091 6.06204 3.80495C7.16753 3.04865 8.43482 2.58092 9.74952 2.43173V4.49512ZM10.4703 10.5401L11.9835 9.60735L11.0508 11.1206C10.964 11.2613 10.803 11.3384 10.639 11.3178C10.4477 11.2937 10.2971 11.1431 10.273 10.9518C10.2524 10.7878 10.3295 10.6268 10.4703 10.5401ZM12.7533 12.17L15.7723 7.27199L15.9607 6.96641L16.3368 6.35613L16.5257 6.04972C16.9214 5.40773 16.1831 4.66941 15.5411 5.06512L15.2347 5.25398L14.6244 5.63014L14.3188 5.81849L9.42084 8.83749C8.61288 9.3355 8.17028 10.2596 8.28865 11.2013C8.42669 12.2993 9.2915 13.1641 10.3896 13.3022C11.3313 13.4205 12.2553 12.9779 12.7533 12.17Z"
        fill={colors[color || "SPACE_PURE_COLOR"]}
      />
    </svg>
  );
};

export default Timer;
