import React from "react";

import Gif from "./assets/gif";
import Buy from "./assets/buy";
import Logo from "./assets/logo";
import Hunt from "./assets/hunt";
import Copy from "./assets/copy";
import Play from "./assets/play";
import Edit from "./assets/edit";
import Audio from "./assets/audio";
import Video from "./assets/video";
import Share from "./assets/share";
import Email from "./assets/email";
import Timer from "./assets/timer";
import Close from "./assets/close";
import Apple from "./assets/apple";
import Error from "./assets/error";
import Delete from "./assets/delete";
import Logout from "./assets/logout";
import Wallet from "./assets/wallet";
import ZoomIn from "./assets/zoomIn";
import LogoIcon from "./assets/icon";
import Google from "./assets/google";
import GoBack from "./assets/goBack";
import Redeem from "./assets/redeem";
import ZoomOut from "./assets/zoomOut";
import Loading from "./assets/loading";
import Profile from "./assets/profile";
import SellNft from "./assets/sellNft";
import Download from "./assets/download";
import Facebook from "./assets/facebook";
import Withdraw from "./assets/withdraw";
import Spaceship from "./assets/spaceship";
import UnSellNft from "./assets/unSellNft";
import GridTiles from "./assets/gridTiles";
import PinchArrow from "./assets/pinchArrow";
import RightArrow from "./assets/rightArrow";
import GooglePlaystore from "./assets/googlePlay";
import GridParticles from "./assets/gridParticles";

import { IconProps, IconType } from "./Icon.interface";

export const Icon: React.FC<IconProps & { name: IconType }> = ({ name, ...props }) => {
  switch (name) {
    case "loading": {
      return <Loading {...props} />;
    }

    case "download": {
      return <Download {...props} />;
    }

    case "share": {
      return <Share {...props} />;
    }

    case "sellNFT": {
      return <SellNft {...props} />;
    }

    case "withdraw": {
      return <Withdraw {...props} />;
    }

    case "unSellNft": {
      return <UnSellNft {...props} />;
    }

    case "copy": {
      return <Copy {...props} />;
    }

    case "google-playstore": {
      return <GooglePlaystore {...props} />;
    }

    case "pinch-arrow": {
      return <PinchArrow {...props} />;
    }

    case "zoom-out": {
      return <ZoomOut {...props} />;
    }

    case "zoom-in": {
      return <ZoomIn {...props} />;
    }

    case "edit": {
      return <Edit {...props} />;
    }

    case "logout": {
      return <Logout {...props} />;
    }

    case "delete": {
      return <Delete {...props} />;
    }

    case "gif": {
      return <Gif {...props} />;
    }

    case "right-arrow": {
      return <RightArrow {...props} />;
    }

    case "go-back": {
      return <GoBack {...props} />;
    }

    case "wallet": {
      return <Wallet {...props} />;
    }

    case "video": {
      return <Video {...props} />;
    }

    case "play": {
      return <Play {...props} />;
    }

    case "audio": {
      return <Audio {...props} />;
    }

    case "profile": {
      return <Profile {...props} />;
    }

    case "grid-tiles": {
      return <GridTiles {...props} />;
    }

    case "grid-particles": {
      return <GridParticles {...props} />;
    }

    case "hunt": {
      return <Hunt {...props} />;
    }

    case "redeem": {
      return <Redeem {...props} />;
    }

    case "icon": {
      return <LogoIcon {...props} />;
    }

    case "apple": {
      return <Apple {...props} />;
    }

    case "error": {
      return <Error {...props} />;
    }

    case "google": {
      return <Google {...props} />;
    }

    case "facebook": {
      return <Facebook {...props} />;
    }

    case "spaceship": {
      return <Spaceship {...props} />;
    }

    case "logo": {
      return <Logo {...props} />;
    }

    case "close": {
      return <Close {...props} />;
    }

    case "email": {
      return <Email {...props} />;
    }

    case "buy": {
      return <Buy {...props} />;
    }

    case "timer": {
      return <Timer {...props} />;
    }
    default:
      return null;
  }
};
