import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Profile = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={colors[color || "SPACE_DARK_COLOR"]}
        d="M11.9509 4.92858C11.9509 6.21322 10.9095 7.25463 9.62482 7.25463C8.34018 7.25463 7.29877 6.21322 7.29877 4.92858C7.29877 3.64394 8.34018 2.60254 9.62482 2.60254C10.9095 2.60254 11.9509 3.64394 11.9509 4.92858ZM13.9509 4.92858C13.9509 7.31779 12.014 9.25463 9.62482 9.25463C7.23561 9.25463 5.29877 7.31779 5.29877 4.92858C5.29877 2.53937 7.23561 0.602539 9.62482 0.602539C12.014 0.602539 13.9509 2.53937 13.9509 4.92858ZM16.6554 16.2174C16.6554 16.8261 16.1621 17.3195 15.5534 17.3195H3.69656C3.08793 17.3195 2.59454 16.8261 2.59454 16.2174C2.59454 14.5606 3.93769 13.2174 5.59454 13.2174H9.62499H13.6554C15.3123 13.2174 16.6554 14.5606 16.6554 16.2174ZM0.594543 16.2174C0.594543 13.456 2.83312 11.2174 5.59454 11.2174H9.62499H13.6554C16.4169 11.2174 18.6554 13.456 18.6554 16.2174V18.3195C18.6554 18.8717 18.2077 19.3195 17.6554 19.3195H1.59454C1.04226 19.3195 0.594543 18.8717 0.594543 18.3195V16.2174Z"
      />
    </svg>
  );
};

export default Profile;
