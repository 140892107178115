import styled, { css } from "styled-components";

import { Contents as AccountContents, CopyButton } from "../Account/Account.style";

export { Title, Container, SubTitle } from "../Account/Account.style";

export const Contents = styled(AccountContents)<{ marginBottom?: boolean }>`
  margin: 0;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: 2rem;

      @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
        margin-bottom: 5.6rem;
      }
    `}
`;

export const EditButton = styled(CopyButton)`
  svg {
    margin: 0 !important;
  }
`;

export const Divider = styled.div`
  opacity: 0.1;
  margin: 2rem 0;
  border: 1px solid ${({ theme }) => theme.colors.IRIS_DARK_COLOR};
`;
