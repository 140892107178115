import styled, { css } from "styled-components";

import { Icon } from "@app/components/atoms";

import { ZoomInContainer } from "../NFTDetailsActions/NFTDetailsActions.style";
import { Image } from "../../NftDetail.styles";
import { INftTypes } from "@app/providers/store/nft/models";

export const TransformImage = styled(Image)<{ nftType: INftTypes | undefined }>`
  object-fit: ${({ nftType }) => (nftType === INftTypes["SOUND"] ? "contain" : "cover")};
`;

export const ImageTransformComponentContainer = styled.div<{
  isNftDetailImageOverviewInstructionDone?: boolean;
}>`
  width: 100%;
  height: 93vh;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    width: 69.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    height: 80vh;
  }

  ${({ isNftDetailImageOverviewInstructionDone }) =>
    !isNftDetailImageOverviewInstructionDone &&
    css`
      img {
        opacity: 0.2;
      }
    `}
`;

export const PinchInstructionContainer = styled.div`
  display: flex;
  min-width: 15rem;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

export const PinchIconsContainer = styled(PinchInstructionContainer)`
  top: 0.1rem;
  width: fill-available;
  transform: rotate(-45deg);
  justify-content: space-between;
`;

export const PinchRightIcon = styled(Icon).attrs({ name: "pinch-arrow" })`
  transform: rotate(45deg);
`;

export const PinchLeftIcon = styled(PinchRightIcon)`
  transform: rotate(225deg);
`;

export const PinchTitle = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.5rem;
  letter-spacing: -0.00642857px;
  font-family: "Signika Negative";
  color: ${({ theme }) => theme.colors.WHITE_COLOR};
`;

export const ZoomOutContainer = styled(ZoomInContainer)`
  top: 8rem;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    top: 10rem;
    right: 6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    right: 15rem;
  }
`;
