import { defineMessages } from "react-intl";
import { Locale } from "@app/providers/store/settings/models";

const messages = defineMessages({
  title: {
    id: "footer.title",
    defaultMessage: "UniqTogether",
  },
  subtitle: {
    id: "footer.subtitle",
    defaultMessage: "NFT Collectibles Portal",
  },
  login: {
    id: "footer.login",
    defaultMessage: "Log In",
  },
  send_magic_link: {
    id: "footer.send_magic_link",
    defaultMessage: "Send Me the Magic Link",
  },
  uniq_together: {
    id: "footer.uniq_together",
    defaultMessage: "©{year}, UniqTogether",
  },
  [Locale.ENGLISH]: {
    id: "footer.english",
    defaultMessage: "{shouldTruncateLanguage, select, true {En} false {English} other {English}}",
  },
  [Locale.GREEK]: {
    id: "footer.greek",
    defaultMessage: "{shouldTruncateLanguage, select, true {ελ} false {Ελληνικά} other {Ελληνικά}}",
  },
  terms_and_conditions: {
    id: "footer.terms_and_conditions",
    defaultMessage: "Terms & Conditions",
  },
  privacy_policy: {
    id: "footer.privacy_policy",
    defaultMessage: "Privacy Policy",
  },
  report_a_bug: {
    id: "footer.report_a_bug",
    defaultMessage: "Report A Bug",
  },
});

export default messages;
