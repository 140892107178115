import styled from "styled-components";

export { ZoomOutContainer } from "../AudioModal/AudioModal.style";

export const Container = styled.div`
  flex: 1;

  .player-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    position: absolute;
    max-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO};
    height: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_SAMSUNG_SFIVE};

    @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
      height: ${({ theme }) => theme.breakpoints.MOBILE};
    }
  }
`;
