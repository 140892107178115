import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const PinchArrow = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 46 46"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.3115 3.18753C45.3115 1.80681 44.1923 0.687527 42.8115 0.687527L20.3116 0.687528C18.9308 0.687528 17.8116 1.80682 17.8116 3.18753C17.8116 4.56824 18.9308 5.68753 20.3116 5.68753L40.3116 5.68753L40.3116 25.6875C40.3116 27.0682 41.4308 28.1875 42.8116 28.1875C44.1923 28.1875 45.3116 27.0682 45.3116 25.6875L45.3115 3.18753ZM1.30626 41.1572C0.329947 42.1336 0.329947 43.7165 1.30626 44.6928C2.28257 45.6691 3.86548 45.6691 4.84179 44.6928L1.30626 41.1572ZM41.0438 1.41976C26.0444 16.4191 12.431 30.0325 1.30626 41.1572L4.84179 44.6928C15.9666 33.568 29.5799 19.9547 44.5793 4.95529L41.0438 1.41976Z"
        fill={colors[color || "WHITE_COLOR"]}
      />
    </svg>
  );
};

export default PinchArrow;
