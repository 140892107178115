import { defineMessages } from "react-intl";
import nftRedeemCardMessages from "@app/components/molecules/NftRedeemCard/messages";

const messages = defineMessages({
  ...nftRedeemCardMessages,
  modal_primary_label: {
    id: "buy_nfts.modal_primary_label",
    defaultMessage: "Back To MarketPlace",
  },
  modal_title: {
    id: "buy_nfts.modal_title",
    defaultMessage: "Your time for the checkout session is expired and you must start again.",
  },
  go_back_to_redeem: {
    id: "buy_nfts.go_back_to_redeem",
    defaultMessage: "Back To MarketPlace",
  },
  checkout_title: {
    id: "buy_nfts.checkout_title",
    defaultMessage: "Checkout",
  },
});

export default messages;
