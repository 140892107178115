import styled from "styled-components";

export const TimerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TimerText = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  font-style: normal;
  margin-right: 1.5rem;
  font-family: "Philosopher";
  color: ${({ theme }) => theme.colors.CANDY_RED_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 1.5rem;
    margin-right: 17px;
    line-height: 1.8rem;
  }
`;
