import React from "react";
import AppleSignin from "react-apple-signin-auth";
import { useDispatch, useSelector } from "react-redux";

import { Config } from "@app/configs";
import { AppleButton } from "./Apple.style";
import { reportError } from "@app/helpers";
import { getSettingsLocal } from "@app/providers/store/settings";
import { loginWithAppleAction } from "@app/providers/store/session";
import { AppleAuthRequestResponse } from "@app/providers/store/session/models";

/**
 * Component that renders the Apple Login Button
 * in signup {@see Signup}
 * along with Apple login functionality
 */
const Apple: React.FC = () => {
  const dispatch = useDispatch();
  const locale = useSelector(getSettingsLocal);

  const appleCallback = (response: AppleAuthRequestResponse) => {
    const payload = { appleAuthRequestResponse: response, language: locale };
    dispatch(loginWithAppleAction(payload));
  };

  return (
    <AppleSignin
      authOptions={{
        nonce: "nonce",
        /** Uses popup auth instead of redirection */
        usePopup: true,
        scope: "email name",
        clientId: String(Config.APPLE_ID),
        redirectURI: `${Config.FRONTEND_URL}/signup`,
      }}
      onError={reportError}
      onSuccess={appleCallback}
      render={({ onClick }) => <AppleButton onClick={onClick} />}
    />
  );
};

export default Apple;
