import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Close = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={colors[color || "GRAY_SCALE"]}
        d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10ZM9.17 14.83l5.66-5.66M14.83 14.83 9.17 9.17"
      />
    </svg>
  );
};

export default Close;
