import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { logoutUserAction } from "@app/providers/store/session";
import { openDeleteAccountConfirmationModalAction } from "@app/providers/store/genericModal";
import messages from "./messages";

import { Container, Title, LogoutButton, Divider } from "./SessionTermination.style";

const SessionTermination: React.FC<{}> = () => {
  const dispatch = useDispatch();

  return (
    <Container>
      <Title>
        <FormattedMessage {...messages.session_termination} />
      </Title>

      <LogoutButton
        icon="logout"
        color="CARDINAL_TEAL_COLOR"
        onClick={() => dispatch(logoutUserAction())}
      >
        <FormattedMessage {...messages.log_out} />
      </LogoutButton>
      <Divider />

      <LogoutButton
        icon="delete"
        color="BUTTON_DELETE_COLOR"
        onClick={() => dispatch(openDeleteAccountConfirmationModalAction())}
      >
        <FormattedMessage {...messages.delete_account} />
      </LogoutButton>
    </Container>
  );
};

export default SessionTermination;
