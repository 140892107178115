import styled, { css } from "styled-components";

import { Button, Card, Image } from "@app/components/atoms";

export const Container = styled(Card)<{ colors: string[]; isMinimized: boolean }>`
  flex: none;
  display: flex;
  min-width: 30rem;
  overflow: hidden;
  align-self: center;
  position: relative;
  align-items: center;
  margin-bottom: 2rem;
  border-radius: 4.2rem;
  flex-direction: column;
  backdrop-filter: blur(33px);
  height: ${({ isMinimized }) => (isMinimized ? 17 : 40)}rem;
  max-width: ${({ isMinimized }) => (isMinimized ? 14 : 34)}rem;
  box-shadow: 5px 3px 8px rgba(28, 30, 45, 0.44), inset 0px 0px 3px rgba(50, 59, 92, 0.47),
    inset 0px 0px 6px rgba(137, 148, 196, 0.1);
  background: linear-gradient(
      327.23deg,
      ${({ colors, theme }) =>
          colors ? theme.hexToRGB(colors[0], 0.4) : theme.colors.SPACE_DARK_COLOR} -27.76%,
      rgba(19, 22, 36, 0.04) 43.96%
    ),
    rgba(15, 17, 30, 0.87);

  &:hover {
    /* border: 0.2rem ${({ theme }) => theme.colors.IRIS_DARK_COLOR} solid; */

    /* img {
      transform: scale(1.5);
    } */

    .image__backdrop::before {
      opacity: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_VIEWPORT}) {
    height: ${({ isMinimized }) => (isMinimized ? 19.9 : 42.4)}rem;
    max-width: ${({ isMinimized }) => (isMinimized ? 16.1 : 34)}rem;
    margin-right: ${({ isMinimized }) => (isMinimized ? 1.6 : 1.29)}rem;
  }
`;

export const Title = styled.h3`
  z-index: 1;
  font-size: 2.4rem;
  font-weight: bold;
  margin-left: 0.8rem;
  word-break: break-all;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.WHITE_COLOR};
`;

export const NFTImageBackgroundContainer = styled.div<{ isMinimized: boolean }>`
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 3rem;
  align-items: center;
  justify-content: center;
  height: ${({ isMinimized }) => (isMinimized ? 17 : 33)}rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X}) {
    height: 35.33rem;
  }
`;

export const NFTImageBackground = styled(Image).attrs({ effect: "blur" })`
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: center center;
  transition: transform 1.5s, filter 2s ease-in-out;
`;

export const NFTImageBackDrop = styled.div<{ isMinimized: boolean }>`
  width: 100%;
  display: flex;
  padding: 2.35rem;
  padding-top: 4rem;
  position: absolute;
  border-radius: 3rem;
  flex-direction: row;
  justify-content: center;
  height: ${({ isMinimized }) => (isMinimized ? 19.9 : 33)}rem;

  svg {
    z-index: 1;
    flex: none;
    margin-top: 0.2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X}) {
    height: ${({ isMinimized }) => (isMinimized ? 19.9 : 35.33)}rem;
  }

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    border-radius: 3rem;
    transition: all 1.5s ease-in-out;
    background: linear-gradient(180deg, #0f111e 0%, rgba(15, 17, 30, 0) 44.01%);
  }
`;

export const NFTButtonWrapper = styled.div`
  width: 87%;
  display: flex;
  height: 5.6rem;
  bottom: 1.5rem;
  position: relative;
`;

export const NFTButton = styled(Button)`
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};
  background: linear-gradient(94.84deg, #8978f1 1.17%, #592c7c 122.85%);
  box-shadow: 0px -2px 9px rgba(133, 122, 255, 0.47), inset 0px 0px 8px 1px rgba(206, 156, 255, 0.6);
`;

export const NFTFreeButton = styled(Button).attrs({ variant: "tertiary" })<{
  isMinimized?: boolean;
}>`
  padding: 0;
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-right: 0.6rem;
  backdrop-filter: blur(33px);
  color: ${({ theme }) => theme.colors.WHITE_COLOR};
  filter: drop-shadow(5px 3px 8px rgba(28, 30, 45, 0.44));
  background: ${({ theme }) => theme.colors.SPACE_DARK_COLOR};
  border-color: ${({ theme }) => theme.colors.SPACE_DARK_COLOR} !important;

  &:focus,
  &:active,
  &:hover {
    background: ${({ theme }) => theme.colors.SPACE_DARK_COLOR};
    border-color: ${({ theme }) => theme.colors.SPACE_DARK_COLOR} !important;
  }

  ${({ isMinimized }) =>
    isMinimized &&
    css`
      margin: 0;
      filter: none;
      box-shadow: none;
      background: none;
      font-size: 1.4rem;
      margin-top: 0.7rem;
      text-align: center;
      line-height: 1.7rem;
      backdrop-filter: none;
      letter-spacing: -0.03em;
      border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR} !important;

      &:focus,
      &:active,
      &:hover {
        background: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
        border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR} !important;
      }
    `}
`;

export const NFTTransferButton = styled(NFTButton)<{ isMinimized?: boolean }>`
  filter: drop-shadow(5px 3px 8px rgba(28, 30, 45, 0.44));
`;

export const NFTPriceButton = styled(NFTTransferButton)`
  background: linear-gradient(91.85deg, #24bdff -5.72%, #7737ff 106.82%);
  box-shadow: 0px -2px 9px rgba(122, 207, 255, 0.47),
    inset 0px 0px 8px 1px rgba(107, 255, 237, 0.51);
`;

export const NFTWithdrawButton = styled(NFTPriceButton)`
  margin: 0 0.5rem;
`;

export const NFTSellButton = styled(NFTTransferButton)`
  background: linear-gradient(91.85deg, #fa7373 -5.72%, #71427d 106.82%);
  box-shadow: 0px -2px 9px rgba(255, 122, 186, 0.47),
    inset 0px 0px 8px 1px rgba(255, 107, 178, 0.54);

  ${({ theme, isLoading }) =>
    !isLoading &&
    css`
      &:hover {
        background: linear-gradient(
          94.84deg,
          ${theme.colors.BUTTON_DELETE_COLOR} 1.17%,
          ${theme.colors.CANDY_RED_COLOR} 122.85%
        );
      }
    `}
`;

export const PriceTag = styled.div`
  font-weight: bold;
  font-size: medium;
`;
