import { defineMessages } from "react-intl";

const messages = defineMessages({
  user_information: {
    id: "profile.user_information.user_information",
    defaultMessage: "User Information",
  },
  first_name: {
    id: "profile.user_information.first_name",
    defaultMessage: "First Name",
  },
  add_first_name: {
    id: "profile.user_information.add_first_name",
    defaultMessage: `{firstName, select, true {{value}} false {Add first name} other {Add first name}}`,
  },
  last_name: {
    id: "profile.user_information.last_name",
    defaultMessage: "Last Name",
  },
  add_last_name: {
    id: "profile.user_information.add_last_name",
    defaultMessage: `{lastName, select, true {{value}} false {Add last name} other {Add last name}}`,
  },
  country: {
    id: "profile.user_information.country",
    defaultMessage: "Country",
  },
  add_country_name: {
    id: "profile.user_information.add_country_name",
    defaultMessage: "{country, select, true {{value}} false {Add country} other {Add country}}",
  },
  email: {
    id: "profile.user_information.email",
    defaultMessage: "Email",
  },
  add_email: {
    id: "profile.user_information.add_email",
    defaultMessage: `{email, select, true {{value}} false {Add email address} other {Add email address}}`,
  },
});

export default messages;
