import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  getUserRedeemNfts,
  setUserSelectedRedeemNftDetailsAction,
} from "@app/providers/store/redeem";
import { APP_URL_LIST } from "@app/routes/urls";
import { IRedeemCollectionType } from "@app/providers/store/redeem/models";
import messages from "./messages";

import {
  Title,
  Container,
  NFTButton,
  NFTImageBackDrop,
  NFTImageBackground,
  NFTImageBackgroundContainer,
} from "./NftCollectionCard.styles";

export const NftCollectionCard: React.FC<
  IRedeemCollectionType & { isSwipeable?: boolean; eventId: string }
> = ({ eventId, isSwipeable = false, ...collection }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redeemNFTs } = useSelector(getUserRedeemNfts, shallowEqual);

  const { collectionId, collectionImage, collectionDisplayName } = collection;

  // CHANGE THE NAVIAGTION ROUTE TO ORIGINAL ROUTE
  const handleNavigate = () => {
    const redeem = redeemNFTs?.find((redeem) => redeem?.eventId === eventId);
    if (redeem) {
      dispatch(setUserSelectedRedeemNftDetailsAction({ ...redeem }));
    }
    navigate(`${APP_URL_LIST.REDEEM_URL}/${collectionId}`);
  };

  return (
    <Container isSwipeable={isSwipeable} onClick={handleNavigate}>
      <NFTImageBackgroundContainer>
        <NFTImageBackground alt="NFT picture art" srcList={[collectionImage]} />
      </NFTImageBackgroundContainer>
      <NFTImageBackDrop className="image__backdrop">
        <Title>{collectionDisplayName}</Title>
      </NFTImageBackDrop>

      <NFTButton iconDirection="column" icon="wallet">
        <FormattedMessage {...messages.see_collection} />
      </NFTButton>
    </Container>
  );
};
