import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Logo = ({ ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="158"
      fill="none"
      height="116"
      viewBox="0 0 158 116"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="77.6155"
        cy="62.5589"
        r="37.2464"
        strokeWidth="32"
        stroke={colors.CARDINAL_BLUE_COLOR}
      />
      <circle
        cx="114.915"
        cy="43.3833"
        r="30.1684"
        strokeWidth="25"
        stroke={colors.CARDINAL_TEAL_COLOR}
      />
      <circle
        cx="20.0381"
        cy="43.3828"
        r="12.6758"
        strokeWidth="13"
        stroke={colors.LINEAR_GOOGLE_RED_COLOR}
      />
    </svg>
  );
};

export default Logo;
