import { defineMessages } from "react-intl";

const messages = defineMessages({
  go_back_to_redeem: {
    id: "redeem_overview.go_back_to_redeem",
    defaultMessage: "Back To MarketPlace",
  },
  fetching_more_redeems: {
    id: "redeem_overview.fetching_more_redeems",
    defaultMessage: "Fetching more data...",
  },
  no_nft_collection: {
    id: "redeem_overview.no_nft_collection",
    defaultMessage: "There are no available NFTs in this Collection",
  },
});

export default messages;
