import { FunctionComponent } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { createIntl, IntlShape, createIntlCache, RawIntlProvider } from "react-intl";

import { IStateTree } from "typings/store";
import { Locale } from "@app/providers/store/settings/models";

import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/el";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/el";

import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-numberformat/locale-data/el";

import "moment/locale/el";
import "moment/locale/en-gb";

import en from "./locales/en.json";
import el from "./locales/el.json";

export let translator = {} as IntlShape;

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();
const messages: Record<Locale, {}> = { en, el };

const Provider: FunctionComponent<{}> = ({ children }) => {
  const { locale } = useSelector(({ settings }: IStateTree) => settings);
  const parsedLocale = locale === "en" ? "en-gb" : locale;

  moment.locale(parsedLocale);

  translator = createIntl(
    { locale: parsedLocale, defaultLocale: parsedLocale, messages: messages[locale] },
    cache
  );

  return <RawIntlProvider value={translator}>{children}</RawIntlProvider>;
};

export default Provider;
