import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { NftCollectionCard } from "@app/components/molecules";
import { INFTRedeemType } from "@app/providers/store/redeem/models";
import messages from "./messages";

import {
  Title,
  Container,
  Description,
  DateWrapper,
  CollectionDate,
  CollectionNumber,
  CollectionContent,
} from "./NftCollection.styles";

export const NftCollection: React.FC<INFTRedeemType> = ({
  eventId,
  eventName,
  startDate,
  collections,
  description,
  collectionsNumber,
}) => {
  const [, monnth, day] = new Date(startDate).toDateString().split(" ");
  const [eventDescription, setEventDescription] = useState<string>("");
  const containerId = eventName?.trim()?.toLocaleLowerCase()?.replaceAll(" ", "-");
  const readMore = eventDescription?.length <= 155 && `${description}`?.length > 150;

  useEffect(() => {
    if (!eventDescription && description) {
      setEventDescription(
        `${description}`?.length > 150 ? `${description.substring(0, 150)}...` : description
      );
    }
  }, [eventDescription, description]);

  const handleShowMore = () => {
    setEventDescription(readMore ? `${description}` : `${description?.substring(0, 150)}...`);
  };

  return (
    <Container id={containerId}>
      <Title>{eventName}</Title>
      <Description>
        {eventDescription}
        {description && description?.length > 150 && (
          <Description onClick={handleShowMore} color="CARDINAL_TEAL_COLOR">
            <FormattedMessage {...messages[readMore ? "readMore" : "readLess"]} />
          </Description>
        )}
      </Description>
      <DateWrapper>
        <CollectionDate>{`${day} ${monnth}`}</CollectionDate>
        <CollectionNumber>
          <FormattedMessage {...messages.collections} values={{ collectionsNumber }} />
        </CollectionNumber>
      </DateWrapper>
      <CollectionContent>
        {collections?.map((collection) => (
          <NftCollectionCard
            {...collection}
            eventId={eventId}
            key={collection?.collectionId}
            isSwipeable={collectionsNumber >= 2}
          />
        ))}
      </CollectionContent>
    </Container>
  );
};
