import { priceResolver, reportError } from "@app/helpers";
import { IStateTree, ThunkDispatchType } from "typings/store";
import { IUserNftType } from "@app/providers/store/nft/models";
import {
  cancelOrderNFTApi,
  createOrderNFTApi,
  createPaymentIntentApi,
  getRedeemCollectionsApi,
  getRedeemCollectionNftsApi,
  addRedeemToUserCollectionApi,
  isWhiteListedApi,
} from "@app/network/redeem";

import {
  SET_REDEEM_LOADING,
  SET_REDEEM_FAILURE,
  SET_REDEEM_COLLECTION,
  SET_REDEEM_COLLECTION_NFTS,
  RESET_REDEEM_COLLECTION_NFTS,
  SET_USER_SELECTED_REDEEM_NFT_DETAILS,
} from "../types";
import {
  INFTRedeemType,
  SetRedeemFailureAction,
  SetRedeemCollectionAction,
  SetRedeemNftLoadingAction,
  SetRedeemCollectionNftsAction,
  ResetRedeemCollectionNftsAction,
  ICreatePaymentIntentMetadataType,
  SetUserSelectedRedeemftDetailsAction,
  IAddRedeemToUserCollectionParamsType,
  IGetSelectedRedeemNftDetailsParamsType,
  IIsWhiteListedInterfaceParamsType,
} from "../models";

export const setRedeemNftsLoadingAction = (): SetRedeemNftLoadingAction => ({
  type: SET_REDEEM_LOADING,
});

export const resetRedeemCollectionAction = (): ResetRedeemCollectionNftsAction => ({
  type: RESET_REDEEM_COLLECTION_NFTS,
});

export const redeemFailure = (error: Error | null): SetRedeemFailureAction => ({
  type: SET_REDEEM_FAILURE,
  payload: error,
});

export const setUserRedeemNftsAction = (redeems: INFTRedeemType[]): SetRedeemCollectionAction => ({
  type: SET_REDEEM_COLLECTION,
  payload: redeems,
});

export const setRedeemCollectionAction = (
  redeemCollection: IUserNftType[]
): SetRedeemCollectionNftsAction => ({
  type: SET_REDEEM_COLLECTION_NFTS,
  payload: redeemCollection,
});

export const getUserRedeemNftsAction = () => {
  return async (dispatch: ThunkDispatchType) => {
    dispatch(setRedeemNftsLoadingAction());
    try {
      const { data } = await getRedeemCollectionsApi();
      dispatch(setUserRedeemNftsAction(data));
    } catch (error) {
      dispatch(redeemFailure(error as Error));
    }
  };
};

export const getRedeemCollectionNftsAction = (
  payload: IGetSelectedRedeemNftDetailsParamsType,
  onSuccess?: (data: IUserNftType[]) => void
) => {
  return async (dispatch: ThunkDispatchType, getState: () => IStateTree) => {
    dispatch(setRedeemNftsLoadingAction());
    try {
      const { data } = await getRedeemCollectionNftsApi(payload);

      // THIS IS DONE AS TO REMOVE DUPLICATES ON PAGE REFRESH
      const { collectionNFTs: previousCollection } = getState().redeem;
      const collectionNFTs = !payload?.offset ? data : [...previousCollection, ...data];

      dispatch(setRedeemCollectionAction(collectionNFTs));
      onSuccess?.(data);
    } catch (error) {
      dispatch(redeemFailure(error as Error));
    }
  };
};

export const isWhiteListedAction = (
  payload: IIsWhiteListedInterfaceParamsType,
  onCallback: (error?: Error) => void
) => async (dispatch: ThunkDispatchType) => {
  try {
    await isWhiteListedApi(payload);
    onCallback();
  } catch (error) {
    onCallback(error as Error);
  }
};

export const addRedeemToUserCollectionAction = (
  { collectionId, ...payload }: IAddRedeemToUserCollectionParamsType,
  onCallback: (error?: Error) => void
) => async (dispatch: ThunkDispatchType, getState: () => IStateTree) => {
  try {
    await addRedeemToUserCollectionApi(payload);

    // REFRESH COLLECTION AFTER COLLECTING A REDEEM
    const { collectionNFTs } = getState().redeem;
    dispatch(
      getRedeemCollectionNftsAction({
        offset: collectionNFTs.length,
        nftCategoryId: String(collectionId),
      })
    );

    dispatch(getUserRedeemNftsAction());

    onCallback();
  } catch (error) {
    onCallback(error as Error);
  }
};

export const setUserSelectedRedeemNftDetailsAction = (
  redeem: INFTRedeemType | null
): SetUserSelectedRedeemftDetailsAction => ({
  type: SET_USER_SELECTED_REDEEM_NFT_DETAILS,
  payload: redeem,
});

export const createNFTOrderAction = (
  nftId: number | undefined,
  onCallback: (error?: Error) => void
) => async () => {
  try {
    await createOrderNFTApi({ nftId: nftId });
    onCallback();
  } catch (error) {
    onCallback(error as Error);
    reportError(error as Error);
  }
};

export const cancelNFTOrderAction = (nftId: number | undefined) => async () => {
  try {
    await cancelOrderNFTApi({ nftId: nftId });
  } catch (error) {
    reportError(error as Error);
  }
};

/**
 * Action that is triggered to call the network post api to create
 * a payment intent for a user
 * @param onCallback | The callback function to verify the payment or trigger the error modal
 *                     in case of error
 */
export const createPaymentIntentAction = (
  onCallback: (data: Record<string, any> | null, error?: Error) => void
) => async (_dispatch: ThunkDispatchType, getState: () => IStateTree) => {
  try {
    // GET USER FROM STORE
    const user = getState().session?.data?.user;
    const userSelectedNftDetails = getState().nft.userSelectedNftDetails;
    const metaData: ICreatePaymentIntentMetadataType = {
      email: user?.email,
      eosId: user?.eosId,
      nftId: userSelectedNftDetails?.nftId,
      eventId: userSelectedNftDetails?.eventId,
      serviceFee: userSelectedNftDetails?.serviceFee,
      initialTotalNFTPrice: priceResolver(userSelectedNftDetails?.price),
      totalNFTPrice: userSelectedNftDetails?.serviceFee
        ? Math.round(
            (priceResolver(userSelectedNftDetails?.price) +
              userSelectedNftDetails?.serviceFee * priceResolver(userSelectedNftDetails?.price)) *
              10
          ) / 10
        : priceResolver(userSelectedNftDetails?.price),
      nftCategory: userSelectedNftDetails?.nftCategory,
    };

    const { data } = await createPaymentIntentApi(metaData);
    onCallback(data);
  } catch (error) {
    onCallback(null, error as Error);
    reportError(error as Error);
  }
};
