import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { APP_URL_LIST } from "@app/routes/urls";
import messages from "@app/components/molecules/Header/messages";
import { getUserToken } from "@app/providers/store/session/selectors/selectors";

import {
  LogoIcon,
  LogoTitle,
  Container,
  GlobalStyle,
  LogoWrapper,
} from "./TermsAndConditions.styles";
import ReactGA from "react-ga";

const TermsAndConditions: React.FC<{}> = () => {
  const navigate = useNavigate();
  const userToken = useSelector(getUserToken);

  React.useEffect(() => {
    ReactGA.pageview(`${APP_URL_LIST.TERMS_AND_CONDITION_URL}`);
  }, []);

  return (
    <Container>
      <GlobalStyle />
      <div className="terms-container">
        {!userToken && (
          <LogoWrapper className="logo-wrapper" onClick={() => navigate(APP_URL_LIST.SIGNUP_URL)}>
            <LogoIcon />
            <LogoTitle>
              <FormattedMessage {...messages.title} />
            </LogoTitle>
          </LogoWrapper>
        )}

        <h1>Ι. Οροι Χρήσης</h1>
        <br />
        <br />
        <h4>ΠΑΡΕΧΟΜΕΝΗ ΥΠΗΡΕΣΙΑ</h4>
        <p>
          H ComeTogether (εφεξής "Η Εταιρία") αναλαμβάνει αποκλειστικά την πώληση εισιτηρίων των
          εκδηλώσεων και λοιπών θεαμάτων που παρουσιάζονται μέσω της ιστοσελίδας της και
          διοργανώνονται από τρίτους (εφεξής "Τρίτους Παρόχους"), σύμφωνα με τους όρους και τις
          προϋποθέσεις που αυτοί, δηλαδή οι Τρίτοι Πάροχοι, διαμορφώνουν και γνωστοποιούν στο ευρύ
          κοινό.
        </p>
        <h4>ΧΡΕΩΣΕΙΣ</h4>
        <p>
          Το κόστος κάθε αγαθού που προσφέρεται μέσω της ιστοσελίδας καθορίζεται από τον αντίστοιχο
          Τρίτο Πάροχο και γνωστοποιείται μαζί με τυχόν φόρους και τέλη στην Εταιρία. Η τελευταία
          ουδεμία ευθύνη φέρει για τη διαμόρφωση ή/και τις μεταβολές των τιμών αυτών.
        </p>
        <p>
          Ενδέχεται σε περιπτώσεις που η εμπορική πολιτική του Τρίτου Παρόχου το επιτρέπει, η
          Εταιρία να έχει τιμή εισιτηρίου χαμηλότερη της τιμής που έχει ορίσει ο Τρίτος Πάροχος.
        </p>
        <p>
          Η Εταιρία εισπράττει το αντίτιμο του εισιτηρίου για λογαριασμό του Τρίτου Παρόχου, το
          οποίο και εν συνεχεία αποδίδει. Από το ανωτέρω ποσό παρακρατεί ως αμοιβή για τις υπηρεσίες
          της τα τέλη κράτησης που εμφανίζονται ως διακριτό ποσό στο σύνολο της χρέωσης του πελάτη.
        </p>
        <h4>ΔΙΑΔΙΚΑΣΙΑ ΑΓΟΡΑΣ</h4>
        <p>
          Η διαδικασία αγοράς εισιτηρίου λαμβάνει χώρα αποκλειστικά μέσω της ιστοσελίδας της
          Εταιρίας με τρόπο απλό, εύχρηστο και ασφαλή. Η πλοήγηση στο περιβάλλον της ιστοσελίδας δεν
          απαιτεί εγγραφή. Όλα τα απαραίτητα στοιχεία για τις προσφερόμενες εκδηλώσεις είναι
          διαθέσιμα στον επισκέπτη.
          <br />
          <br />
          Για την αγορά εισιτηρίου απαιτείται η εγγραφή του πελάτη στην πλατφόρμα που είναι δυνατή
          με τρεις τρόπους:
          <br />
          α. με τη χρήση του Google λογαριασμού του
          <br />
          β. τη χρήση του Facebook λογαριασμού του, αποδεχόμενος την επεξεργασία των αναγκαίων
          προσωπικών δεδομένων που παρέχονται από τις ως άνω πλατφόρμες κοινωνικής δικτύωσης,
          <br />
          γ. με τη χρήση της διεύθυνσης ηλεκτρονικού ταχυδρομείου του. Στην τελευταία αυτή περίπτωση
          αποστέλλεται σχετικό email στον πελάτη, ο οποίος πρέπει να ανοίξει το σύνδεσμο που
          περιέχεται μέσα σε αυτό, προκειμένου να ολοκληρωθεί η εγγραφή.
          <br />
          <br />
          Μετά την επιλογή της εκδήλωσης απαιτείται η επιβεβαίωση του αριθμού του κινητού τηλεφώνου
          του πελάτη. Ο τελευταίος λαμβάνει σχετικό γραπτό μήνυμα με έναν κωδικό τον οποίο εισάγει
          στην ιστοσελίδα. Αυτό το βήμα είναι απαραίτητο και λαμβάνει χώρα μόνο μια φορά για κάθε
          λογαριασμό, προκειμένου να αποφευχθούν φαινόμενα κυβερνοεπιθέσεων, αλλά και διασφάλισης
          ότι οι ποσοτικοί περιορισμοί στην αγορά εισιτηρίων δεν καταστρατηγούνται.
          <br />
          <br />
          Τέλος, ακολουθώντας τις οδηγίες της ιστοσελίδας, καλείται να πληρώσει το αντίτιμο του
          εισιτηρίου και των τελών κράτησης με την πιστωτική/χρεωστική του κάρτα, χωρίς
          ανακατεύθυνση σε άλλη ιστοσελίδα τράπεζας. Η διαδικασία λαμβάνει χώρα μέσω του προηγμένου
          συστήματος πληρωμών που εφαρμόζει η Εταιρία μέσω της Stripe.
          <br />
          <br />
          Η αγορά επιβεβαιώνεται με την εμφάνιση του κωδικού συναλλαγής και την αποστολή σχετικού
          μηνύματος στο ηλεκτρονικό ταχυδρομείο του πελάτη που θα περιέχει επίσης και τις σχετικές
          αποδείξεις πληρωμής του εισιτηρίου και των τελών κράτησης.
          <br />
          <br />
          Μετά την υποβολή των στοιχείων του για την ολοκλήρωση της συναλλαγής του ή εάν προκύψει
          μήνυμα λάθους ή διακοπή της υπηρεσίας κατά την αποστολή των στοιχείων του, είναι ευθύνη
          του χρήστη να επικοινωνήσει με το τμήμα εξυπηρέτησης πελατών της Εταιρίας, προκειμένου να
          πληροφορηθεί για το αν ολοκληρώθηκε η συναλλαγή του ή όχι. Η Εταιρία ουδεμία ευθύνη φέρει
          για τυχόν ζημία του χρήστη σε περίπτωση που για οποιαδήποτε αιτία δεν έλαβε επιβεβαίωση
          της συναλλαγής του.
        </p>
        <h4>ΔΙΑΔΙΚΑΣΙΑ ΑΛΛΑΓΩΝ & ΑΚΥΡΩΣΕΩΝ - ΕΠΙΣΤΡΟΦΗ ΧΡΗΜΑΤΩΝ</h4>
        <p>
          Κάθε Τρίτος Πάροχος είναι αποκλειστικά αρμόδιος για τον ορισμό της δυνατότητας και της
          διαδικασίας ακύρωσης ή μεταβολής κράτησης ή προμήθειας αγαθού που αυτός παρέχει σύμφωνα με
          τους όρους που αυτός διαμορφώνει και δημοσιοποιεί στο ευρύ κοινό. Κατά κανόνα, δεν
          παρέχεται η δυνατότητα ακυρώσεων, ενώ για την περίπτωση οποιασδήποτε αλλαγής, εάν και
          εφόσον παρέχεται ως δυνατότητα από τον Τρίτο Πάροχο, συνήθως είναι εφικτή εντός
          συγκεκριμένης προθεσμίας από την ημερομηνία διεξαγωγής της εκδήλωσης που επέλεξε ο
          χρήστης.
          <br />
          <br />
          Οι πελάτες, εφόσον το επιθυμούν, δύνανται πριν την αγορά τους να ζητήσουν εγγράφως ή
          τηλεφωνικά από την Εταιρία στοιχεία του Τρίτου Παρόχου, προκειμένου να τους παρασχεθούν
          πληροφορίες για την πολιτική αλλαγών και ακυρώσεων που εφαρμόζονται στην εκάστοτε
          εκδήλωση.
          <br />
          <br />
          Σε περίπτωση ακύρωσης των εκδηλώσεων, η Εταιρεία δεν φέρει καμία ευ΄θυνη για την επιστροφή
          των χρημάτων έναντι των πελατών, καθώς αυτή βαρύνει αποκλειστικά τον Διοργανωτή. Σε
          περιπτώσεις που εγκριθεί η επιστροφή εισιτηρίων από τον τρίτο πάροχο,, θα επιστρέφεται
          μόνο το ποσό που αντιστοιχεί στην ονομαστική τιμή του εισιτηρίου και όχι οι επιπλέον
          χρεώσεις ανά εισιτήριο, καθώς αυτές αποτελούν αμοιβή παροχής υπηρεσιών οι οποίες έχουν
          παρασχεθεί για τη διάθεση εισιτηρίων μέσω της ComeTogether.
          <br />
          <br />Η εταιρία συμμορφώνεται πλήρως με το ισχύον νομικό πλαίσιο (Ν.2251/1994) για την
          προστασία του καταναλωτή και τις ειδικότερες ρυθμίσεις περί συμβάσεων που συνάπτονται από
          απόσταση, όπως η προκείμενη. Σημειώνεται όμως ότι ο καταναλωτής στερείται του δικαιώματος
          υπαναχώρησης εντός 14 ημερών που ισχύει κατά κανόνα, καθότι οι υπηρεσίες σχετιζόμενες με
          δραστηριότητες αναψυχής εάν η σύμβαση προβλέπει συγκεκριμένη ημερομηνία ή προθεσμία
          εκτέλεσης, αποτελούν ρητή εξαίρεση κατά το άρθρο 3ιβ περ.ιβ του ίδιου νόμου. Τα εισιτήρια
          εκδηλώσεων και λοιπών θεαμάτων αποτελούν χαρακτηριστική τέτοια περίπτωση και, συνεπώς, δεν
          υπάρχει σχετική δυνατότητα υπαναχώρησης.
        </p>
        <h4>ΠΡΟΣΒΑΣΗ ΣΤΙΣ ΕΚΔΗΛΩΣΕΙΣ/ ΘΕΑΜΑΤΑ</h4>
        <p>
          Για την πρόσβαση στις εκδηλώσεις ο πελάτης απαιτείται να φέρει μαζί του ηλεκτρονική φορητή
          συσκευή (κινητό τηλέφωνο, τάμπλετ) με εγκατεστημένη την εφαρμογή ComeTogether ή
          εγκατεστημένο φυλλομετρητή (browser). Με με την εισαγωγή των στοιχείων του λογαριασμού
          του, θα εμφανίζεται το ηλεκτρονικό του εισιτήριο σε μορφή δυναμικού QR Code (Dynamic QR
          Code). Ο δυναμικός QR Code είναι ένα είδος προηγμένου γραμμωτού κώδικα που χρησιμοποιεί η
          Εταιρία για την αποφυγή παράνομης εμπορίας εισιτηρίων και της απρόσκοπτης και άμεσης
          εξυπηρέτησης του πελάτη.
          <br />
          <br />
          Για τη σύνδεση στην εφαρμογή/ιστοσελίδα και την εμφάνιση του εισιτηρίου στη φορητή συσκευή
          απαιτείται σύνδεση στο διαδίκτυο. Από τη στιγμή που το εισιτήριο έχει μεταφορτωθει στη
          συσκευή δεν απαιτείται πλέον σύνδεση στο διαδίκτυο και αρκεί η σάρωσή του απο τις ειδικές
          συσκευές της Εταιρίας που βρίσκονται στην είσοδο κάθε εκδήλωσης.
          <br />
          <br />
          Επισημαίνεται ότι το δυναμικό QR Code, είναι διαθέσιμο για σάρωση και είσοδο μία ώρα πριν
          την έναρξη κάθε εκδήλωσης και όχι νωρίτερα, εκτός αν αυτό γνωστοποιηθεί ειδικά σε
          ορισμένες περιπτώσεις. Παρόλαυτα, η μεταφόρτωσή του για λόγους πρακτικής διευκόλυνσης
          μπορεί να γίνει και πριν από αυτό το χρόνο. Το εισιτήριο θα λάβει αυτόματα την αναγνώσιμη
          μορφή που απαιτείται για την πρόσβαση του πελάτη κατά το χρόνο που θα είναι δυνατή η
          είσοδος, χωρίς να απαιτείται κάποια ειδικότερη ενέργεια εκ μέρους του πελάτη. Εξου και ο
          δυναμικός χαρακτήρας του ηλεκτρονικού εισιτηρίου.
        </p>
        <h1>ΙΙ. Όροι διεξαγωγής εκδηλώσεων/θεαμάτων</h1>
        <br />
        <br />
        <p>
          1. Η αγορά του εισιτηρίου, παρέχει το δικαίωμα στον κάτοχό του, να παρακολουθήσει την
          εκδήλωση που αναγράφεται σε αυτό, εάν και εφόσον αποδεχθεί και τηρήσει τους όρους
          λειτουργίας του χώρου. Η είσοδος του θεατή στην εκδήλωση σημαίνει την ανεπιφύλακτη αποδοχή
          των όρων που αναγράφονται στο παρόν.
          <br />
          <br />
          2. Το εισιτήριο ισχύει για μία και μόνο είσοδο. Η ανταλλαγή και η εν γένει εμπορία
          εισιτηρίων απαγορεύεται. Η μεταπώληση επιτρέπεται μόνο μέσω μόνο μέσω του πρωτοκόλλου
          Blockchain ComeTogether υπο τους ακόλουθους όρους. Μετά την αγορά του εισιτηρίου, και μόνο
          εφόσον συναινεί και ο Τρίτος Πάροχος, ενεργοποιείται η δυνατότητα μεταπώλησης, η οποία
          προσφέρεται μέσω της ιστοσελίδας/εφαρμογής. Η περαιτέρω διάθεση του εισιτηρίου γίνεται
          στην τιμή που ο ίδιος ο πελάτης επιθυμεί. Εν συνεχεία, η διαδικασία πώλησής του
          διεκπεραιώνεται από την εταιρία. Ο Τρίτος Πάροχος μπορεί να συμφωνεί ειδικά ότι ποσοστό
          από τη μεταπώληση των εισιτηρίων θα αποδίδεται σε αυτόν, συμφωνία που θα γνωστοποιείται
          στον πελάτη μέσω της εφαρμογής προτού θέσει το εισιτήριό του προς μεταπώληση.
          <br />
          <br />Η εταιρία θα διαθέτει τα μεταπωλούμενα εισιτήρια με έναν εκ των δύο ακολούθων
          τρόπων, ανάλογα με τη σύμφωνία που έχει συναφθεί με τον Τρίτο Πάροχο:
          <br />
          α) μόνο κατόπιν της εξάντλησης των αρχικώς διατιθέμενων εισιτηρίων. Εν προκειμένω, τα
          μεταπωλούμενα εισιτήρια θα διατίθενται αυστηρά με σειρά από το οικονομικότερο προς το
          ακριβότερο και, σε περίπτωση ίδιας τιμής μεταπώλησης, κατά σειρά χρονικής προτεραιότητας.
          Σε περίπτωση μη εξάντλησης των αρχικών εισιτηρίων δεν θα ενεργοποιείται η διαδικασία
          μεταπώλησης.
          <br />
          β) μεταπώληση με σειρά διάθεσης από το οικονομικότερο στο ακριβότερο, ανεξαρτήτως του αν
          διατίθενται από τον Τρίτο Πάροχο ή από τον πελάτη/μεταπωλητή.
          <br />
          <br />
          3. Ο κάτοχος του εισιτηρίου αποδέχεται το ενδεχόμενο να ελεγχθούν προσωπικά του
          αντικείμενα, στο πλαίσιο των κάτωθι ενδεικτικά παρατιθέμενων απαγορεύσεων.
          <br />
          <br />
          4. Ο Διοργανωτής μπορεί να απαγορεύσει την είσοδο ιδίως σε πρόσωπα τα οποία είναι υπό την
          επήρεια αλκοόλ ή ναρκωτικών ουσιών, φέρουν φιάλες ή άλλα επικίνδυνα ή εύφλεκτα αντικείμενα
          ακόμα και στην περίπτωση που είναι κάτοχοι εισιτηρίου. Απαγορεύεται επίσης η μεταφορά και
          χρήση οπτικοακουστικών μέσων στο χώρο της εκδήλωσης (για βιντεοσκόπηση φωτογράφιση,
          μαγνητοφώνηση της εκδήλωσης ή μέρους αυτής).
          <br />
          <br />
          5. Ο Διοργανωτής δεν φέρει καμία ευθύνη για διαπληκτισμούς μεταξύ θεατών, για ζημιές ή
          απώλειες προσωπικών αντικειμένων των θεατών στο χώρο της εκδήλωσης.
          <br />
          <br />
          6. Το κοινό οφείλει να συμμορφώνεται στις υποδείξεις του προσωπικού ασφαλείας και των
          εκπροσώπων του Διοργανωτή για θέματα ασφαλείας. Απαγορεύεται ρητά το crowd surfing για
          λόγους ασφαλείας.
          <br />
          <br />
          7. Σε περίπτωση αναβολής της εκδήλωσης λόγω ανωτέρας βίας που να καθιστά τη διεξαγωγή της
          εκδήλωσης ανέφικτη ή για οργανωτικούς λόγους, ο Διοργανωτής διατηρεί το δικαίωμα να
          αλλάξουν την ημερομηνία, την ώρα και το χώρο διεξαγωγής της, ειδοποιώντας το κοινό
          σχετικά, από τα Μέσα Μαζικής Ενημέρωσης. Στην περίπτωση αυτή τα εισιτήρια ισχύουν ως έχουν
          και δεν εξαργυρώνονται. Επίσης διατηρεί το δικαίωμα αλλαγής του προγράμματος της
          εκδήλωσης.
          <br />
          <br />
          8. Σε περίπτωση οριστικής ματαίωσης της εκδήλωσης, τα εισιτήρια θα εξαργυρώνονται, εντός
          ορισμένου χρόνου με τον τρόπο και στο χρόνο που ορίζει ο Διοργανωτής. Ο θεατής αποδέχεται
          ότι ο Διοργανωτής δεν έχει υποχρέωση να τον αποζημιώσει, εκτός από το αντίτιμο του
          εισιτηρίου του, για καμία άλλη δαπάνη του από τη ματαίωση της εκδήλωσης.
          <br />
          <br />
          9. Σε περίπτωση διακοπής και ματαίωσης της εκδήλωσης για λόγους ανωτέρας βίας,
          τριανταπέντε (35) τουλάχιστον λεπτά μετά την έναρξης της, θεωρείται ολοκληρωμένη και δεν
          επαναλαμβάνεται. Στην περίπτωση αυτή, τα εισιτήρια δεν εξαργυρώνονται.
          <br />
          <br />
          10. Στην περίπτωση που με το ίδιο εισιτήριο εξασφαλίζεται η είσοδος/παρακολούθηση
          περισσοτέρων εκδηλώσεων, happenings κλπ, παραστάσεις, συναυλίες (λ.χ. Festival) και
          ματαιωθεί η εμφάνιση συγκροτήματος ή μεμονωμένου καλλιτέχνη, ο θεατής αποδέχεται ότι θα
          του επιστραφεί το αναλογούν τμήμα του εισιτηρίου και ότι ο Διοργανωτής δε δεσμεύεται από
          εκτιμήσεις σχετικά με την καλλιτεχνική αξία του συγκροτήματος (ή μεμονωμένου) του οποίου
          ακυρώνεται η εμφάνιση.
          <br />
          <br />
          11. Σε περιπτώσεις που εγκριθεί η επιστροφή εισιτηρίων, θα επιστρέφεται μόνο το ποσό που
          αντιστοιχεί στην ονομαστική τιμή του εισιτηρίου και όχι οι επιπλέον χρεώσεις ανά
          εισιτήριο, καθώς αυτές αποτελούν αμοιβή παροχής υπηρεσιών οι οποίες έχουν παρασχεθεί για
          τη διάθεση εισιτηρίων μέσω της ComeTogether.
          <br />
          <br />
          12. Σε κάθε περίπτωση, η επιστροφή του αντιτίμου δεν θα είναι δυνατή μετά το τέλος της
          καλλιτεχνικής/ αγωνιστικής περιόδου εντός της οποίας πραγματοποιήθηκε η ματαίωση.
          <br />
          <br />
          13. Η κατοχή αυτού του εισιτηρίου, δεν δίνει το δικαίωμα στον κάτοχό του, να λάβει
          οποιαδήποτε επιπλέον δωρεάν παροχή, εκτός αν αυτό έχει ανακοινωθεί.
          <br />
          <br />
          14. Μέρη της εκδήλωσης πιθανόν να βιντεοσκοπούνται/μαγνητοσκοπούνται. Μέλη του κοινού που
          θα εμφανίζονται σε αυτά, δεν θα έχουν καμία απαίτηση από την εταιρία που εκτελεί τη
          βιντεοσκόπηση/μαγνητοσκόπηση ή τους Διοργανωτές.
          <br />
          <br />
          15. Σε περίπτωση αγοράς μειωμένου εισιτηρίου (φοιτητικού, νεανικού, ανέργων κ.λ.π.) ο
          κάτοχος θα πρέπει να φέρει μαζί του, προς επίδειξη στην είσοδο της εκδήλωσης, την
          αντίστοιχη ταυτότητα / πιστοποιητικό (φοιτητικό πάσο, κάρτα ΟΑΕΔ κ.λπ.).
          <br />
          <br />
          16. Οι ταξιθέτες και το προσωπικό ασφαλείας βρίσκονται στο χώρο του θεάτρου με σκοπό την
          εξυπηρέτηση και ασφάλεια του κοινού, καθώς και την ομαλή διεξαγωγή της εκδήλωσης. Το κοινό
          οφείλει να συμμορφώνεται στις υποδείξεις των ταξιθετών και του προσωπικού ασφαλείας Οι
          ταξιθέτες και το προσωπικό ασφαλείας έχουν το δικαίωμα να απομακρύνουν από το χώρο της
          εκδήλωσης θεατή/ θεατές που παρακωλύουν με την συμπεριφορά τους την ομαλή εξέλιξη του
          θεάματος ή /και το δικαίωμα των υπόλοιπων θεατών να παρακολουθήσουν την εκδήλωση.
        </p>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
