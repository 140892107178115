import React from "react";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";

import { getUserData } from "@app/providers/store/session";
import messages from "./messages";

import { Title, SubTitle, Container, CopyButton, Contents } from "./Account.style";

const Account: React.FC<{}> = () => {
  const { formatMessage } = useIntl();
  const user = useSelector(getUserData, shallowEqual);

  const onCopy = () => {
    navigator.clipboard.writeText(user?.referralLink || "");
    toast(formatMessage(messages.copied_referral_link));
  };

  return (
    <Container>
      <Title className="account_title">
        <FormattedMessage {...messages.account} />
      </Title>
      <Contents>
        <SubTitle>
          <FormattedMessage {...messages.referral_link} />
        </SubTitle>
        <CopyButton icon="copy" onClick={onCopy}>
          <FormattedMessage {...messages.copy} />
        </CopyButton>
      </Contents>
    </Container>
  );
};

export default Account;
