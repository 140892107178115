import styled from "styled-components";

export const Container = styled.div``;

export const ZoomInContainer = styled.div`
  display: none;
  right: 2.16rem;
  cursor: pointer;
  padding: 8px 15px;
  position: absolute;
  align-items: center;
  flex-direction: row;
  border-radius: 21px;
  justify-content: center;
  backdrop-filter: blur(33px);
  background: rgba(9, 11, 18, 0.44);
  border: 1px ${({ theme }) => theme.colors.SPACE_PURE_COLOR} solid;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    top: 6rem;
    display: flex;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    top: 2.5rem;
    display: flex;
  }
`;

export const DownloadContainer = styled(ZoomInContainer)`
  right: 8.3rem;
  padding: 7px 15px;
`;

export const ShareContainer = styled(ZoomInContainer)`
  right: 14.8rem;
  padding: 7px 15px;
`;

export const PlayContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 15px;
  cursor: pointer;
  width: fit-content;
  position: absolute;
  height: fit-content;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  justify-content: center;
  backdrop-filter: blur(33px);
  background: rgba(9, 11, 18, 0.44);
  border: 1px ${({ theme }) => theme.colors.SPACE_PURE_COLOR} solid;
`;
