import React from "react";
import { useWindowSize } from "usehooks-ts";
import { useTheme } from "styled-components";
import { useLocation } from "react-router-dom";

import { Header } from "@app/components/molecules";
import { SideDrawer } from "../SideDrawer";

import {
  Footer,
  Container,
  GlobalStyle,
  ContentWrapper,
  ChildrenWrapper,
} from "./PageLayout.styles";

export const PageLayout: React.FC<{}> = ({ children }) => {
  const { breakpoints } = useTheme();
  const { state } = useLocation() as CTLocation;
  const { width: screen_width } = useWindowSize();

  const isRedeem = Boolean(state?.isRedeem) || Boolean(state?.isCollection);

  const applyIdToDesktop =
    screen_width >=
    Number(
      breakpoints.LARGE_VIEWPORT_IPAD_PRO.substring(
        0,
        breakpoints.LARGE_VIEWPORT_IPAD_PRO.length - 2
      )
    );

  return (
    <Container>
      <GlobalStyle isRedeem={isRedeem} />
      <Header />
      <ContentWrapper isRedeem={isRedeem}>
        {isRedeem && <SideDrawer />}
        <ChildrenWrapper
          isRedeem={isRedeem}
          className="page__layout__children__wrapper"
          id={applyIdToDesktop ? "scrollable_div" : undefined}
        >
          {children}
        </ChildrenWrapper>
      </ContentWrapper>
      <Footer className="footer__container" />
    </Container>
  );
};
