import { defineMessages } from "react-intl";

const messages = defineMessages({
  play_content_toast: {
    id: "nft_detail.play_content_toast",
    defaultMessage: "You must buy the NFT to be able to see it's content.",
  },
});

export default messages;
