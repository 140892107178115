// import original module declarations
import "styled-components";

export enum BREAK_POINTS {
  MOBILE = "600px",
  SMALL_MOBILE = "360px",
  LARGE_VIEWPORT = "992px",
  SMALL_VIEWPORT = "576px",
  MEDIUM_VIEWPORT = "768px",
  DESKTOP_FULL_HD_VIEW = "1920px",
  EXTRA_LARGE_VIEWPORT = "1200px",
  LARGE_VIEWPORT_IPAD_PRO = "1024px",
  EXTRA_LARGE_DESKTOP_VIEW = "1440px",
  EXTRA_SMALL_VIEWPORT_PIXEL_XL = "411px",
  EXTRA_SMALL_VIEWPORT_IPHONE_X = "375px",
  EXTRA_SMALL_VIEWPORT_IPHONE_X_SE = "319px",
  EXTRA_SMALL_VIEWPORT_SAMSUNG_SFIVE = "360px",
  EXTRA_SMALL_VIEWPORT_IPHONE_X_PLUS = "414px",
  EXTRA_SMALL_VIEWPORT_IPHONE_X_HEIGHT = "812px",
  EXTRA_SMALL_VIEWPORT_IPHONE6_7_8_HEIGHT = "667px",
}

// All app colors
export enum LIGHT_MODE_COLORS {
  LIGHT_GRAY = "#939AA4",
  GRAY_SCALE = "#F9FAFF",
  GREY_COLOR = "#808080",
  LIGHT_GREY = "#B4CFF2",
  BLACK_COLOR = "#000000",
  ANGRY_COLOR = "#dd3333",
  WHITE_COLOR = "#FFFFFF",
  ORANGE_COLOR = "#FF652F",
  PURPLE_COLOR = "#200A40",
  DISABLED_GRAY = "#e3e5e5",
  OBSIDIAN_COLOR = "#14141d",
  IRIS_DARK_COLOR = "#7879F1",
  IRIS_PURE_COLOR = "#DEDEFF",
  CANDY_RED_COLOR = "#EB5757",
  ONIX_DARK_COLOR = "#10121F",
  ONIX_DEEP_COLOR = "#090B12",
  BID_GREEN_COLOR = "#66A500",
  GRAY_SCALE_COLOR = "#F7F7F9",
  VIVID_PINK_COLOR = "#D53693",
  IRIS_LIGHT_COLOR = "#A5A6F6",
  GOOGLE_RED_COLOR = "#924141",
  SPACE_DEEP_COLOR = "#323B5C",
  SPACE_DARK_COLOR = "#424A7D",
  SPACE_PURE_COLOR = "#D7DEFF",
  ONIX_SHADE_COLOR = "#1E2235",
  BLUE_COLOR = "rgb(0,103,187)",
  SPACE_LIGHT_COLOR = "#8390C6",
  LINEAR_GREY_COLOR = "#1b1e2b",
  LINEAR_PINK_COLOR = "#71427D",
  SPACE_SHADE_COLOR = "#6E7EC3",
  CANDY_YELLOW_COLOR = "#FFC213",
  LINEAR_BLACK_COLOR = "#131624",
  CANDY_ORANGE_COLOR = "#F2994A",
  BUTTON_DELETE_COLOR = "#FA7373",
  BUTTON_NOTICE_COLOR = "#EBC540",
  METALLIC_DARK_COLOR = "#2B2E43",
  CARDINAL_BLUE_COLOR = "#3772FF",
  CARDINAL_TEAL_COLOR = "#24bdff",
  FACEBOOK_BLUE_COLOR = "#275EA4",
  LINEAR_PURPLE_COLOR = "#592C7C",
  SHADOW_PURPLE_COLOR = "#8479ff",
  SHADOW_YELLOW_COLOR = "#ffe179",
  METALLIC_LIGHT_COLOR = "#91919F",
  OBSIDIAN_SHADE_COLOR = "#1E1E32",
  BUTTON_ALTERED_COLOR = "#8978F1",
  TRANSPARENT_COLOR = "transparent",
  CARDINAL_PURPLE_COLOR = "#7737FF",
  LINEAR_GOOGLE_RED_COLOR = "#FA7373",
  LINEAR_EXTRA_BLACK_COLOR = "#0f101e",
  METALLIC_PEARL_WHITE_COLOR = "#FDFDFD",
  CARDINAL_TEAL_SHADOW_COLOR = "#79ceff",
  LIGHT_BACKGROUND_GREY_COLOR = "#C4C4C4",
}

export enum DARK_MODE_COLORS {
  LIGHT_GRAY = "#939AA4",
  GRAY_SCALE = "#F9FAFF",
  GREY_COLOR = "#808080",
  LIGHT_GREY = "#B4CFF2",
  BLACK_COLOR = "#000000",
  ANGRY_COLOR = "#dd3333",
  WHITE_COLOR = "#FFFFFF",
  ORANGE_COLOR = "#FF652F",
  PURPLE_COLOR = "#200A40",
  DISABLED_GRAY = "#e3e5e5",
  OBSIDIAN_COLOR = "#14141d",
  IRIS_DARK_COLOR = "#7879F1",
  IRIS_PURE_COLOR = "#DEDEFF",
  CANDY_RED_COLOR = "#EB5757",
  ONIX_DARK_COLOR = "#10121F",
  ONIX_DEEP_COLOR = "#090B12",
  BID_GREEN_COLOR = "#66A500",
  GRAY_SCALE_COLOR = "#F7F7F9",
  VIVID_PINK_COLOR = "#D53693",
  IRIS_LIGHT_COLOR = "#A5A6F6",
  GOOGLE_RED_COLOR = "#924141",
  SPACE_DEEP_COLOR = "#323B5C",
  SPACE_DARK_COLOR = "#424A7D",
  SPACE_PURE_COLOR = "#D7DEFF",
  ONIX_SHADE_COLOR = "#1E2235",
  BLUE_COLOR = "rgb(0,103,187)",
  SPACE_LIGHT_COLOR = "#8390C6",
  LINEAR_GREY_COLOR = "#1b1e2b",
  LINEAR_PINK_COLOR = "#71427D",
  SPACE_SHADE_COLOR = "#6E7EC3",
  CANDY_YELLOW_COLOR = "#FFC213",
  LINEAR_BLACK_COLOR = "#131624",
  CANDY_ORANGE_COLOR = "#F2994A",
  BUTTON_DELETE_COLOR = "#FA7373",
  BUTTON_NOTICE_COLOR = "#EBC540",
  METALLIC_DARK_COLOR = "#2B2E43",
  CARDINAL_BLUE_COLOR = "#3772FF",
  CARDINAL_TEAL_COLOR = "#24bdff",
  FACEBOOK_BLUE_COLOR = "#275EA4",
  LINEAR_PURPLE_COLOR = "#592C7C",
  SHADOW_PURPLE_COLOR = "#8479ff",
  SHADOW_YELLOW_COLOR = "#ffe179",
  METALLIC_LIGHT_COLOR = "#91919F",
  OBSIDIAN_SHADE_COLOR = "#1E1E32",
  BUTTON_ALTERED_COLOR = "#8978F1",
  TRANSPARENT_COLOR = "transparent",
  CARDINAL_PURPLE_COLOR = "#7737FF",
  LINEAR_GOOGLE_RED_COLOR = "#FA7373",
  LINEAR_EXTRA_BLACK_COLOR = "#0f101e",
  METALLIC_PEARL_WHITE_COLOR = "#FDFDFD",
  CARDINAL_TEAL_SHADOW_COLOR = "#79ceff",
  LIGHT_BACKGROUND_GREY_COLOR = "#C4C4C4",
}

declare module "styled-components" {
  export interface DefaultTheme {
    // App dark mode condition
    isDarkMode: boolean;
    // Toggle App theme
    toggleTheme: () => void;
    // App break points
    breakpoints: typeof BREAK_POINTS;
    // All Global App Colors typings
    colors: typeof DARK_MODE_COLORS | typeof LIGHT_MODE_COLORS;
    // App color converter
    hexToRGB: (hexColor: string, alpha?: number | undefined) => string;
  }
}
