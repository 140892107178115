import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Icon } from "@app/components/atoms";
import { closeGenericModalAction } from "@app/providers/store/genericModal";
import { getGenericModal } from "@app/providers/store/genericModal/selectors";

import {
  Container,
  GlobalStyle,
  Description,
  CloseButton,
  ModalFooter,
  BottomMessageLink,
  InfoPrimaryButton,
  ActionPrimaryButton,
  ActionButtonWrapper,
  ActionSecondaryButton,
} from "./GenericModal.styles";

export const GenericModal: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const {
    icon,
    title,
    onClose,
    description,
    primaryLabel,
    show = false,
    primaryOnPress,
    secondaryLabel,
    bottomLinkProps,
    bottomLinkLabel,
    secondaryOnPress,
    isCookies = false,
    status = "success",
    isTransparent = true,
    hideCloseButton = false,
    descriptionColor = "CARDINAL_BLUE_COLOR",
    ...props
  } = useSelector(getGenericModal, shallowEqual);

  const closeModal = () => {
    onClose?.();
    primaryOnPress?.();
    dispatch(closeGenericModalAction());
  };

  return (
    <Container
      size="sm"
      centered
      show={show}
      onHide={onClose}
      iscookies={String(isCookies)}
      istransparent={String(isTransparent)}
      {...props}
    >
      <GlobalStyle status={status} />
      <Container.Header>
        {icon && <Container.Title className="title icon">{icon}</Container.Title>}
        {title && <Container.Title className="title">{title}</Container.Title>}

        {description && <Description color={descriptionColor}>{description}</Description>}

        {hideCloseButton && (
          <CloseButton onClick={() => onClose?.() || dispatch(closeGenericModalAction())}>
            <Icon name="close" />
          </CloseButton>
        )}
      </Container.Header>
      <Container.Body>
        {primaryLabel && !secondaryLabel && status && (
          <InfoPrimaryButton onClick={closeModal}>{primaryLabel}</InfoPrimaryButton>
        )}

        {primaryLabel && secondaryLabel && status && (
          <ActionButtonWrapper>
            <ActionSecondaryButton variant="tertiary" onClick={secondaryOnPress || closeModal}>
              {secondaryLabel}
            </ActionSecondaryButton>

            <ActionPrimaryButton variant="secondary" onClick={primaryOnPress}>
              {primaryLabel}
            </ActionPrimaryButton>
          </ActionButtonWrapper>
        )}
      </Container.Body>

      <Container.Footer>
        {bottomLinkLabel && (
          <BottomMessageLink onClick={bottomLinkProps?.onClick}>
            {bottomLinkProps?.icon && <Icon name={bottomLinkProps?.icon} />}
            <Description color={bottomLinkProps?.color || "CARDINAL_TEAL_COLOR"}>
              {bottomLinkLabel}
            </Description>
          </BottomMessageLink>
        )}
      </Container.Footer>
      <ModalFooter />
    </Container>
  );
};
