/**
 *
 * @description responseToFile a method for converting files to shareable files
 * @function responseToFile
 * @property blob: Blob, filename: string, fileType: string
 * @returns void
 */

const responseToFile = (blob: Blob, filename: string, fileType: string) => {
  return new File([blob], filename, { type: fileType, lastModified: new Date().getTime() });
};

export default responseToFile;
