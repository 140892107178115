import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopProvider: React.FC<any> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const pageLayoutChildren = document.querySelector(".page__layout__children__wrapper");

    // Reset Desktop scroll position
    pageLayoutChildren?.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // Reset Mobile scroll position
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return children || null;
};

export default ScrollToTopProvider;
