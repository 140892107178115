import { IStateTree, ThunkDispatchType } from "typings/store";
import { getUserSelectedRedeemNftDetails } from "@app/network/redeem";
import { getUserNftsApi, getUserSelectedUserNftDetails } from "@app/network/nft";

import {
  SET_USER_NFTS,
  SET_NFT_LOADING,
  SET_NFT_FAILURE,
  SET_USER_SELECTED_NFT_DETAILS,
} from "../types";
import {
  IUserNftType,
  SetUserNftsAction,
  SetNftsLoadingAction,
  SetNftsFailureAction,
  SetUserSelectedNftDetailsAction,
  IGetSelectedNftDetailsParamsType,
} from "../models";
import { openGenericServerErrorModalAction } from "../../genericModal";
import { IGetSelectedRedeemNftDetailsParamsType } from "../../redeem/models";

export const setNftsLoadingAction = (): SetNftsLoadingAction => ({
  type: SET_NFT_LOADING,
});

export const setUserNftsAction = (payload: IUserNftType[]): SetUserNftsAction => ({
  payload,
  type: SET_USER_NFTS,
});

export const userNftsFailureAction = (error: Error | null): SetNftsFailureAction => ({
  type: SET_NFT_FAILURE,
  payload: error,
});

export const setUserSelectedNftDetailsAction = (
  payload: IUserNftType | null
): SetUserSelectedNftDetailsAction => ({
  payload,
  type: SET_USER_SELECTED_NFT_DETAILS,
});

export const getUserSelectedUserNftDetailsAction = (
  payload: IGetSelectedNftDetailsParamsType,
  state?: CTLocation["state"],
  onSuccess?: (data: IUserNftType) => void
) => {
  return async (dispatch: ThunkDispatchType) => {
    dispatch(setNftsLoadingAction());
    try {
      if (state?.isCollection) {
        const { data } = await getUserSelectedUserNftDetails(payload);
        dispatch(setUserSelectedNftDetailsAction(data));
        onSuccess?.(data);
      }

      if (state?.isRedeem) {
        const { data } = await getUserSelectedRedeemNftDetails(payload);
        dispatch(setUserSelectedNftDetailsAction(data));
        onSuccess?.(data);
      }
    } catch (error) {
      dispatch(userNftsFailureAction(error as Error));
      dispatch(openGenericServerErrorModalAction());
    }
  };
};

export const getUserNftsAction = (
  payload: Omit<IGetSelectedRedeemNftDetailsParamsType, "nftCategoryId">,
  onSuccess?: (data: IUserNftType[]) => void
) => {
  return async (dispatch: ThunkDispatchType, getState: () => IStateTree) => {
    dispatch(setNftsLoadingAction());
    try {
      const { data } = await getUserNftsApi(payload);

      // THIS IS DONE AS TO REMOVE DUPLICATES ON PAGE REFRESH
      const { userNfts: previousUserNfts } = getState().nft;
      const userNfts = !payload?.offset ? data : [...previousUserNfts, ...data];

      dispatch(setUserNftsAction(userNfts));
      onSuccess?.(data);
    } catch (error) {
      dispatch(userNftsFailureAction(error as Error));
    }
  };
};
