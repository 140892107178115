import styled, { createGlobalStyle } from "styled-components";

export { LogoIcon, LogoTitle, LogoWrapper } from "@app/components/molecules/Header/Header.styles";

export const GlobalStyle = createGlobalStyle`
  body {
    .footer__container {
      background-color: ${({ theme }) => theme.colors.ONIX_DEEP_COLOR};
    }

    #scrollable_div {
      height: 92vh;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 3rem 0;
  align-items: center;
  flex-direction: column;

  .logo-wrapper {
    margin-bottom: 5rem;

    h3 {
      display: flex;
      font-size: 2rem;
    }
  }

  .policy-container {
    margin: 5rem 0;
    max-width: 90rem;
    font-size: 1.6rem;
    line-height: 1.875;
    padding: 0 2.5rem;
    font-weight: normal;

    ul {
      padding: 0 0 20px 0;

      li {
        z-index: 1;
        list-style: none;
        line-height: 4rem;
        position: relative;
        padding-left: 2rem;

        ::before {
          content: "";
          left: 0;
          top: 45%;
          width: 5px;
          bottom: 45%;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          background-color: #7d75fb;
        }
      }
    }

    h1 {
      font-size: 4.2rem;
      line-height: 4.8rem;
    }

    h4 {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }

    h1,
    h4,
    p > strong {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};
    }

    p {
      margin: 0 0 2rem 0;

      a {
        color: #f16634;
        transition: color 300ms;
      }
    }
  }
`;
