import React, { useState, Fragment } from "react";

import { Icon } from "../Icon";
import { InputBaseProps } from "./Input.interface";

import { Container, InputControl, Error, ErrorWrapper } from "./Input.styles";

export const Input: React.FC<InputBaseProps> = ({
  as,
  type,
  value,
  error,
  left_icon,
  maxLength,
  minLength,
  error_icon,
  right_icon,
  defaultValue,
  disabled = false,
  placeholder = "Search",
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(0);

  return (
    <Fragment>
      <Container {...props} inputtype={as} isfocused={isFocused} error={error?.message}>
        {left_icon && <Icon name={left_icon} className="left_input_icon" />}
        <InputControl
          as={as}
          type={type}
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          className="input__field"
          aria-label={placeholder}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onBlur={() => setIsFocused(0)}
          onFocus={() => setIsFocused(1)}
        />
        {right_icon && <Icon name={right_icon} className="right_input_icon" />}
      </Container>

      {error ? (
        <ErrorWrapper>
          {error_icon && <Icon name={error_icon} className="error_icon" />}
          <Error className="input_error">{error?.message}</Error>
        </ErrorWrapper>
      ) : null}
    </Fragment>
  );
};
