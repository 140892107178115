import { defineMessages } from "react-intl";

const messages = defineMessages({
  send_magic_link: {
    id: "signup.email_login.send_magic_link",
    defaultMessage: "Send Me the Magic Link",
  },
  placeholder: {
    id: "signup.email_login.placeholder",
    defaultMessage: "Enter your email address here",
  },
  title: {
    id: "signup.send_email_modal.title",
    defaultMessage: "✨ An email with a magic link has been sent to your email address.",
  },
  description: {
    id: "signup.send_email_modal.description",
    defaultMessage: "Please follow the instructions.",
  },
  accept: {
    id: "signup.send_email_modal.accept",
    defaultMessage: "Back To Log In",
  },
});

export default messages;
