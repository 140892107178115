import React from "react";

import { Account, UserInformation, SessionTermination } from "./component";

import {
  QRCode,
  Contents,
  Container,
  GlobalStyle,
  ProfileHeader,
  ContentWrapper,
  QRCodeContainer,
  QRCodeBackground,
} from "./Profile.styles";
import ReactGA from "react-ga";
import { APP_URL_LIST } from "@app/routes/urls";

const Profile: React.FC<{}> = () => {
  React.useEffect(() => {
    ReactGA.pageview(`${APP_URL_LIST.PROFILE_URL}`);
  }, []);
  return (
    <Container>
      <GlobalStyle />
      <Contents>
        <ProfileHeader>
          <QRCodeContainer>
            <QRCodeBackground>
              <QRCode value="https://reactjs.org/" />
            </QRCodeBackground>
          </QRCodeContainer>
        </ProfileHeader>

        <ContentWrapper>
          <Account />
          <UserInformation />
          <SessionTermination />

          <div className="content_wrapper_for_desktop">
            <Account />
            <SessionTermination />
          </div>
        </ContentWrapper>
      </Contents>
    </Container>
  );
};

export default Profile;
