import React, { Fragment, useState } from "react";
import useImageColor from "use-image-color";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { translator } from "@app/providers/internationalization/IntlProvider";

import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";
import { getUserToken } from "@app/providers/store/session";
import { INftTypes, IUserNftType } from "@app/providers/store/nft/models";
import { setUserSelectedNftDetailsAction } from "@app/providers/store/nft";
import { GenericModalProperties } from "@app/providers/store/genericModal/models";
import {
  openGenericModalAction,
  closeGenericModalAction,
  openUnauthorizedModalAction,
  openNonFunctionalYetModalAction,
  openGenericServerErrorModalAction,
} from "@app/providers/store/genericModal";
import {
  getRedeemCollectionNftsAction,
  addRedeemToUserCollectionAction,
  getUserSelectedRedeemNftDetails,
} from "@app/providers/store/redeem";
import { priceResolver } from "@app/helpers";
import messages from "./messages";

import {
  Title,
  PriceTag,
  Container,
  NFTButton,
  NFTSellButton,
  NFTFreeButton,
  NFTPriceButton,
  NFTImageBackDrop,
  NFTButtonWrapper,
  NFTTransferButton,
  NFTWithdrawButton,
  NFTImageBackground,
  NFTImageBackgroundContainer,
} from "./NftRedeemCard.styles";

export const NftRedeemCard: React.FC<IUserNftType & { isMinimized?: boolean }> = ({
  isMinimized = false,
  ...nft
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { collectionId } = useParams();
  const userToken = useSelector(getUserToken);
  const [isLoading, setIsLoading] = useState(false);
  const { state, pathname } = useLocation() as CTLocation;

  const redeem = useSelector(getUserSelectedRedeemNftDetails, shallowEqual);

  const {
    price,
    nftId,
    nftName,
    nftType,
    nftAsset,
    isSellable,
    resalePrice,
    nftThumbnail,
    nftSmallThumbnail,
    isWithdrawable,
    isTransferable,
  } = nft;

  const isFreeNFT = price === "0.01 COME";

  const collection = redeem?.collections?.find(
    (col) => Number(col.collectionId) === Number(collectionId)
  );

  const { colors: gradientColors } = useImageColor(nftSmallThumbnail || nftAsset, {
    cors: true,
    colors: 2,
  });

  // CHANGE THE NAVIGATION ROUTE TO ORIGINAL ROUTE
  const handleNavigate = () => {
    dispatch(setUserSelectedNftDetailsAction(nft));
    navigate(
      state?.isCollection
        ? `${APP_URL_LIST.COLLECTION_URL}/${nftId}`
        : `${APP_URL_LIST.REDEEM_URL}/${collectionId}/${nftId}`
    );
  };

  const addToCollection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (!userToken) {
      return dispatch(
        openUnauthorizedModalAction({
          primaryOnPress: () => navigate(APP_URL_LIST.SIGNUP_URL, { state: { path: pathname } }),
        })
      );
    }

    if (isFreeNFT) {
      setIsLoading(true);
      const modalParams: GenericModalProperties = {
        icon: "🛍",
        status: "success",
        title: formatMessage(messages.modal_title),
        primaryLabel: formatMessage(messages.modal_primary_label),
        bottomLinkLabel: formatMessage(messages.modal_bottomLink_label),
        primaryOnPress: () => {
          dispatch(getRedeemCollectionNftsAction({ nftCategoryId: String(collectionId) }));
          dispatch(closeGenericModalAction());
        },
        bottomLinkProps: {
          icon: "wallet",
          onClick: () => {
            navigate(APP_URL_LIST.COLLECTION_URL);
            dispatch(closeGenericModalAction());
            dispatch(getRedeemCollectionNftsAction({ nftCategoryId: String(collectionId) }));
          },
        },
      };

      const onCallback = (error?: Record<string, any>) => {
        setIsLoading(false);
        if (error) {
          const payloadError =
            error?.extraData?.type === "whiteListError"
              ? {
                  title: translator.formatMessage(messages.not_white_listed),
                  description: error?.message,
                }
              : { description: error?.message };
          dispatch(openGenericServerErrorModalAction({ ...payloadError }));
        } else {
          dispatch(openGenericModalAction(modalParams));
        }
      };

      return dispatch(
        addRedeemToUserCollectionAction(
          {
            nftId: Number(nftId),
            eventId: Number(redeem?.eventId),
            collectionName: `${collection?.collectionName}`,
          },
          onCallback
        )
      );
    } else {
      dispatch(
        setUserSelectedNftDetailsAction({ nftCategory: collection?.collectionName, ...nft })
      );
      navigate(
        `${APP_URL_LIST.BUY_NFTS}/${Number(redeem?.eventId)}/${Number(
          collection?.collectionId
        )}/${Number(nftId)}`
      );
    }
  };

  const handleActions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(openNonFunctionalYetModalAction());
  };

  return (
    <Container
      colors={gradientColors}
      onClick={handleNavigate}
      isMinimized={isMinimized}
      className={isMinimized ? "nft__min__redeem__card" : "nft__max__redeem__card"}
    >
      <NFTImageBackgroundContainer isMinimized={isMinimized}>
        <NFTImageBackground alt="NFT picture art" srcList={[nftAsset, nftSmallThumbnail]} />
      </NFTImageBackgroundContainer>

      <NFTImageBackDrop isMinimized={isMinimized} className="image__backdrop">
        {!isMinimized && (
          <Fragment>
            {nftType === INftTypes.GIF && <Icon name="gif" />}
            {nftType === INftTypes.SOUND && <Icon name="audio" />}
            {nftType === INftTypes.VIDEO && <Icon name="video" />}
            <Title>{nftName}</Title>
          </Fragment>
        )}
      </NFTImageBackDrop>

      {state?.isCollection && !isMinimized ? (
        <NFTButtonWrapper>
          <Fragment>
            {isTransferable && (
              <NFTTransferButton
                icon="share"
                isLoading={isLoading}
                iconDirection="column"
                onClick={handleActions}
              >
                <FormattedMessage {...messages.transfer} />
              </NFTTransferButton>
            )}

            {isWithdrawable && (
              <NFTWithdrawButton
                icon="withdraw"
                isLoading={isLoading}
                iconDirection="column"
                onClick={handleActions}
              >
                <FormattedMessage {...messages.withdraw} />
              </NFTWithdrawButton>
            )}

            {isSellable && (
              <NFTSellButton
                isLoading={isLoading}
                iconDirection="column"
                onClick={handleActions}
                icon={Number(resalePrice?.split(" ")[0]) ? "unSellNft" : "sellNFT"}
              >
                <FormattedMessage {...messages.sell} />
              </NFTSellButton>
            )}
          </Fragment>
        </NFTButtonWrapper>
      ) : null}

      {state?.isRedeem ? (
        <NFTButtonWrapper>
          <NFTFreeButton isMinimized={isMinimized}>
            <PriceTag>
              {!isFreeNFT ? (
                `€${priceResolver(nft?.price)}`
              ) : (
                <FormattedMessage {...messages.free} />
              )}
            </PriceTag>
          </NFTFreeButton>

          {!isMinimized &&
            (isFreeNFT ? (
              <NFTButton
                icon="wallet"
                isLoading={isLoading}
                iconDirection="column"
                onClick={addToCollection}
              >
                <FormattedMessage {...messages.add_to_collection} />
              </NFTButton>
            ) : (
              <NFTPriceButton
                icon="buy"
                isLoading={isLoading}
                iconDirection="column"
                onClick={addToCollection}
              >
                <FormattedMessage {...messages.buy_now} />
              </NFTPriceButton>
            ))}
        </NFTButtonWrapper>
      ) : null}
    </Container>
  );
};
