import { IStateTree, ThunkDispatchType } from "typings/store";
import { translator } from "@app/providers/internationalization/IntlProvider";

import messages from "./messages";
import { logoutUserAction } from "../../session";
import { getRedeemCollectionNftsAction } from "../../redeem";
import { OPEN_GENERIC_MODAL, CLOSE_GENERIC_MODAL } from "../types";
import { GenericModalProperties, OpenGenericModalAction, CloseGenericModalAction } from "../models";

export const openGenericModalAction = (
  payload: GenericModalProperties
): OpenGenericModalAction => ({
  payload,
  type: OPEN_GENERIC_MODAL,
});

export const closeGenericModalAction = (): CloseGenericModalAction => ({
  type: CLOSE_GENERIC_MODAL,
});

/**
 * Action that is triggered when there is a server error
 * @param payload {@see GenericModalProperties}
 * @param errorMessage | The localized error message returned from server
 */
export const openGenericServerErrorModalAction = (payload?: GenericModalProperties) => {
  const modalParams: GenericModalProperties = {
    icon: "😓",
    status: "error",
    primaryOnPress: () => closeGenericModalAction(),
    title: translator.formatMessage(messages.server_error_title),
    primaryLabel: translator.formatMessage(messages.close_modal),
    description: translator.formatMessage(messages.server_error_description),
    ...payload,
  };
  return openGenericModalAction(modalParams);
};

/**
 * Action that is triggered when there is a server error
 * @param payload {@see GenericModalProperties}
 */
const openDeleteAccountSuccessModalAction = (payload?: GenericModalProperties) => {
  const modalParams: GenericModalProperties = {
    icon: "✉️",
    status: "success",
    primaryOnPress: () => {
      logoutUserAction();
      closeGenericModalAction();
    },
    primaryLabel: translator.formatMessage(messages.close_modal),
    title: translator.formatMessage(messages.delete_account_completion_title),
    description: translator.formatMessage(messages.delete_account_completion_description),
    ...payload,
  };
  return openGenericModalAction(modalParams);
};

/**
 * Action that is triggered when there is a server error
 * @param payload {@see GenericModalProperties}
 */
export const openDeleteAccountConfirmationModalAction = (payload?: GenericModalProperties) => (
  dispatch: ThunkDispatchType
) => {
  const modalParams: GenericModalProperties = {
    icon: "😓",
    status: "warning",
    isTransparent: true,
    descriptionColor: "CANDY_RED_COLOR",
    secondaryOnPress: () => dispatch(closeGenericModalAction()),
    primaryOnPress: () => dispatch(openDeleteAccountSuccessModalAction()),
    title: translator.formatMessage(messages.delete_account_confirmation_title),
    description: translator.formatMessage(messages.delete_account_confirmation_description),
    primaryLabel: translator.formatMessage(messages.delete_account_confirmation_primary_label),
    secondaryLabel: translator.formatMessage(messages.delete_account_confirmation_secondary_label),
    ...payload,
  };

  dispatch(openGenericModalAction(modalParams));
};

/**
 * Action that is triggered when buying nft is successful
 * @param payload {@see GenericModalProperties}
 */
export const openSuccessfulBuyNftModalAction = (payload?: GenericModalProperties) => (
  dispatch: ThunkDispatchType,
  getState: () => IStateTree
) => {
  const userSelectedNftDetails = getState()?.nft?.userSelectedNftDetails;

  const modalParams: GenericModalProperties = {
    ...payload,
    icon: "🛍",
    status: "success",
    title: translator.formatMessage(messages.successful_buy_nft_title),
    primaryLabel: translator.formatMessage(messages.successful_buy_nft_primary_label),
    bottomLinkLabel: translator.formatMessage(messages.successful_buy_nft_bottom_link_label),
    primaryOnPress: () => {
      payload?.primaryOnPress?.();
      dispatch(
        getRedeemCollectionNftsAction({
          nftCategoryId: String(userSelectedNftDetails?.nftCategoryId),
        })
      );
      dispatch(closeGenericModalAction());
    },
    bottomLinkProps: {
      icon: "wallet",
      onClick: () => {
        payload?.bottomLinkProps?.onClick?.();
        dispatch(
          getRedeemCollectionNftsAction({
            nftCategoryId: String(userSelectedNftDetails?.nftCategoryId),
          })
        );
        dispatch(closeGenericModalAction());
      },
    },
  };

  dispatch(openGenericModalAction(modalParams));
};

/**
 * Action that is triggered when a feature is not yet implemented or functional
 * @param payload {@see GenericModalProperties}
 */
export const openNonFunctionalYetModalAction = (payload?: GenericModalProperties) => {
  const modalParams: GenericModalProperties = {
    icon: "🤦🏼‍♂️",
    status: "success",
    isTransparent: false,
    primaryOnPress: closeGenericModalAction,
    primaryLabel: translator.formatMessage(messages.close_modal),
    title: translator.formatMessage(messages.non_functional_confirmation_title),
    ...payload,
  };
  return openGenericModalAction(modalParams);
};

/**
 * Action that is triggered when a feature is not yet implemented or functional
 * @param payload {@see GenericModalProperties}
 */
export const openUnauthorizedModalAction = (payload?: GenericModalProperties) => {
  const modalParams: GenericModalProperties = {
    ...payload,
    icon: "🤦🏼‍♂️",
    isTransparent: false,
    hideCloseButton: true,
    title: translator.formatMessage(messages.authenticated_modal_title),
    description: translator.formatMessage(messages.authenticated_modal_subtitle),
    primaryLabel: translator.formatMessage(messages.authenticated_modal_primary_label),
    primaryOnPress: () => {
      payload?.primaryOnPress?.();
      closeGenericModalAction();
    },
  };
  return openGenericModalAction(modalParams);
};
