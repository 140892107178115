import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useWindowSize } from "usehooks-ts";
import { useTheme } from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
  userNftsFailureAction,
  getUserSelectedNftDetails,
  setUserSelectedNftDetailsAction,
  getUserSelectedUserNftDetailsAction,
} from "@app/providers/store/nft";
import {
  getNftDetailImageOverviewInstruction,
  setNftDetailImageOverviewInstructionDone,
} from "@app/providers/store/settings";
import { INftTypes } from "@app/providers/store/nft/models";
import {
  TopOverlay,
  AudioModal,
  VideoModal,
  ImageModal,
  BottomDetails,
  NFTDetailsActions,
} from "./components";
import messages from "./messages";

import {
  Image,
  Container,
  GlobalStyle,
  ImageContainer,
  ImageBackgroundOverlay,
  ImageBackgroundOverlayContents,
} from "./NftDetail.styles";
import ReactGA from "react-ga";
import { APP_URL_LIST } from "@app/routes/urls";

const NftDetail: React.FC<{}> = () => {
  const { nftId, collectionId } = useParams();
  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const { formatMessage } = useIntl();
  const { state } = useLocation() as CTLocation;
  const { width: screen_width } = useWindowSize();
  const [showDetails, setShowDetails] = useState(false);
  const [showImageScreen, setShowImageScreen] = useState(false);
  const [showAudioScreen, setShowAudioScreen] = useState(false);
  const [showVideoScreen, setShowVideoScreen] = useState(false);
  const { userSelectedNftDetails, error } = useSelector(getUserSelectedNftDetails, shallowEqual);
  const isNftDetailImageOverviewInstructionDone = useSelector(getNftDetailImageOverviewInstruction);

  React.useEffect(() => {
    return state?.isRedeem
      ? ReactGA.pageview(`${APP_URL_LIST.REDEEM_URL}/${collectionId}/${nftId}`)
      : ReactGA.pageview(`${APP_URL_LIST.COLLECTION_URL}/${nftId}`);
  }, []);

  useEffect(() => {
    /**
     * request a specific nft when the component mounts
     */
    dispatch(getUserSelectedUserNftDetailsAction({ nftId: Number(nftId) }, state));
  }, [dispatch, nftId, state]);

  useEffect(() => {
    /**
     * reset nft details store on error
     * TODO: NEED UI FOR THIS SCREEN IS BLANK WHEN THERE IS ERROR
     */
    if (error) {
      dispatch(userNftsFailureAction(null));
      dispatch(setUserSelectedNftDetailsAction(null));
    }
  }, [dispatch, error, userSelectedNftDetails]);

  const changeOnMobile =
    screen_width <
    Number(
      breakpoints.LARGE_VIEWPORT_IPAD_PRO.substring(
        0,
        breakpoints.LARGE_VIEWPORT_IPAD_PRO.length - 2
      )
    );

  useEffect(() => {
    const footerContainer = document.querySelector(".footer__container");
    const sideDrawerContent = document.querySelector(".side__drawer__container");
    const pageLayoutChildren = document.querySelector(".page__layout__children__wrapper");

    if (showImageScreen || showAudioScreen || showVideoScreen) {
      // REMOVE SIDEDRAWER AND NFT IMAGE FROM VIEW TO MOUNT IMAGE ZOOM COMPONENT
      sideDrawerContent?.setAttribute("style", "display: none");
      pageLayoutChildren?.setAttribute(
        "style",
        `justify-content: center; display: flex; margin-top: ${changeOnMobile ? 0 : 5}rem`
      );
      footerContainer?.setAttribute("style", `display: ${changeOnMobile ? "none" : "flex"} `);

      if (!isNftDetailImageOverviewInstructionDone) {
        // ADD A CLICK EVENT LISTENER TO REMOVE OVERVIEW INSTRUCTION IF IT'S MOUNTED
        pageLayoutChildren?.addEventListener("click", () => {
          dispatch(setNftDetailImageOverviewInstructionDone());

          // REMOVE EVENT LISTENER ON SUCCESSFUL PAGE CLICK WHEN OVERVIEW INSTRUCTION IS DONE
          pageLayoutChildren?.removeEventListener("click", () => undefined);
        });
      }
    } else {
      pageLayoutChildren?.setAttribute("style", `display: block; margin: 0`);
      footerContainer?.setAttribute("style", `display: ${changeOnMobile ? "none" : "flex"} `);
      sideDrawerContent?.setAttribute("style", `display: ${changeOnMobile ? "none" : "flex"} `);
    }

    // This helps to keep the image and the icon overlay always at the beginning of the page
    pageLayoutChildren?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [
    dispatch,
    changeOnMobile,
    showAudioScreen,
    showImageScreen,
    showVideoScreen,
    isNftDetailImageOverviewInstructionDone,
  ]);

  const handleImageClick = () => {
    // open image modal if nft is image or gif
    if (
      userSelectedNftDetails?.nftType === INftTypes.IMAGE ||
      userSelectedNftDetails?.nftType === INftTypes.GIF
    ) {
      setShowImageScreen(!showImageScreen);
    }

    // open audio modal if nft is sound
    if (userSelectedNftDetails?.nftType === INftTypes.SOUND) {
      if (state?.isCollection) {
        setShowAudioScreen(!showImageScreen);
      } else {
        toast(formatMessage(messages.play_content_toast));
      }
    }

    // open video modal if nft is video
    if (userSelectedNftDetails?.nftType === INftTypes.VIDEO) {
      if (state?.isCollection) {
        setShowVideoScreen(!showImageScreen);
      } else {
        toast(formatMessage(messages.play_content_toast));
      }
    }
  };

  return (
    <Fragment>
      {!(showImageScreen || showAudioScreen || showVideoScreen) ? (
        <Container onClick={handleImageClick}>
          <GlobalStyle />
          <Image
            nftType={userSelectedNftDetails?.nftType}
            srcList={[
              String(userSelectedNftDetails?.nftAsset),
              String(userSelectedNftDetails?.nftThumbnail),
            ]}
            container={(children) => (
              <ImageContainer>
                {children}
                <ImageBackgroundOverlay>
                  <NFTDetailsActions />

                  <ImageBackgroundOverlayContents>
                    <TopOverlay
                      showDetails={showDetails}
                      setShowDetails={() => setShowDetails(!showDetails)}
                    />
                  </ImageBackgroundOverlayContents>
                </ImageBackgroundOverlay>
              </ImageContainer>
            )}
          />
        </Container>
      ) : null}

      {!(showImageScreen || showAudioScreen) && showDetails && <BottomDetails />}

      {showImageScreen && (
        <ImageModal setShowImageScreen={() => setShowImageScreen(!showImageScreen)} />
      )}

      {showAudioScreen && (
        <AudioModal setShowAudioScreen={() => setShowAudioScreen(!showAudioScreen)} />
      )}

      {showVideoScreen && (
        <VideoModal setShowVideoScreen={() => setShowVideoScreen(!showVideoScreen)} />
      )}
    </Fragment>
  );
};

export default NftDetail;
