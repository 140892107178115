import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const SellNFT = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7541 2.47897L2.73371 10.4993C2.34318 10.8899 2.34318 11.523 2.73371 11.9136L8.64129 17.8211C9.03182 18.2117 9.66498 18.2117 10.0555 17.8211L18.0759 9.80077C18.3038 9.57282 18.4079 9.24895 18.3554 8.93087L17.5187 3.85997C17.449 3.43721 17.1176 3.10586 16.6949 3.03611L11.624 2.19942C11.3059 2.14694 10.982 2.25102 10.7541 2.47897ZM19.4901 11.215C20.174 10.5311 20.4862 9.55951 20.3287 8.60528L19.4921 3.53438C19.2828 2.26608 18.2888 1.27206 17.0205 1.06279L11.9496 0.2261C10.9953 0.0686529 10.0237 0.380891 9.33986 1.06476L1.31949 9.08513C0.147918 10.2567 0.14792 12.1562 1.31949 13.3278L7.22708 19.2354C8.39865 20.4069 10.2981 20.4069 11.4697 19.2354L19.4901 11.215ZM12.8043 6.61185C12.4903 6.92584 12.4903 7.43493 12.8043 7.74893C13.1183 8.06292 13.6274 8.06292 13.9414 7.74893C14.2554 7.43493 14.2554 6.92584 13.9414 6.61185C13.6274 6.29785 13.1183 6.29785 12.8043 6.61185ZM11.3901 5.19763C10.295 6.29268 10.295 8.0681 11.3901 9.16314C12.4851 10.2582 14.2605 10.2582 15.3556 9.16314C16.4506 8.0681 16.4506 6.29268 15.3556 5.19763C14.2605 4.10259 12.4851 4.10259 11.3901 5.19763Z"
        fill={colors[color || "WHITE_COLOR"]}
      />
    </svg>
  );
};

export default SellNFT;
