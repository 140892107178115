import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "card_details.title",
    defaultMessage: "Card Details",
  },
  info: {
    id: "card_details.info",
    defaultMessage:
      "We require your preferred payment card details in order to finish the selling process.",
  },
  checkout: {
    id: "card_details.checkout",
    defaultMessage: "Checkout",
  },
  description: {
    id: "card_details.description",
    defaultMessage:
      "To View your ticket please follow the instructions in your {email} account email",
  },
  cancel: {
    id: "card_details.cancel",
    defaultMessage: "Cancel",
  },
});

export default messages;
