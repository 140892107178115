import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";
import messages from "@app/pages/buy-nfts/messages";
import { GenericModalProperties } from "@app/providers/store/genericModal/models";
import { closeGenericModalAction, openGenericModalAction } from "@app/providers/store/genericModal";

import { TimerWrapper, TimerText } from "./Timer.style";

const Timer: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [timeRemaining, setTimeRemaining] = useState(900); // 900 seconds = 15min

  // checkout coundown timer that displays when time is up
  useEffect(() => {
    const otpTimer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev === 1) {
          clearInterval(otpTimer);
          // open timeout modal
          const modalParams: GenericModalProperties = {
            icon: "⏰ Oops!",
            status: "error",
            title: formatMessage(messages.modal_title),
            primaryLabel: formatMessage(messages.modal_primary_label),
            primaryOnPress: () => {
              navigate(APP_URL_LIST.REDEEM_URL);
              dispatch(closeGenericModalAction());
            },
          };
          dispatch(openGenericModalAction(modalParams));
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(otpTimer);
    };
  }, [dispatch, formatMessage, navigate]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = (timeRemaining - minutes * 60).toString().padStart(2, "0");

  return (
    <TimerWrapper>
      <TimerText>{`${minutes}:${seconds} min`}</TimerText>
      <Icon name="timer" color="CANDY_RED_COLOR" />
    </TimerWrapper>
  );
};
export default Timer;
