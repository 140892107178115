import styled from "styled-components";

import { Button } from "@app/components/atoms";

export const AppleButton = styled(Button).attrs({ icon: "apple" })`
  margin: 0px 1rem;
  background: ${({ theme }) => theme.colors.WHITE_COLOR};
  box-shadow: 0px -2px 9px rgba(122, 207, 255, 0.47),
    inset 0px 0px 8px 1px rgba(107, 255, 237, 0.51);

  &:hover {
    background: ${({ theme }) => theme.colors.ONIX_DARK_COLOR} !important;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.WHITE_COLOR};
      }
    }
  }

  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.WHITE_COLOR} !important;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.BLACK_COLOR};
      }
    }
  }
`;
