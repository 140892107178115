import styled, { css, DefaultTheme } from "styled-components";

export { HeaderBackTitle } from "@app/pages/redeem-overview/RedeemOverview.styles";
import { HeaderBackButton as RedeemHeaderBackButton } from "@app/pages/redeem-overview/RedeemOverview.styles";

export const Container = styled.div<{ isNftDetailScreen: boolean }>`
  height: 100%;
  display: none;
  margin-right: 5.6rem;
  padding: 6rem 1.7rem;
  align-items: flex-end;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};
  background-image: url("/assets/svg/side-drawer-background.svg");

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    width: 34rem;
    display: flex;
    /* padding-right: 3rem; */
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_DESKTOP_VIEW}) {
    width: 50rem;
    margin-right: 7.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.DESKTOP_FULL_HD_VIEW}) {
    width: 76rem;
  }

  ${({ isNftDetailScreen }) => {
    if (isNftDetailScreen) {
      return css`
        margin: 0 !important;

        @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
          width: 40rem;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_DESKTOP_VIEW}) {
          width: 60rem;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.DESKTOP_FULL_HD_VIEW}) {
          width: 80rem;
        }
      `;
    }
  }}
`;

export const ContentWrapper = styled.div`
  flex: 1;
  width: 80%;
  height: 70vh;
  overflow-y: scroll;
  padding-bottom: 5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_DESKTOP_VIEW}) {
    width: 70%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    padding-right: 3rem;
  }
`;

export const Title = styled.h3<{ color?: keyof DefaultTheme["colors"] }>`
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1.6rem;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme, color }) => theme.colors[color || "SPACE_SHADE_COLOR"]};

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_DESKTOP_VIEW}) {
    font-size: 5rem;
  }
`;

export const HeaderBackButton = styled(RedeemHeaderBackButton)`
  display: flex;
  margin-bottom: 1.6rem;
`;
