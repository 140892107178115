import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";

import { getUserData } from "@app/providers/store/session";
import messages from "./messages";

import { Title, Divider, Contents, SubTitle, Container, EditButton } from "./UserInformation.style";

const UserInformation: React.FC<{}> = () => {
  const user = useSelector(getUserData, shallowEqual);

  return (
    <Container>
      <Contents marginBottom={true}>
        <Title>
          <FormattedMessage {...messages.user_information} />
        </Title>

        <EditButton icon="edit" onClick={() => console.log("EDIT PRESSED")} />
      </Contents>

      <Contents>
        <SubTitle>
          <FormattedMessage {...messages.first_name} />
        </SubTitle>
        <SubTitle color="SPACE_PURE_COLOR">
          <FormattedMessage
            {...messages.add_first_name}
            values={{ firstName: Boolean(user?.firstName), value: user?.firstName }}
          />
        </SubTitle>
      </Contents>
      <Divider />

      <Contents>
        <SubTitle>
          <FormattedMessage {...messages.last_name} />
        </SubTitle>
        <SubTitle color="SPACE_PURE_COLOR">
          <FormattedMessage
            {...messages.add_last_name}
            values={{ lastName: Boolean(user?.lastName), value: user?.lastName }}
          />
        </SubTitle>
      </Contents>
      <Divider />

      <Contents>
        <SubTitle>
          <FormattedMessage {...messages.email} />
        </SubTitle>
        <SubTitle color="SPACE_PURE_COLOR">
          <FormattedMessage
            {...messages.add_email}
            values={{ email: Boolean(user?.email), value: user?.email }}
          />
        </SubTitle>
      </Contents>
      <Divider />

      <Contents>
        <SubTitle>
          <FormattedMessage {...messages.country} />
        </SubTitle>

        <SubTitle color="SPACE_PURE_COLOR">
          <FormattedMessage
            {...messages.add_country_name}
            values={{ country: Boolean(user?.country), value: user?.country }}
          />
        </SubTitle>
      </Contents>
    </Container>
  );
};

export default UserInformation;
