import styled from "styled-components";
import { Spinner as Loader } from "react-bootstrap";

import { IconProps } from "../Icon.interface";

const Spinner = styled(Loader)<IconProps>`
  width: 3rem;
  height: 3rem;
  margin: 0rem 2rem;
  border-width: 0.4rem;
  color: ${({ theme, color }) => color && theme.colors[color]};
`;

const Loading = (props?: IconProps) => <Spinner animation="border" role="status" {...props} />;

export default Loading;
