import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Hunt = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="21"
      height="22"
      fill="none"
      viewBox="0 0 21 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={colors[color || "SPACE_DARK_COLOR"]}
        d="M5.48039 0.220703C4.97229 0.220703 4.56039 0.632602 4.56039 1.1407V2.61106H3.39258C1.73572 2.61106 0.392578 3.9542 0.392578 5.61106V18.0669C0.392578 19.7237 1.73572 21.0669 3.39258 21.0669H17.4074C19.0643 21.0669 20.4074 19.7237 20.4074 18.0669V5.61106C20.4074 3.9542 19.0643 2.61106 17.4074 2.61106H16.2402V1.1407C16.2402 0.632601 15.8283 0.220703 15.3202 0.220703C14.8121 0.220703 14.4002 0.632602 14.4002 1.1407V2.61106H6.40039V1.1407C6.40039 0.632601 5.98849 0.220703 5.48039 0.220703ZM3.39258 4.61106H4.56039V4.67143C4.56039 5.17954 4.97229 5.59143 5.48039 5.59143C5.98849 5.59143 6.40039 5.17953 6.40039 4.67143V4.61106H14.4002V4.67143C14.4002 5.17954 14.8121 5.59143 15.3202 5.59143C15.8283 5.59143 16.2402 5.17953 16.2402 4.67143V4.61106H17.4074C17.9597 4.61106 18.4074 5.05877 18.4074 5.61106V7.60693H2.39258V5.61106C2.39258 5.05877 2.84029 4.61106 3.39258 4.61106ZM2.39258 9.60693V18.0669C2.39258 18.6191 2.84029 19.0669 3.39258 19.0669H17.4074C17.9597 19.0669 18.4074 18.6191 18.4074 18.0669V9.60693H2.39258Z"
      />
    </svg>
  );
};

export default Hunt;
