import { useEffect, FC, useMemo, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider as StyledThemeProvider, DefaultTheme } from "styled-components";

import { hexToRGB } from "@app/helpers";
import { IColorMode } from "@app/providers/store/settings/models";
import { setColorMode } from "@app/providers/store/settings/actions";

import GlobalStyle from "./reset";
import { IStateTree } from "typings/store";
import { BREAK_POINTS, DARK_MODE_COLORS, LIGHT_MODE_COLORS } from "./style";

const ThemeProvider: FC = ({ children }) => {
  // Get the browser-color mode [if it's dark or light mode]
  const dispatch = useDispatch();
  const { colorMode } = useSelector(({ settings }: IStateTree) => settings);

  const isDarkMode = colorMode === IColorMode.DARK;

  const toggleTheme = useCallback(
    () =>
      dispatch(setColorMode(colorMode === IColorMode.LIGHT ? IColorMode.DARK : IColorMode.LIGHT)),
    [dispatch, colorMode]
  );

  // Conditionally change the theme-color from dark to light-mode and vise-versa
  const theme: DefaultTheme = useMemo(
    () => ({
      hexToRGB,
      isDarkMode,
      toggleTheme,
      breakpoints: BREAK_POINTS,
      colors: isDarkMode ? DARK_MODE_COLORS : LIGHT_MODE_COLORS,
    }),
    [isDarkMode, toggleTheme]
  );

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia(`(prefers-color-scheme: ${IColorMode.DARK})`)
      .addEventListener("change", (e) =>
        dispatch(setColorMode(e.matches ? IColorMode.DARK : IColorMode.LIGHT))
      );

    // Remove listener
    return () => {
      window
        .matchMedia(`(prefers-color-scheme: ${IColorMode.DARK})`)
        .removeEventListener("change", (e) =>
          dispatch(setColorMode(e.matches ? IColorMode.DARK : IColorMode.LIGHT))
        );
    };
  }, [dispatch]);

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
      <ToastContainer theme={IColorMode.DARK} bodyClassName="toast_container" autoClose={3000} />
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
