import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {
  Redeem,
  Signup,
  Profile,
  NotFound,
  NftDetail,
  PrivacyPolicy,
  RedeemOverview,
  TermsAndConditions,
} from "@app/pages";
import { BuyNFTs } from "@app/pages/buy-nfts";
import { PageLayout } from "@app/components/organisms";
import { GenericModal } from "@app/components/molecules";
import { getUserData } from "@app/providers/store/session";
import ScrollToTopProvider from "@app/providers/scroll-to-top";
import { PrivateRoute, PublicRoute } from "./utils";
import { APP_URL_LIST } from "./urls";

export default function RootRouter() {
  const user = useSelector(getUserData, shallowEqual);

  /**
   * Use effect that sets logged in user to sentry for session tracking
   */
  useEffect(() => {
    if (user?.eosId) {
      Sentry.setUser({ ...user });
    }
  }, [user, user?.eosId]);

  return (
    <Router>
      <ScrollToTopProvider>
        <Routes>
          <Route path={APP_URL_LIST.BUY_NFTS}>
            <Route path=":eventId">
              <Route path=":nftCategoryId">
                <Route
                  path=":nftId"
                  element={
                    <PrivateRoute>
                      <PageLayout>
                        <BuyNFTs />
                      </PageLayout>
                    </PrivateRoute>
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route path={APP_URL_LIST.REDEEM_URL}>
            <Route path=":collectionId">
              <Route
                path=":nftId"
                element={
                  <PrivateRoute>
                    <PageLayout>
                      <NftDetail />
                    </PageLayout>
                  </PrivateRoute>
                }
              />

              <Route
                index={true}
                element={
                  <PrivateRoute>
                    <PageLayout>
                      <RedeemOverview />
                    </PageLayout>
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              index={true}
              element={
                <PrivateRoute>
                  <PageLayout>
                    <Redeem />
                  </PageLayout>
                </PrivateRoute>
              }
            />
          </Route>

          <Route path={APP_URL_LIST.COLLECTION_URL}>
            <Route
              path=":nftId"
              element={
                <PrivateRoute>
                  <PageLayout>
                    <NftDetail />
                  </PageLayout>
                </PrivateRoute>
              }
            />
            <Route
              index={true}
              element={
                <PrivateRoute>
                  <PageLayout>
                    <RedeemOverview />
                  </PageLayout>
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path={APP_URL_LIST.PROFILE_URL}
            element={
              <PrivateRoute>
                <PageLayout>
                  <Profile />
                </PageLayout>
              </PrivateRoute>
            }
          />

          <Route
            path={APP_URL_LIST.TERMS_AND_CONDITION_URL}
            element={
              <PublicRoute>
                <PageLayout>
                  <TermsAndConditions />
                </PageLayout>
              </PublicRoute>
            }
          />

          <Route
            path={APP_URL_LIST.PRIVACY_POLICY_URL}
            element={
              <PublicRoute>
                <PageLayout>
                  <PrivacyPolicy />
                </PageLayout>
              </PublicRoute>
            }
          />

          <Route
            path={APP_URL_LIST.SIGNUP_URL}
            element={
              <PublicRoute>
                <Signup />
              </PublicRoute>
            }
          />
          <Route
            path={APP_URL_LIST.NOT_FOUND_URL}
            element={
              <PublicRoute>
                <NotFound />
              </PublicRoute>
            }
          />
        </Routes>
        <GenericModal />
      </ScrollToTopProvider>
    </Router>
  );
}
