/**
 * Constant that denotes the Base auth url for authentication route for login
 */
export const LOGIN_URL = "/login";

/**
 * Constant that denotes the url for the send login email endpoint
 */
export const SEND_LOGIN_EMAIL = `${LOGIN_URL}/sendLoginEmail`;

/**
 * Constant that denotes the url for the verify login email endpoint
 */
export const VERIFY_LOGIN_EMAIL = `${LOGIN_URL}/verifyLoginEmail`;
