import {
  INFTRedeemType,
  ICreatePaymentIntentMetadataType,
  IAddRedeemToUserCollectionParamsType,
  IGetSelectedRedeemNftDetailsParamsType,
  IIsWhiteListedInterfaceParamsType,
} from "@app/providers/store/redeem/models";
import { ResponseInterface } from "typings";
import { DEFAULT_PAGINATION } from "@app/constants";
import { IGetSelectedNftDetailsParamsType, IUserNftType } from "@app/providers/store/nft/models";
import {
  CANCEL_ORDER_NFT_URL,
  CREATE_ORDER_NFT_URL,
  REDEEM_CATEGORY_NFT_URL,
  CREATE_PAYMENT_INTENT_URL,
  USER_REDEEM_COLLECTION_URL,
  ADD_REDEEM_TO_USER_COLLECTION_URL,
  GET_USER_SELECTED_REDEEM_NFT_DETAIL_URL,
  IS_WHITE_LISTED_URL,
} from "@app/network/urls/redeem";
import { network } from "./api";

/**
 *
 * @description get all cometogether avaliable nft collections
 * @function getRedeemCollections
 * @returns Promise<ResponseInterface<INFTRedeemType[]>
 */
export const getRedeemCollectionsApi = async (): Promise<ResponseInterface<INFTRedeemType[]>> => {
  const { data } = await network.get<ResponseInterface<INFTRedeemType[]>>(
    USER_REDEEM_COLLECTION_URL
  );

  return data;
};

/**
 *
 * @description get all cometogether avaliable nft redeem collections
 * @function getRedeemCollectionNfts
 * @returns Promise<ResponseInterface<IUserNftType[]>
 */
export const getRedeemCollectionNftsApi = async (
  payload: IGetSelectedRedeemNftDetailsParamsType
): Promise<ResponseInterface<IUserNftType[]>> => {
  const { data } = await network.get<ResponseInterface<IUserNftType[]>>(REDEEM_CATEGORY_NFT_URL, {
    params: {
      limit: DEFAULT_PAGINATION.LIMIT,
      offset: DEFAULT_PAGINATION.OFF_SET,
      ...payload,
    },
  });

  return data;
};

/**
 *
 * @description add redeem nft to a cometogether user nft wallet collections
 * @function addRedeemToUserCollection
 * @param IAddRedeemToUserCollectionParamsType
 * @returns Promise<ResponseInterface<IUserNftType[]>
 */
export const addRedeemToUserCollectionApi = async (
  payload: IAddRedeemToUserCollectionParamsType
): Promise<ResponseInterface<IUserNftType[]>> => {
  const { data } = await network.post<ResponseInterface<IUserNftType[]>>(
    ADD_REDEEM_TO_USER_COLLECTION_URL,
    { ...payload }
  );

  return data;
};

/**
 * @description post request to check if a user is whitelisted for a specific nft category
 * @function isWhiteListedApi
 * @param IIsWhiteListedInterfaceParamsType
 * @returns Promise<ResponseInterface<null>>
 */
export const isWhiteListedApi = async (
  payload: IIsWhiteListedInterfaceParamsType
): Promise<ResponseInterface<null>> => {
  const { data } = await network.post<ResponseInterface<null>>(IS_WHITE_LISTED_URL, {
    ...payload,
  });
  return data;
};

/**
 *
 * @description add redeem nft to a cometogether user nft wallet collections
 * @function getUserSelectedRedeemNftDetails
 * @param IGetSelectedNftDetailsParamsType
 * @returns Promise<ResponseInterface<IUserNftType>
 */
export const getUserSelectedRedeemNftDetails = async (
  payload: IGetSelectedNftDetailsParamsType
): Promise<ResponseInterface<IUserNftType>> => {
  const { data } = await network.get<ResponseInterface<IUserNftType>>(
    GET_USER_SELECTED_REDEEM_NFT_DETAIL_URL,
    { params: payload }
  );

  return data;
};

/**
 *
 * @description cancel order in NFT. Typically it unlocks the current locked NFT in database
 * @function cancelOrderNFTApi
 * @returns Promise<ResponseInterface<null>>
 * @param payload
 */
export const cancelOrderNFTApi = async (payload: {
  nftId: number | undefined;
}): Promise<ResponseInterface<null>> => {
  const { data } = await network.post<ResponseInterface<null>>(CANCEL_ORDER_NFT_URL, {
    ...payload,
  });
  return data;
};

/**
 * @description create order in NFT. Typically it locks this particular NFT for 15mins
 * for current user
 * @function createOrderNFTApi
 * @returns Promise<ResponseInterface<null>>
 * @param payload
 */
export const createOrderNFTApi = async (payload: {
  nftId: number | undefined;
}): Promise<ResponseInterface<null>> => {
  const { data } = await network.post<ResponseInterface<null>>(CREATE_ORDER_NFT_URL, {
    ...payload,
  });
  return data;
};

/**
 * @description create a payment intent for a user
 * @function createPaymentIntent
 * @returns Promise<ResponseInterface<any>>
 * @param payload
 */
export const createPaymentIntentApi = async (
  payload: ICreatePaymentIntentMetadataType
): Promise<ResponseInterface<Record<string, any>>> => {
  const { data } = await network.post<ResponseInterface<Record<string, any>>>(
    CREATE_PAYMENT_INTENT_URL,
    { metadata: { ...payload } }
  );
  return data;
};
