import { Dispatch } from "redux";

import {
  SET_COLOR_MODE,
  SET_LOCALE,
  SET_IS_NFT_DETAIL_IMAGE_OVERVIEW_INSTRUCTION_DONE,
} from "../types";
import {
  ISetLocale,
  Locale,
  ISetColorMode,
  IColorMode,
  ISetIsNftDetailImageOverviewInstructionDone,
} from "../models";

export const setColorMode = (colorMode: IColorMode): ISetColorMode => ({
  type: SET_COLOR_MODE,
  payload: colorMode,
});

export const setLocale = (locale: string): ISetLocale => ({
  type: SET_LOCALE,
  payload: locale as Locale,
});

export const setNftDetailImageOverviewInstructionDone = (): ISetIsNftDetailImageOverviewInstructionDone => ({
  type: SET_IS_NFT_DETAIL_IMAGE_OVERVIEW_INSTRUCTION_DONE,
});

export const updateUserLanguageAction = (payload: Locale, onSuccess?: VoidFunction) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLocale(payload));
    // MAKE NETWORK CALL HERE TO UPDATE SERVER
    onSuccess?.();
  };
};
