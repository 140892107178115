/**
 * Constant that denotes the url for getting user redeemed nft
 */
export const GET_USER_NFTS_URL = "/vn/nft/getNfts";

/**
 * Constant that denotes the url for transfering a user nft
 */
export const TRANSFER_USER_NFTS_URL = "vn/nft/transferNFT";

/**
 * Constant that denotes the url for getting the details of a user selected nft
 */
export const GET_USER_SELECTED_USER_NFT_DETAIL_URL = "/vn/nft/getSelectedNft";
