import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";
import messages from "./messages";

import {
  Link,
  Contents,
  LogoIcon,
  LogoTitle,
  Container,
  IconWrapper,
  LogoWrapper,
  LinksWrapper,
} from "./Header.styles";

export const Header: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation() as CTLocation;

  return (
    <Container>
      <Contents>
        <LogoWrapper onClick={() => navigate(APP_URL_LIST.REDEEM_URL, { replace: true })}>
          <LogoIcon />
          <LogoTitle>
            <FormattedMessage {...messages.title} />
          </LogoTitle>
        </LogoWrapper>
        <LinksWrapper>
          <IconWrapper
            path={pathname}
            isRedeem={state?.isRedeem}
            isActive={Boolean(state?.isRedeem)}
            disabled={Boolean(state?.isRedeem)}
            onClick={() => navigate(APP_URL_LIST.REDEEM_URL, { replace: true })}
          >
            <Icon name="redeem" />
            <Link className="nav-link">
              <FormattedMessage {...messages.redeem} />
            </Link>
          </IconWrapper>

          <IconWrapper
            path={pathname}
            isCollection={state?.isCollection}
            isActive={Boolean(state?.isCollection)}
            disabled={Boolean(state?.isCollection)}
            onClick={() => navigate(APP_URL_LIST.COLLECTION_URL, { replace: true })}
          >
            <Icon name="wallet" color="SPACE_DARK_COLOR" />
            <Link className="nav-link">
              <FormattedMessage {...messages.collection} />
            </Link>
          </IconWrapper>

          <IconWrapper
            path={pathname}
            disabled={pathname === APP_URL_LIST.PROFILE_URL}
            isActive={pathname === APP_URL_LIST.PROFILE_URL}
            onClick={() => navigate(APP_URL_LIST.PROFILE_URL, { replace: true })}
          >
            <Icon name="profile" />
            <Link className="nav-link">
              <FormattedMessage {...messages.profile} />
            </Link>
          </IconWrapper>
        </LinksWrapper>
      </Contents>
    </Container>
  );
};
