import styled, { css } from "styled-components";
import { ButtonProps } from "./Button.interface";

export const PrimaryButton = styled.button<ButtonProps>`
  width: 100%;
  height: 5.2rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 2.1rem;
  padding: 0.625rem 2rem;
  border: none !important;
  color: ${({ theme, color }) => theme.colors[color || "WHITE_COLOR"]};
  background: linear-gradient(
    91.85deg,
    ${({ theme }) => theme.colors.CARDINAL_TEAL_COLOR} -5.72%,
    ${({ theme }) => theme.colors.CARDINAL_PURPLE_COLOR} 106.82%
  );
  box-shadow: 0px -2px 9px rgba(122, 207, 255, 0.47),
    inset 0px 0px 8px 1px rgba(107, 255, 237, 0.51);

  .button__icon {
    margin-right: 0.83rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 1.6rem;
  }

  &:focus,
  &:active {
    border: none !important;
    color: ${({ theme, color }) => theme.colors[color || "WHITE_COLOR"]} !important;
  }

  ${({ theme, isLoading }) =>
    !isLoading &&
    css`
      &:hover {
        background: linear-gradient(
          94.84deg,
          ${theme.colors.IRIS_DARK_COLOR} 1.17%,
          ${theme.colors.SPACE_DEEP_COLOR} 122.85%
        );
      }
    `}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      background: linear-gradient(
        94.84deg,
        ${theme.colors.IRIS_DARK_COLOR} 1.17%,
        ${theme.colors.SPACE_DEEP_COLOR} 122.85%
      );
    `}
`;

export const SecondaryButton = styled(PrimaryButton)`
  background: linear-gradient(
    91.85deg,
    ${({ theme }) => theme.colors.BUTTON_DELETE_COLOR} -5.72%,
    ${({ theme }) => theme.colors.LINEAR_PINK_COLOR} 106.82%
  );
  box-shadow: 0px -2px 9px rgba(255, 122, 186, 0.47),
    inset 0px 0px 8px 1px rgba(255, 107, 178, 0.54);

  ${({ theme, isLoading }) => {
    if (!isLoading) {
      return `
        &:hover {
          background: linear-gradient(91.85deg, ${theme.colors.BUTTON_NOTICE_COLOR} -5.72%,  ${theme.colors.LINEAR_GOOGLE_RED_COLOR} 106.82%);
          box-shadow: 0px -2px 9px rgba(133, 122, 255, 0.47), inset 0px 0px 8px 1px rgba(206, 156, 255, 0.6);
        }
      `;
    }
  }}
`;

export const TertiaryButton = styled(PrimaryButton)`
  box-shadow: none;
  color: ${({ theme }) => theme.colors.SPACE_LIGHT_COLOR};
  background: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  border: 0.4rem solid ${({ theme }) => theme.colors.SPACE_DEEP_COLOR} !important;

  &:focus,
  &:active {
    border: 0.4rem solid ${({ theme }) => theme.colors.SPACE_DEEP_COLOR} !important;
  }

  ${({ theme, isLoading }) => {
    if (!isLoading) {
      return `
        &:hover {
          box-shadow: none;
          background: none;
          color: ${theme.colors.WHITE_COLOR};
          border-color: ${theme.colors.SPACE_LIGHT_COLOR} !important;
        }
      `;
    }
  }}
`;

export const Content = styled.div<Pick<ButtonProps, "iconDirection">>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ iconDirection }) => iconDirection};
  font-size: ${({ iconDirection }) => (iconDirection ? "1rem" : "inherit")};

  & > svg {
    ${({ iconDirection }) =>
      iconDirection === "row" ? `margin-right: 0.625rem` : "margin-bottom: 0.28rem"}
  }
`;
