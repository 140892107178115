import { defineMessages } from "react-intl";

const messages = defineMessages({
  token_id: {
    id: "nft_details.bottom_details.title",
    defaultMessage: "Token ID",
  },
  event: {
    id: "nft_details.bottom_details.event",
    defaultMessage: "Project",
  },
  copy: {
    id: "nft_details.bottom_details.copy",
    defaultMessage: "Copy",
  },
  token_id_copied: {
    id: "nft_details.bottom_details.token_id_copied",
    defaultMessage: "🦄 Token ID Copied Successfully",
  },
  date_received: {
    id: "nft_details.bottom_details.date_received",
    defaultMessage: "Date Received",
  },
  organizer: {
    id: "nft_details.bottom_details.organizer",
    defaultMessage: "Project creator",
  },
  creator: {
    id: "nft_details.bottom_details.creator",
    defaultMessage: "NFT Creator",
  },
  preOwner: {
    id: "nft_details.bottom_details.preOwner",
    defaultMessage: "Owner",
  },
  pinch_zoom_in_out: {
    id: "nft_details.bottom_details.pinch_zoom_in_out",
    defaultMessage: "Pinch to Zoom In & Out",
  },
});

export default messages;
