import styled from "styled-components";

import { Button } from "@app/components/atoms";

export const FacebookButton = styled(Button).attrs({ icon: "facebook" })`
  background: ${({ theme }) => theme.colors.FACEBOOK_BLUE_COLOR};
  box-shadow: 0px -2px 9px rgba(133, 122, 255, 0.47), inset 0px 0px 8px 1px rgba(206, 156, 255, 0.6);

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.FACEBOOK_BLUE_COLOR};
      }
    }
  }

  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.FACEBOOK_BLUE_COLOR} !important;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.WHITE_COLOR};
      }
    }
  }
`;
