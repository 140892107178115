import { defineMessages } from "react-intl";

const messages = defineMessages({
  nfts: {
    id: "nft_collection.nfts",
    defaultMessage: "{numberOfNfts, plural, =0 {# NFT} one {# NFT} other {# NFTs}}",
  },
  see_collection: {
    id: "nft_collection.see_collection",
    defaultMessage: "See Entire Collection",
  },
});

export default messages;
