import styled from "styled-components";
import { Card, Icon, Button } from "@app/components/atoms";

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.ONIX_DEEP_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    height: 85vh;
  }
`;

export const CardContainer = styled(Card)`
  display: flex;
  margin: 10rem 0;
  cursor: default;
  overflow: hidden;
  max-width: 50rem;
  padding-top: 4rem;
  padding: 5rem 1rem;
  position: relative;
  border-radius: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const LogoIcon = styled(Icon).attrs({ name: "icon" })`
  width: 10rem;
  height: 10rem;
  margin-bottom: 2rem;
`;

export const LogoIconCurve = styled(LogoIcon)`
  top: -20rem;
  width: 40rem;
  right: -25rem;
  height: 30rem;
  position: absolute;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3rem;
  text-align: center;
  margin-bottom: 1rem;
`;

export const SubTitle = styled.p`
  opacity: 0.8;
  max-width: 25rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 35rem;
  margin-top: 5rem;
  align-items: center;
  justify-content: space-evenly;
`;

export const ButtonsMargin = styled.div`
  width: 2rem;
`;

export const StoreButton = styled(Button)`
  border-radius: 0.5rem;

  &:hover {
    border: none;
    box-shadow: none;
    background: inherit;
  }

  &:focus,
  &:active {
    background: linear-gradient(
      91.85deg,
      ${({ theme }) => theme.colors.CARDINAL_TEAL_COLOR} -5.72%,
      ${({ theme }) => theme.colors.CARDINAL_PURPLE_COLOR} 106.82%
    ) !important;
    box-shadow: 0px -2px 9px rgba(122, 207, 255, 0.47),
      inset 0px 0px 8px 1px rgba(107, 255, 237, 0.51) !important;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.colors.WHITE_COLOR};
    }
  }
`;
