import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const RightArrow = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      {...props}
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.30551 1.27762C0.596437 1.97354 0.591098 3.1142 1.29362 3.81673L8.43858 10.9617L1.29125 18.109C0.58693 18.8133 0.583695 19.9543 1.28401 20.6626C1.99598 21.3827 3.16009 21.379 3.86744 20.6543L13.3291 10.9617L3.83172 1.30027C3.14021 0.596816 2.00952 0.586682 1.30551 1.27762Z"
        fill={colors[color || "METALLIC_PEARL_WHITE_COLOR"]}
      />
    </svg>
  );
};

export default RightArrow;
