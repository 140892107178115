import { Dispatch } from "redux";
import jwt_decode from "jwt-decode";

import {
  loginOuteApi,
  loginWithEmailApi,
  loginWithAppleApi,
  loginWithFacebookApi,
  verifyLoginWithEmailApi,
  loginWithGoogleRedirectApi,
} from "@app/network/user";
import { ThunkDispatchType } from "typings/store";

import {
  ILogout,
  ISession,
  ISetSession,
  ISeasonUser,
  ISessionFailure,
  ISessionStarted,
  ISessionLoginWithAppleParams,
  ISessionLoginWithGoogleParams,
  ISessionLoginWithFacebookParams,
  ISessionLoginWithEmailActionParams,
  ISessionVerifyLoginWithEmailActionParams,
} from "../models";
import { persistor } from "../../store";
import { SESSION_SUCCESS, SESSION_REQUEST, SESSION_FAILURE, LOGOUT } from "../types";

export const sessionRequest = (): ISessionStarted => ({ type: SESSION_REQUEST });

export const sessionSuccess = (session: ISession): ISetSession => ({
  type: SESSION_SUCCESS,
  payload: session,
});

export const sessionFailure = (error: Error | null): ISessionFailure => ({
  type: SESSION_FAILURE,
  payload: error,
});

export const logoutUserAction = (): ILogout => {
  // <- logout user from backend also
  loginOuteApi();

  // <- rest navigator and clear persisted store
  persistor.purge();
  persistor.flush();
  return { type: LOGOUT };
};

/**
 * Thunk Dispatch action type that calls the {@see loginWithFacebookApi}
 * to sign in the user with facebook
 * @param payload type {@see ISessionLoginWithFacebookParams}
 */
export const loginWithFacebookAction = (
  payload: ISessionLoginWithFacebookParams,
  onSuccess?: VoidFunction
) => {
  return async (dispatch: ThunkDispatchType) => {
    try {
      const { token: jwtToken } = await loginWithFacebookApi(payload);
      const user: ISeasonUser = jwt_decode(jwtToken);
      dispatch(sessionSuccess({ jwtToken, user }));
      onSuccess?.();
    } catch (error) {
      dispatch(sessionFailure(error as Error));
    }
  };
};

/**
 * Thunk Dispatch action type that calls the {@see loginWithGoogleRedirectApi}
 * to sign in the user with google redirect
 * @param payload type {@see ISessionLoginWithGoogleParams}
 */
export const loginWithGoogleAction = (
  payload: ISessionLoginWithGoogleParams,
  onSuccess?: VoidFunction
) => {
  return async (dispatch: ThunkDispatchType) => {
    try {
      const { token: jwtToken } = await loginWithGoogleRedirectApi(payload);
      const user: ISeasonUser = jwt_decode(jwtToken);
      dispatch(sessionSuccess({ jwtToken, user }));
      onSuccess?.();
    } catch (error) {
      dispatch(sessionFailure(error as Error));
    }
  };
};

/**
 * Action that calls the login with email endpoint {@see loginWithEmailApi}
 * to send in a uniqtogether user a templated email with a deeplink to login via email
 * @param payload {@see ISessionLoginWithEmailActionParams}
 * @param onSuccess {@see EmailLogin.tsx}
 */
export const loginWithEmailAction = (
  payload: ISessionLoginWithEmailActionParams,
  onSuccess?: VoidFunction
) => {
  return async (dispatch: Dispatch) => {
    dispatch(sessionRequest());
    try {
      await loginWithEmailApi(payload);
      dispatch(sessionRequest());
      onSuccess?.();
    } catch (error) {
      dispatch(sessionFailure(error as Error));
    }
  };
};

/**
 * Action that is triggered when the user redirects from the deeplink
 * to the signup page to log in via email. It calls the verify login with email
 * endpoint to assign to user a jwtToken in login via email process
 * @param payload {@see ISessionVerifyLoginWithEmailActionParams}
 */
export const verifyLoginWithEmailAction = (
  payload: ISessionVerifyLoginWithEmailActionParams,
  onSuccess?: VoidFunction
) => {
  return async (dispatch: Dispatch) => {
    dispatch(sessionRequest());
    try {
      const { jwtToken } = await verifyLoginWithEmailApi(payload);
      const user: ISeasonUser = jwt_decode(jwtToken);
      dispatch(sessionSuccess({ jwtToken, user }));
      onSuccess?.();
    } catch (error) {
      dispatch(sessionFailure(error as Error));
    }
  };
};

/**
 * Action that calls the login with apple endpoint {@see loginWithAppleApi}
 * to signin in a uniqtogether user with apple
 * @param payload {@see ISessionLoginWithAppleParams}
 */
export const loginWithAppleAction = (payload: ISessionLoginWithAppleParams) => {
  return async (dispatch: ThunkDispatchType) => {
    try {
      const { token: jwtToken } = await loginWithAppleApi(payload);
      const user: ISeasonUser = jwt_decode(jwtToken);
      dispatch(sessionSuccess({ jwtToken, user }));
    } catch (error) {
      dispatch(sessionFailure(error as Error));
    }
  };
};
