import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  email_required: {
    id: "form.validator.email_required",
    defaultMessage: `Please provide your email address.`,
  },

  email_pattern: {
    id: "form.validator.email_pattern",
    defaultMessage: "Please enter a valid email address",
  },

  password_required: {
    id: "form.validator.password_required",
    defaultMessage: `Password is required.`,
  },

  password_valid: {
    id: "form.validator.password_valid",
    defaultMessage: `Password does not match.`,
  },
});

export const useFormValidation = () => {
  const intl = useIntl();

  const emailValidation = {
    required: {
      value: true,
      message: intl.formatMessage(messages.email_required),
    },

    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
      message: intl.formatMessage(messages.email_pattern),
    },
  };

  const passwordValidation = {
    required: {
      value: true,
      message: intl.formatMessage(messages.password_required),
    },

    pattern: {
      value: /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,15}/,
      message: intl.formatMessage(messages.password_valid),
    },
  };

  return {
    emailValidation,
    passwordValidation,
  };
};
