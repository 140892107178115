import { Modal } from "react-bootstrap";
import styled, { createGlobalStyle, css, DefaultTheme } from "styled-components";

import { Button } from "@app/components/atoms";
import { GenericModalStatuses } from "@app/providers/store/genericModal/models";
import { Footer } from "../Footer";

export const GlobalStyle = createGlobalStyle<{ status: GenericModalStatuses }>`
  body {
    .modal-backdrop.show,
    .modal-backdrop.fade {
      opacity: unset;
    }

    .modal-backdrop {
      backdrop-filter: blur(79px);
      filter: drop-shadow(5px 3px 8px rgba(28, 30, 45, 0.44));

      ${({ status }) => {
        switch (status) {
          case "error":
            return css`
              background: linear-gradient(
                130.5deg,
                rgba(137, 120, 241, 0.1792) 11.25%,
                rgba(139, 52, 170, 0.4256) 83.82%
              );
            `;

          case "warning":
            return css`
              background: linear-gradient(
                91.85deg,
                rgba(250, 115, 115, 0.1) -5.72%,
                rgba(113, 66, 125, 0.1) 106.82%
              );
            `;

          case "success":
            return css`
              background: linear-gradient(320.54deg, #424a7d -5.89%, rgba(66, 74, 125, 0) 106.78%);
            `;

          default:
            break;
        }
      }}
    }
  }
`;

export const Container = styled(Modal)<{ iscookies: string; istransparent: string }>`
  .title {
    flex: 1;
    font-weight: bold;
    text-align: center;
    line-height: 2.3rem;
    margin-bottom: 2rem;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};
    font-size: ${({ size }) => (size !== "sm" ? 2.4 : 1.8)}rem;
  }

  .icon {
    font-size: 3.4rem;
    line-height: 3.8rem;
    margin-bottom: 2rem;
  }

  .modal-body,
  .modal-header,
  .modal-content,
  .modal-footer {
    width: 97%;
    border: none;
    background-color: ${({ theme, istransparent }) =>
      theme.colors[istransparent === "true" ? "TRANSPARENT_COLOR" : "OBSIDIAN_SHADE_COLOR"]};
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    border: none;
    border-radius: 3rem;
    box-shadow: 0px -15px 50px ${({ theme, istransparent }) => theme.colors[istransparent === "true" ? "TRANSPARENT_COLOR" : "OBSIDIAN_COLOR"]};
  }

  .modal-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal-content {
    padding: 3.4rem;
    min-height: 25.3rem;
    border-radius: 1rem;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      min-width: 45rem;
    }
  }

  ${({ theme, iscookies }) => {
    if (iscookies === "true") {
      return `
        .title {
          font-size: 3.2rem;
          line-height: unset;
        }

        .icon {
          font-size: 6rem;
          line-height: unset;
        }

        h4 {
          max-width: 40rem;
          font-size: 1.6rem;
          line-height: 2.3rem;
          color: ${theme.hexToRGB(theme.colors.SPACE_PURE_COLOR, 0.7)};
        }


        @media (max-width: ${theme.breakpoints.MOBILE}) {
          .title {
            font-size: 2.5rem;
          }

          .icon {
            font-size: 6rem;
            line-height: unset;
          }

          h4 {
            font-size: 1.2rem;
            line-height: 1.6rem;
          }
        }

        button {
          background: linear-gradient(
            91.85deg,
            #f5b75d -5.72%,
            #7a4b2c 106.82%
          );
          box-shadow: 0px -2px 9px ${theme.hexToRGB(theme.colors.CANDY_ORANGE_COLOR, 0.47)},
            inset 0px 0px 8px 1px ${theme.hexToRGB(theme.colors.BUTTON_NOTICE_COLOR, 0.51)};

          :hover{
            box-shadow: none;
            background: #7a4b2c;
          }
        }
      `;
    }
  }}
`;

export const CloseButton = styled.div`
  right: 0;
  top: -60px;
  opacity: 0.7;
  cursor: pointer;
  padding: 0.5rem;
  position: absolute;
  border-radius: 100%;
  background-color: ${({ theme, color }) => color || theme.colors.LIGHT_GRAY};

  &:hover {
    background-color: ${({ theme }) =>
      theme.isDarkMode ? theme.colors.ORANGE_COLOR : theme.colors.ORANGE_COLOR};
    opacity: 1;
  }
`;

export const Description = styled.h4<{ color: keyof DefaultTheme["colors"] }>`
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
  min-width: 20.5rem;
  line-height: 1.9rem;
  margin-bottom: 4rem;
  letter-spacing: -0.02em;
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const ActionButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    justify-content: space-between;

    button {
      max-width: 20rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    align-items: center;
    flex-direction: column;
    justify-content: center;

    button {
      max-width: unset;

      :first-child {
        margin-bottom: 2rem;
      }
    }
  }
`;

export const InfoPrimaryButton = styled(Button)`
  max-width: 40rem;
  color: ${({ theme }) => theme.colors.METALLIC_PEARL_WHITE_COLOR};
`;

export const ActionPrimaryButton = styled(InfoPrimaryButton)`
  max-width: 32rem;
`;

export const ActionSecondaryButton = styled(ActionPrimaryButton)``;

export const DescriptionWrapper = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 4rem;
  padding-right: 6.5rem;
  color: ${({ theme }) => theme.colors.DISABLED_GRAY};
`;

export const BottomMessageLink = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;
  margin-top: 2.5rem;
  align-items: center;
  justify-content: center;

  h4 {
    margin: 0rem;
    min-width: unset;
    font-weight: 600;
    margin-left: 1rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-transform: capitalize;
  }

  :hover {
    h4 {
      text-decoration: underline;
    }
  }
`;

export const ModalFooter = styled(Footer)`
  left: 50%;
  bottom: 0;
  display: none;
  position: fixed;
  transform: translateX(-50%);

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: flex;
  }
`;
