import styled, { css, DefaultTheme } from "styled-components";

import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";

const setActiveRouteIconColor = (color: keyof DefaultTheme["colors"]) => css`
  svg {
    filter: drop-shadow(0px -2px 9px ${({ theme }) => theme.colors[color]});
    path {
      fill: ${({ theme }) => theme.hexToRGB(theme.colors[color], 0.8)};
    }
  }
`;

export const Container = styled.header`
  top: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1.7rem);
  background-color: ${({ theme }) => theme.colors.ONIX_DARK_COLOR};
`;

export const Contents = styled.div`
  width: 90%;
  display: flex;
  min-height: 5rem;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    min-height: 7rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_DESKTOP_VIEW}) {
    width: 80%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.DESKTOP_FULL_HD_VIEW}) {
    width: 70%;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
  align-items: center;
`;

export const Link = styled.h3`
  display: none;
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: -0.02em;
  font-family: Signika Negative;
  color: ${({ theme }) => theme.colors.SPACE_DARK_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: flex;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    font-size: 1.8rem;
  }

  :hover {
    color: ${({ theme }) => theme.colors.SPACE_DARK_COLOR};
  }
`;

export const LogoTitle = styled.h3`
  display: none;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-left: 1.6rem;
  line-height: 2.4rem;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.WHITE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    display: block;
    font-size: 2rem;
  }
`;

export const LinksWrapper = styled(LogoWrapper)`
  cursor: default;
  justify-content: flex-end;
`;

export const LogoIcon = styled(Icon).attrs({ name: "icon" })`
  @media (max-width: ${({ theme }: { theme: DefaultTheme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    width: 4rem;
    height: 4rem;
  }
`;

export const IconWrapper = styled.button<{
  isActive: boolean;
  path: APP_URL_LIST;
  isRedeem?: boolean;
  isCollection?: boolean;
}>`
  height: 100%;
  border: none;
  display: flex;
  cursor: pointer;
  background: none;
  margin-left: 4rem;
  position: relative;
  align-items: center;

  padding: 0;
  border: none;
  background: none;

  ${({ isActive, path, isRedeem, isCollection, theme }) => {
    if (isActive) {
      return css`
        .nav-link {
          color: ${theme.colors.SPACE_PURE_COLOR};
        }

        ${isRedeem && setActiveRouteIconColor("BUTTON_ALTERED_COLOR")};
        ${isCollection && setActiveRouteIconColor("BUTTON_NOTICE_COLOR")};
        ${path === APP_URL_LIST.PROFILE_URL && setActiveRouteIconColor("LINEAR_GOOGLE_RED_COLOR")};

        ::after {
          content: "";
          bottom: 0px;
          width: 100%;
          height: 0.3rem;
          position: absolute;
          border-radius: 3.4rem;

          ${isRedeem &&
          css`
            background: linear-gradient(
              94.84deg,
              ${theme.colors.BUTTON_ALTERED_COLOR} 1.17%,
              ${theme.colors.LINEAR_PURPLE_COLOR} 122.85%
            );
            box-shadow: 0px -2px 9px rgba(133, 122, 255, 0.47),
              inset 0px 0px 8px 1px rgba(206, 156, 255, 0.6);
          `};

          ${isCollection &&
          css`
            background: linear-gradient(
              91.85deg,
              ${theme.colors.BUTTON_NOTICE_COLOR} -5.72%,
              ${theme.colors.LINEAR_GOOGLE_RED_COLOR} 106.82%
            );
            box-shadow: 0px -2px 9px rgba(255, 226, 122, 0.47),
              inset 0px 0px 8px 1px rgba(255, 240, 107, 0.54);
          `};

          ${path === APP_URL_LIST.PROFILE_URL &&
          css`
            background: linear-gradient(
              91.85deg,
              ${theme.colors.LINEAR_GOOGLE_RED_COLOR} -5.72%,
              ${theme.colors.LINEAR_PINK_COLOR} 106.82%
            );
            box-shadow: 0px -2px 9px rgba(255, 122, 186, 0.47),
              inset 0px 0px 8px 1px rgba(255, 107, 178, 0.54);
          `};
        }
      `;
    }
  }};
`;
