import { defineMessages } from "react-intl";

const messages = defineMessages({
  collections: {
    id: "nft_collection.collections",
    defaultMessage: `- {collectionsNumber, plural, =0 {# Collection} one {# Collection} other {# Collections}}`,
  },
  readMore: {
    id: "nft_collection.readMore",
    defaultMessage: "Read More",
  },
  readLess: {
    id: "nft_collection.readLess",
    defaultMessage: "Read Less",
  },
});

export default messages;
