import styled, { css, DefaultTheme } from "styled-components";

import { Button, Image } from "@app/components/atoms";

export const Container = styled.div<{ showOnDesktop?: boolean }>`
  margin-top: 3.65rem;
  padding: 0rem 3.2rem 15.9rem 3.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    display: none;

    ${({ showOnDesktop }) =>
      showOnDesktop &&
      css`
        padding: 0;
        display: block;
      `}
  }
`;

export const Description = styled.h4`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 4.3rem;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};
`;

export const DetailRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RowContainer = styled(DetailRowContainer)`
  justify-content: flex-end;
`;

export const DetailRowLabel = styled(Description)`
  margin: 0rem;
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.02px;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};
`;

export const CopyEventContainer = styled(Button).attrs({ variant: "tertiary", icon: "copy" })`
  width: auto;
  height: auto;
  padding: 0.5rem 1.5rem;
  border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR} !important;

  :hover,
  :active,
  :focus {
    background: none;
    border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR} !important;
  }

  .button__icon {
    margin: 0;
  }
`;

export const DetailRowText = styled(DetailRowLabel)<{ color?: keyof DefaultTheme["colors"] }>`
  font-weight: 600;
  padding-left: 1rem;
  color: ${({ theme, color }) => theme.colors[color || "CARDINAL_TEAL_COLOR"]};
`;

export const HRLine = styled.div`
  width: auto;
  margin: 1.6rem 0rem;
  border: 1px solid ${({ theme }) => theme.colors.SPACE_DEEP_COLOR};
`;

export const NFTUserImage = styled(Image).attrs({
  wrapperProps: { style: { height: "100%" } },
})`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.WHITE_COLOR};
`;
