import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const GridTiles = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        y="1"
        rx="3"
        x="1.5"
        width="22"
        height="22"
        strokeWidth="2"
        stroke={colors[color || "SPACE_DEEP_COLOR"]}
      />
    </svg>
  );
};

export default GridTiles;
