import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { APP_URL_LIST } from "@app/routes/urls";
import { getUserToken } from "@app/providers/store/session";
import messages from "./messages";

import { Title, SubTitle, Container, GoBackButton, ContentWrapper } from "./NotFound.styles";
import ReactGA from "react-ga";

const NotFound: React.FC<{}> = () => {
  const navigate = useNavigate();
  const userToken = useSelector(getUserToken);

  const onBackClicked = () => {
    navigate(userToken ? APP_URL_LIST.REDEEM_URL : APP_URL_LIST.SIGNUP_URL, { replace: true });
  };

  React.useEffect(() => {
    ReactGA.pageview(`${APP_URL_LIST.NOT_FOUND_URL}`);
  }, []);

  return (
    <Container>
      <ContentWrapper>
        <Title>
          <FormattedMessage {...messages.title} />
        </Title>

        <SubTitle>
          <FormattedMessage {...messages.subtitle} values={{ br: <br /> }} />
        </SubTitle>

        <GoBackButton onClick={onBackClicked}>
          <FormattedMessage {...messages.go_back} />
        </GoBackButton>
      </ContentWrapper>
    </Container>
  );
};

export default NotFound;
