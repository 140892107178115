import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";

import { Config } from "@app/configs";
import { reportError } from "@app/helpers";
import { store } from "@app/providers/store";
import { logoutUserAction } from "@app/providers/store/session";

// Set config defaults when creating the instance
export const network = axios.create({ baseURL: Config.BACKEND_URL + "/api" });

axiosRetry(network, { retries: 3 });

// Add a request interceptor
network.interceptors.request.use(
  (config) => {
    // grab current state
    const session = store.getState()?.session?.data;

    // get the locale through settings redux state slice
    const locale = store.getState()?.settings?.locale;

    // get the JWT token out of it
    const access_token = session?.jwtToken;

    return {
      ...config,
      headers: {
        ...config.headers,
        "Accept-Language": locale,
        authorization: access_token,
      },
    };
  },
  (error: AxiosError) => {
    reportError(error?.response?.data as Error);
    return Promise.reject(error?.response?.data as Error);
  }
);

// Add a response interceptor
network.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config;

    if (
      error?.response?.status === 403 ||
      (error?.response?.data.statusCode === 403 && !originalRequest._retry)
    ) {
      // const state = store.getState();
      // const refresh_token = state?.session?.data?.refreshToken;

      try {
        // retry the failed request
        // originalRequest._retry = true;
        /**
         *
         * @description refresh a cometogether user access token
         * @returns Promise<ResponseInterface>
         */
        // const { data } = await network.post<ResponseInterface<ISession>>("/auth/refresh-token", {
        //   refresh_token,
        // });
        // axios.defaults.headers.common["Authorization"] = data.payload.access_token;
        // return network(originalRequest);
      } catch (error) {
        /**
         * Logout user when refreshing access token failed
         * This could mean the refresh token also has expired
         */
        store.dispatch(logoutUserAction());
      }
    }

    reportError(error?.response?.data as Error);
    return Promise.reject(error?.response?.data as Error);
  }
);
