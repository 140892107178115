import FileSaver from "file-saver";

/**
 *
 * @description imageDownload a method for downloading NFT images
 * @function imageDownload
 * @property source {string}
 * @returns void
 */

const imageDownload = (source: string, imageName: string) => {
  return FileSaver.saveAs(source, imageName);
};

export default imageDownload;
