import styled from "styled-components";

export {
  LogoIcon,
  LogoTitle,
  GlobalStyle,
  LogoWrapper,
} from "@app/pages/privacy-policy/PrivacyPolicy.styles";

import { Container as PrivacyPolicyContainer } from "@app/pages/privacy-policy/PrivacyPolicy.styles";

export const Container = styled(PrivacyPolicyContainer)`
  .terms-container {
    margin: 5rem 0;
    max-width: 90rem;
    font-size: 1.6rem;
    line-height: 1.875;
    padding: 0 2.5rem;
    font-weight: normal;

    h1 {
      font-size: 4.2rem;
      line-height: 4.8rem;
    }

    h4 {
      font-size: 2.4rem;
      line-height: 3.6rem;
      margin-bottom: 2rem;
    }

    h1,
    h4 {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};
    }

    p {
      margin: 0 0 2rem 0;
    }

    b {
      font-size: 1.5rem;
      font-weight: bold;
    }

    br {
      font-size: 1rem;
    }
  }
`;
