import React, { Fragment, useState } from "react";
import { useImage } from "react-image";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";
import { getUserToken } from "@app/providers/store/session";
import { imageDownload, priceResolver } from "@app/helpers";
import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import {
  getUserSelectedRedeemNftDetails,
  addRedeemToUserCollectionAction,
} from "@app/providers/store/redeem";
import {
  openGenericModalAction,
  closeGenericModalAction,
  openUnauthorizedModalAction,
  openGenericServerErrorModalAction,
  openNonFunctionalYetModalAction,
} from "@app/providers/store/genericModal";
import { useWebShare } from "@app/hooks";
import { INftTypes } from "@app/providers/store/nft/models";
import { GenericModalProperties } from "@app/providers/store/genericModal/models";
import messages from "./messages";

import {
  HRLine,
  NFTTitle,
  PriceTag,
  Dropdown,
  NFTButton,
  DetailText,
  DetailArrow,
  NFTUserName,
  NFTUserImage,
  RowContainer,
  NFTSellButton,
  NFTFreeButton,
  NFTPriceButton,
  HeaderBackTitle,
  BottomContainer,
  HeaderContainer,
  NFTButtonWrapper,
  HeaderBackButton,
  NFTTransferButton,
  NFTWithdrawButton,
  VewMoreDetailButton,
} from "./TopOverlay.style";

const TopOverlay: React.FC<{ showDetails: boolean; setShowDetails: VoidFunction }> = ({
  showDetails,
  setShowDetails,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { collectionId } = useParams();
  const userToken = useSelector(getUserToken);
  const [isLoading, setIsLoading] = useState(false);
  const { state, pathname } = useLocation() as CTLocation;
  const isShareAvailable = !!navigator.share && !!navigator.canShare;
  const redeem = useSelector(getUserSelectedRedeemNftDetails, shallowEqual);
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);

  const srcList = [
    String(userSelectedNftDetails?.nftAsset),
    String(userSelectedNftDetails?.nftThumbnail),
  ];
  const { src } = useImage({ srcList, useSuspense: false });

  const handleShareNFT = useWebShare({ nft: userSelectedNftDetails, src });

  const isFreeNFT = userSelectedNftDetails?.price === "0.01 COME";

  const collection = redeem?.collections?.find(
    (col) => Number(col.collectionId) === Number(collectionId)
  );

  const addToCollection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (!userToken) {
      return dispatch(
        openUnauthorizedModalAction({
          primaryOnPress: () => navigate(APP_URL_LIST.SIGNUP_URL, { state: { path: pathname } }),
        })
      );
    }

    if (isFreeNFT) {
      setIsLoading(true);
      const modalParams: GenericModalProperties = {
        icon: "🛍",
        status: "success",
        title: formatMessage(messages.modal_title),
        primaryLabel: formatMessage(messages.modal_primary_label),
        primaryOnPress: () => dispatch(closeGenericModalAction()),
        bottomLinkLabel: formatMessage(messages.modal_bottomLink_label),
        bottomLinkProps: {
          icon: "wallet",
          onClick: () => {
            navigate(APP_URL_LIST.COLLECTION_URL);
            dispatch(closeGenericModalAction());
          },
        },
      };

      const onCallback = (error?: any) => {
        setIsLoading(false);
        if (error) {
          const payloadError =
            error?.extraData?.type === "whiteListError"
              ? {
                  description: error?.message,
                  title: formatMessage(messages.not_white_listed),
                }
              : { description: error?.message };
          dispatch(openGenericServerErrorModalAction({ ...payloadError }));
        } else {
          dispatch(openGenericModalAction(modalParams));
        }
      };

      return dispatch(
        addRedeemToUserCollectionAction(
          {
            eventId: Number(redeem?.eventId),
            nftId: Number(userSelectedNftDetails?.nftId),
            collectionName: `${collection?.collectionName}`,
          },
          onCallback
        )
      );
    } else {
      navigate(
        `${APP_URL_LIST.BUY_NFTS}/${Number(redeem?.eventId)}/${Number(
          collection?.collectionId
        )}/${Number(userSelectedNftDetails?.nftId)}`
      );
    }
  };

  const handleActions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(openNonFunctionalYetModalAction());
  };

  const handleImageDownload = () => {
    const fileName = `${userSelectedNftDetails?.nftName}.${
      userSelectedNftDetails?.nftType === INftTypes.GIF ? "gif" : "png"
    }`;

    imageDownload(String(src), fileName);
  };

  return (
    <Fragment>
      <HeaderContainer>
        <HeaderBackButton
          to={APP_URL_LIST.REDEEM_URL}
          onClick={(event) => {
            event.stopPropagation();
            // stop the browser from changing the URL
            event.preventDefault();
            // navigate a step back to previous screen
            navigate(-1);
          }}
        >
          <Icon name="go-back" />
          <HeaderBackTitle>
            <FormattedMessage {...messages.go_back_to_list} />
          </HeaderBackTitle>
        </HeaderBackButton>

        {state?.isCollection ? (
          <Dropdown
            onClick={(event: any) => {
              event.stopPropagation();
              // stop the browser from changing the URL
              event.preventDefault();
            }}
          >
            <Dropdown.Toggle id="dropdown-button">
              {[...Array(3)].map(() => (
                <div className="dropdown-more-icon" />
              ))}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {isShareAvailable && (
                <Dropdown.Item onClick={handleShareNFT}>
                  <Icon name="share" color="LINEAR_EXTRA_BLACK_COLOR" width={24} />
                  Share NFT image
                </Dropdown.Item>
              )}

              <Dropdown.Item onClick={handleImageDownload}>
                <Icon name="download" color="LINEAR_EXTRA_BLACK_COLOR" />
                Download NFT image
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
      </HeaderContainer>

      <NFTTitle>{userSelectedNftDetails?.nftName}</NFTTitle>

      <RowContainer>
        <NFTUserImage
          srcList={[
            String(userSelectedNftDetails?.nftCreatorImage),
            "/assets/images/default-user-pic.png",
          ]}
        />
        <NFTUserName>{userSelectedNftDetails?.nftCreator}</NFTUserName>
      </RowContainer>

      <BottomContainer>
        <NFTButtonWrapper>
          {state?.isCollection ? (
            <Fragment>
              {userSelectedNftDetails?.isTransferable && (
                <NFTTransferButton
                  icon="share"
                  isLoading={isLoading}
                  iconDirection="column"
                  onClick={handleActions}
                >
                  <FormattedMessage {...messages.transfer} />
                </NFTTransferButton>
              )}

              {userSelectedNftDetails?.isWithdrawable && (
                <NFTWithdrawButton
                  icon="withdraw"
                  isLoading={isLoading}
                  iconDirection="column"
                  onClick={handleActions}
                >
                  <FormattedMessage {...messages.withdraw} />
                </NFTWithdrawButton>
              )}

              {userSelectedNftDetails?.isSellable && (
                <NFTSellButton
                  isLoading={isLoading}
                  iconDirection="column"
                  onClick={handleActions}
                  icon={
                    Number(userSelectedNftDetails?.resalePrice?.split(" ")[0])
                      ? "unSellNft"
                      : "sellNFT"
                  }
                >
                  <FormattedMessage {...messages.sell} />
                </NFTSellButton>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <NFTFreeButton disabled>
                <PriceTag>
                  {!isFreeNFT ? (
                    `€${priceResolver(userSelectedNftDetails?.price)}`
                  ) : (
                    <FormattedMessage {...messages.free} />
                  )}
                </PriceTag>
              </NFTFreeButton>
              {isFreeNFT ? (
                <NFTButton
                  icon="wallet"
                  isLoading={isLoading}
                  iconDirection="column"
                  onClick={addToCollection}
                >
                  <FormattedMessage {...messages.add_to_collection} />
                </NFTButton>
              ) : (
                <NFTPriceButton
                  icon="buy"
                  isLoading={isLoading}
                  iconDirection="column"
                  onClick={addToCollection}
                >
                  <FormattedMessage {...messages.buy_now} />
                </NFTPriceButton>
              )}
            </Fragment>
          )}
        </NFTButtonWrapper>

        <VewMoreDetailButton
          onClick={(e) => {
            e.stopPropagation();
            setShowDetails();
          }}
        >
          <Fragment>
            <DetailText>
              <FormattedMessage {...messages.details} />
            </DetailText>
            <HRLine />
            <DetailArrow showdetails={String(showDetails)} />
          </Fragment>
        </VewMoreDetailButton>
      </BottomContainer>
    </Fragment>
  );
};

export default TopOverlay;
