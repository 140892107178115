import styled from "styled-components";
import { Form } from "react-bootstrap";

import { Button, Input } from "@app/components/atoms";

export const EmailInput = styled(Input)`
  margin-top: 3.4rem;
  margin-bottom: 1.6rem;
`;

export const MagicLinkButton = styled(Button)`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: Compagnon;

  div {
    font-size: inherit;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .recaptcha_container {
    margin-top: 1rem;
  }
`;
