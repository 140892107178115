import AudioPlayer from "react-h5-audio-player";
import { useSelector, shallowEqual } from "react-redux";

import { Icon } from "@app/components/atoms";
import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import { AudioModalProps } from "./AudioModal.interface";

import {
  Container,
  TransformImage,
  ZoomOutContainer,
  ImageTransformComponentContainer,
} from "./AudioModal.style";

const AudioModal: React.FC<AudioModalProps> = ({ setShowAudioScreen }) => {
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);

  return (
    <Container>
      <TransformImage
        nftType={userSelectedNftDetails?.nftType}
        srcList={[String(userSelectedNftDetails?.nftThumbnail), "/assets/svg/audio-background.svg"]}
        container={(children) => (
          <ImageTransformComponentContainer>
            {children}

            <AudioPlayer
              autoPlay
              className="nft_audio_player"
              src={userSelectedNftDetails?.nftAsset}
            />
          </ImageTransformComponentContainer>
        )}
      />

      <ZoomOutContainer onClick={setShowAudioScreen}>
        <Icon name="close" />
      </ZoomOutContainer>
    </Container>
  );
};

export default AudioModal;
