export enum INftTypes {
  GIF = "gif",
  SOUND = "sound",
  VIDEO = "video",
  IMAGE = "image",
}

export interface IUserNftType {
  nftId: number;
  price: string;
  eventId: number;
  nftName: string;
  tokenId: string;
  nftAsset: string;
  eventName: string;
  dateReceived: Date;
  nftCreator: string;
  nftType: INftTypes;
  isSellable: boolean;
  resalePrice: string;
  sampleVideo: string;
  nftThumbnail: string;
  nftSmallThumbnail: string;
  nftCategoryId: number;
  eventOrganizer: string;
  nftDescription: string;
  nftCreatorImage: string;
  isTransferable: boolean;
  isWithdrawable: boolean;
  eventOrganizerImage?: string;
  serviceFee?: number;
  nftCategory?: string;
  preOwner?: string;
  preOwnerImage?: string;
  utility?: {
    eventId: number;
    ticketCategory: string;
  };
}

export interface INftState {
  isLoading: boolean;
  error: Error | null;
  userNfts: IUserNftType[];
  userSelectedNftDetails: IUserNftType | null;
}

export type IGetSelectedNftDetailsParamsType = Pick<IUserNftType, "nftId">;
