import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  getUserRedeemNfts,
  getRedeemCollectionNfts,
  resetRedeemCollectionAction,
  getRedeemCollectionNftsAction,
  getUserSelectedRedeemNftDetails,
  setUserSelectedRedeemNftDetailsAction,
} from "@app/providers/store/redeem";
import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";
import { DEFAULT_PAGINATION } from "@app/constants";
import { INFTRedeemType } from "@app/providers/store/redeem/models";
import {
  getUserSelectedNftDetails,
  setUserSelectedNftDetailsAction,
} from "@app/providers/store/nft";
import { getUserNfts, getUserNftsAction } from "@app/providers/store/nft";
import { NftRedeemCard, PresentationButtons } from "@app/components/molecules";
import { RedeemNFTTitle } from "./component";
import messages from "./messages";

import {
  Skeleton,
  Container,
  HeaderBackTitle,
  HeaderBackButton,
  EmptyListWrapper,
  CollectionWrapper,
  CollectionContent,
  SkeletonContainer,
  LoadingMoreWrapper,
  HeaderTitleWrapper,
  CollectionContentLoader,
} from "./RedeemOverview.styles";
import ReactGA from "react-ga";

const RedeemOverview: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const { state } = useLocation() as CTLocation;
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [gridPresentation, setGridPresentation] = useState(false);
  const redeem = useSelector(getUserSelectedRedeemNftDetails);
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails);
  const { isLoading: isUserNftsLoading, userNfts } = useSelector(getUserNfts, shallowEqual);
  const { redeemNFTs, isLoading: redeemNFTsLoading } = useSelector(getUserRedeemNfts, shallowEqual);
  const { isLoading: isRedeemCollectionLoading, collectionNFTs } = useSelector(
    getRedeemCollectionNfts,
    shallowEqual
  );

  const nftList = state?.isCollection ? userNfts : collectionNFTs;
  const isLoading = isRedeemCollectionLoading || isUserNftsLoading || redeemNFTsLoading;

  React.useEffect(() => {
    return state?.isRedeem
      ? ReactGA.pageview(`${APP_URL_LIST.REDEEM_URL}/${collectionId}`)
      : ReactGA.pageview(`${APP_URL_LIST.COLLECTION_URL}`);
  }, []);

  useEffect(() => {
    if (state?.isRedeem && collectionId) {
      dispatch(getRedeemCollectionNftsAction({ nftCategoryId: String(collectionId) }));
    }

    if (state?.isCollection && !collectionId) {
      dispatch(getUserNftsAction({}));

      if (userSelectedNftDetails) {
        dispatch(setUserSelectedNftDetailsAction(null));
      }
    }
  }, [collectionId, dispatch, state, userSelectedNftDetails]);

  useEffect(() => {
    dispatch(resetRedeemCollectionAction());
  }, [dispatch, collectionId]);

  const setGrid = () => setGridPresentation(!gridPresentation);

  const onSuccessCallback = (data: typeof nftList) => {
    if (!data?.length || data?.length < DEFAULT_PAGINATION.LIMIT) {
      setCanFetchMore(false);
    }
  };

  const onLoadMore = () => {
    if (state?.isRedeem && collectionId) {
      dispatch(
        getRedeemCollectionNftsAction(
          { offset: nftList?.length, nftCategoryId: String(collectionId) },
          onSuccessCallback
        )
      );
    }

    if (state?.isCollection && !collectionId) {
      dispatch(getUserNftsAction({ offset: nftList?.length }, onSuccessCallback));
    }
  };

  useEffect(() => {
    if (!isLoading && redeemNFTs && !redeem) {
      let selectedRedeem: INFTRedeemType | null = null;

      for (let index = 0; index < redeemNFTs.length; index++) {
        const redeem = redeemNFTs[index];

        for (let index = 0; index < redeem.collections.length; index++) {
          const collection = redeem.collections[index];

          if (Number(collectionId) === Number(collection?.collectionId)) {
            selectedRedeem = redeem;
            break;
          }
        }

        if (selectedRedeem) {
          dispatch(setUserSelectedRedeemNftDetailsAction({ ...selectedRedeem }));
          break;
        }
      }
    }
  }, [redeemNFTs, redeem, isLoading, dispatch, collectionId]);

  return (
    <Container>
      {state?.isRedeem && (
        <HeaderBackButton to={APP_URL_LIST.REDEEM_URL}>
          <Icon name="go-back" />
          <HeaderBackTitle>
            <FormattedMessage {...messages.go_back_to_redeem} />
          </HeaderBackTitle>
        </HeaderBackButton>
      )}

      <HeaderTitleWrapper>
        <RedeemNFTTitle />
        {redeem && nftList?.length ? (
          <PresentationButtons setGrid={setGrid} gridPresentation={gridPresentation} />
        ) : null}
      </HeaderTitleWrapper>

      <CollectionWrapper>
        {/* LOADING NFT COLLECTION STATE */}
        {!nftList?.length && isLoading ? (
          <CollectionContentLoader>
            <SkeletonContainer>
              <Skeleton count={2} containerClassName="skeleton__holder" />
              <Skeleton count={2} containerClassName="skeleton__holder" />
            </SkeletonContainer>
          </CollectionContentLoader>
        ) : null}

        {/* EMPTY NFT COLLECTION LABEL */}
        {!nftList?.length && !isLoading ? (
          <EmptyListWrapper>
            <p>
              <FormattedMessage {...messages.no_nft_collection} />
            </p>
          </EmptyListWrapper>
        ) : null}

        {/* NFT COLLECTION LIST */}
        {nftList?.length ? (
          <InfiniteScroll
            next={onLoadMore}
            hasMore={canFetchMore}
            scrollableTarget="scrollable_div"
            dataLength={nftList?.length}
            loader={
              <LoadingMoreWrapper>
                <Icon name="loading" color="SPACE_LIGHT_COLOR" />
                <p>
                  <FormattedMessage {...messages.fetching_more_redeems} />
                </p>
              </LoadingMoreWrapper>
            }
          >
            <CollectionContent isMinimized={gridPresentation}>
              {nftList?.map((nft) => (
                <NftRedeemCard key={nft?.nftId} {...nft} isMinimized={gridPresentation} />
              ))}
            </CollectionContent>
          </InfiniteScroll>
        ) : null}
      </CollectionWrapper>
    </Container>
  );
};

export default RedeemOverview;
