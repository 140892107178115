import styled from "styled-components";
import { InputGroup, FormControl } from "react-bootstrap";

export const Container = styled(InputGroup)<{
  error: string;
  isfocused: number;
  inputtype?: string;
}>`
  display: flex;
  height: 5.2rem;
  padding: 0rem 2rem;
  align-items: center;
  font-family: Raleway;
  border-radius: 2.1rem;
  border: 3px solid
    ${({ theme, isfocused }) =>
      isfocused ? theme.colors.IRIS_DARK_COLOR : theme.colors.SPACE_DEEP_COLOR};

  .right_input_icon {
    margin-right: 0.68rem;
  }

  .left_input_icon {
    margin-left: 0.68rem;
  }

  svg {
    path {
      fill: ${({ theme }) =>
        theme.isDarkMode ? theme.colors.BLACK_COLOR : theme.colors.GRAY_SCALE_COLOR};
    }
  }

  ${({ error, theme }) => {
    if (error) {
      return `
        border-color: ${theme.colors.BUTTON_DELETE_COLOR};
      `;
    }
  }}
`;

export const InputControl = styled(FormControl)`
  flex: 1;
  padding: 0px;
  height: 100%;
  border: none;
  resize: none;
  outline: none;
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: -0.02em;
  padding-top: ${({ as }) => (as ? 1.8 : 0)}rem;
  padding-bottom: ${({ as }) => (as ? 1.8 : 0)}rem;
  caret-color: ${({ theme }) => theme.colors.GRAY_SCALE_COLOR};
  background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  color: ${({ theme }) => theme.colors.SPACE_LIGHT_COLOR} !important;

  ::placeholder {
    opacity: 0.4;
    color: ${({ theme }) => theme.colors.SPACE_LIGHT_COLOR} !important;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
    background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 1.8rem;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.9rem;
  justify-content: center;

  .error_icon {
    margin-right: 0.68rem;
  }
`;

export const Error = styled.p`
  font-weight: 800;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.8rem;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.BUTTON_DELETE_COLOR};
`;
