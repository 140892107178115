import { defineMessages } from "react-intl";

const messages = defineMessages({
  summary_title: {
    id: "summary.summary_title",
    defaultMessage: "Summary",
  },
  summary_details: {
    id: "summary.summary_details",
    defaultMessage:
      "Due to high demand, we recommend that you complete the checkout process within the next 15 minutes",
  },
});

export default messages;
