/*
 ****************************************************************
 ******************    ALL APP CONSTANTS   **********************
 ****************************************************************
 */

/**
 * extending app global constants
 * @constant LANGUAGE_DEFAULT default value
 */
export const LANGUAGE_DEFAULT = "en";

/**
 * extending app global constants
 * @constant CACHE_TIME default value
 */
export const CACHE_TIME = 1000 * 60; // 1 minute for testing;

/**
 * extending app global constants
 * @constant CACHE_VERSION default value
 */
export const CACHE_VERSION = 1; // version 1

/**
 * extending app global constants
 * @constant __ROOT_REDUX_STATE_KEY__ default value
 */
export const __ROOT_REDUX_STATE_KEY__ = `@REDUX_LOCAL_STATE_PERSIST_KEY`;

/**
 * extending app global constants
 * @constant __AUTHENTICATION_REDUX_STATE_KEY__ default value
 */
export const __AUTHENTICATION_REDUX_STATE_KEY__ = `@REDUX_LOCAL_AUTHENTICATION_KEY`;

/**
 * extending app global constants
 * @constant DEFAULT_PAGINATION default enum
 */
export enum DEFAULT_PAGINATION {
  LIMIT = 10,
  OFF_SET = 0,
}

/**
 * Array to map the months in MMM format
 */
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
