import { IStateTree } from "typings/store";

import { ISeasonUser } from "../models";
import { __SESSION__REDUX__STATE_KEY__ } from "../types";

export const getReduxStateSlice = (state: IStateTree) => state[__SESSION__REDUX__STATE_KEY__];

export const getUserToken = (state: IStateTree): string | undefined =>
  getReduxStateSlice(state)?.data?.jwtToken;

export const getUserData = (state: IStateTree): ISeasonUser | null | undefined =>
  getReduxStateSlice(state).data?.user;

export const getSessionLoading = (state: IStateTree): boolean =>
  getReduxStateSlice(state)?.isLoading;
