import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Withdraw = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0552 8.67196C14.6613 8.2848 14.0282 8.29023 13.641 8.68408L11.5009 10.8612V1.92725C11.5009 1.37496 11.0532 0.927246 10.5009 0.927246C9.94863 0.927246 9.50092 1.37496 9.50092 1.92725V10.7712L7.34649 8.66932C6.95118 8.28364 6.31806 8.29146 5.93239 8.68678C5.54671 9.08209 5.55453 9.71521 5.94985 10.1009L9.88346 13.9385C10.2777 14.3232 10.9088 14.3166 11.2949 13.9238L15.0673 10.0861C15.4545 9.69226 15.4491 9.05912 15.0552 8.67196ZM0.505447 15.8241C0.505447 18.0332 2.29631 19.8241 4.50545 19.8241H16.4947C18.7039 19.8241 20.4947 18.0332 20.4947 15.8241V13.6878C20.4947 13.1355 20.047 12.6878 19.4947 12.6878C18.9424 12.6878 18.4947 13.1355 18.4947 13.6878V15.8241C18.4947 16.9287 17.5993 17.8241 16.4947 17.8241H4.50545C3.40088 17.8241 2.50545 16.9287 2.50545 15.8241V13.6878C2.50545 13.1355 2.05773 12.6878 1.50545 12.6878C0.953163 12.6878 0.505447 13.1355 0.505447 13.6878V15.8241Z"
        fill={colors[color || "WHITE_COLOR"]}
      />
    </svg>
  );
};

export default Withdraw;
