import styled from "styled-components";

export const SummaryTitle = styled.p`
  font-weight: 700;
  font-size: 2.4rem;
  font-style: normal;
  margin-top: 3.2rem;
  line-height: 2.7rem;
  padding-bottom: 1.2rem;
  font-family: "Philosopher";
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 3.4rem;
    margin-top: 5.6rem;
    line-height: 3.8rem;
  }
`;

export const SummaryDetails = styled.p`
  font-weight: 600;
  font-size: 1.2rem;
  font-style: normal;
  line-height: 1.8rem;
  font-family: Raleway;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.SPACE_DEEP_COLOR};
`;
