import styled from "styled-components";
import ReactLoadingSkeleton, { SkeletonTheme } from "react-loading-skeleton";

export const Container = styled.div`
  height: 100%;
  display: flex;
  padding: 0 1rem;
  margin-top: 3.4rem;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    padding: 0 2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    padding: 0;
  }
`;

export const CollectionWrapper = styled.div`
  display: flex;
  margin: 8rem 0rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    margin-top: 4rem;
    align-items: flex-start;
  }
`;

export const Title = styled.p`
  font-size: 3.4rem;
  line-height: 4rem;
  font-weight: bold;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    display: none;
  }
`;

export const CollectionContentLoader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE6_7_8_HEIGHT}) {
    .skeleton__holder {
      :nth-child(odd) {
        margin-right: 1.2rem;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    justify-content: flex-start;
  }
`;

export const SkeletonContainer = styled(SkeletonTheme).attrs(({ theme }) => ({
  baseColor: theme.colors.ONIX_SHADE_COLOR,
  highlightColor: theme.colors.SPACE_DEEP_COLOR,
}))``;

export const Skeleton = styled(ReactLoadingSkeleton)`
  width: 30rem;
  height: 40rem;
  margin-top: 2.4rem;
  border-radius: 4.2rem;
  margin-bottom: 3.829rem;
  backdrop-filter: blur(33px);
  box-shadow: 5px 3px 8px rgba(28, 30, 45, 0.44), inset 0px 0px 3px rgba(50, 59, 92, 0.47),
    inset 0px 0px 6px rgba(137, 148, 196, 0.1);

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    min-width: 34rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE6_7_8_HEIGHT}) {
    min-width: 30rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    height: 42.4rem;
    min-width: 34rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    height: 40rem;
    min-width: 30rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    min-width: 34rem;
  }
`;
