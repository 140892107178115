import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";
import { Apple, EmailLogin, Facebook, Google } from "./components";
import messages from "./messages";

import {
  Hr,
  Title,
  Login,
  SubTitle,
  Container,
  GoToRedeem,
  GlobalStyle,
  DividerText,
  SignupFooter,
  ContentWrapper,
  DividerWrapper,
  SocialButtonsWrapper,
} from "./Signup.styles";

/**
 * Component that renders the Login functionality
 */
const Signup: React.FC<{}> = () => {
  const location = useLocation() as CTLocation;

  useEffect(() => {
    ReactGA.pageview(`${APP_URL_LIST.SIGNUP_URL}`);
  }, []);

  useEffect(() => {
    if (location.state?.path) {
      // Store user previous route on local-storage
      // This is because when user leaves signup screen the react router state clear after logging in with social media option
      localStorage.setItem("state_pathname", location.state?.path);
    }
  }, [location, location.state?.path]);

  return (
    <Container>
      <GlobalStyle />
      <ContentWrapper>
        <GoToRedeem to={APP_URL_LIST.REDEEM_URL} state={{ replace: true }}>
          <Icon name="logo" />
          <Title>
            <FormattedMessage {...messages.title} />
          </Title>
        </GoToRedeem>
        <SubTitle>
          <FormattedMessage {...messages.subtitle} />
        </SubTitle>
        <Login>
          <FormattedMessage {...messages.login} />
        </Login>

        <SocialButtonsWrapper>
          <Facebook />
          <Apple />
          <Google />
        </SocialButtonsWrapper>

        <DividerWrapper>
          <Hr />
          <DividerText>
            <FormattedMessage {...messages.or} />
          </DividerText>
          <Hr />
        </DividerWrapper>

        <EmailLogin />
      </ContentWrapper>
      <SignupFooter />
    </Container>
  );
};

export default Signup;
