import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { SummaryTitle, SummaryDetails } from "./SummarySection.style";
import messages from "./messages";

const SummarySection: React.FC<{}> = () => (
  <Fragment>
    <SummaryTitle>
      <FormattedMessage {...messages.summary_title} />
    </SummaryTitle>
    <SummaryDetails>
      <FormattedMessage {...messages.summary_details} />
    </SummaryDetails>
  </Fragment>
);

export default SummarySection;
