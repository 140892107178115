import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { APP_URL_LIST } from "@app/routes/urls";
import messages from "@app/components/molecules/Header/messages";
import { getUserToken } from "@app/providers/store/session/selectors/selectors";

import { Container, LogoIcon, LogoTitle, LogoWrapper, GlobalStyle } from "./PrivacyPolicy.styles";
import ReactGA from "react-ga";

const PrivacyPolicy: React.FC<{}> = () => {
  const navigate = useNavigate();
  const userToken = useSelector(getUserToken);

  React.useEffect(() => {
    ReactGA.pageview(`${APP_URL_LIST.PRIVACY_POLICY_URL}`);
  }, []);

  return (
    <Container>
      <GlobalStyle />
      <div className="policy-container">
        {!userToken && (
          <LogoWrapper className="logo-wrapper" onClick={() => navigate(APP_URL_LIST.SIGNUP_URL)}>
            <LogoIcon />
            <LogoTitle>
              <FormattedMessage {...messages.title} />
            </LogoTitle>
          </LogoWrapper>
        )}

        <h1>
          <span> PRIVACY POLICY</span>
        </h1>
        <p>
          <span>The purpose of this Notice</span>
        </p>
        <p>
          <span>
            This notice describes how we collect and use personal data about individuals, in
            accordance with the General Data Protection Regulation(GDPR).
          </span>
        </p>
        <p>
          <span>
            You should read the following carefully in order to understand our procedures regarding
            your personal data and how we treat it.
          </span>
        </p>
        <h4>
          <span>WHAT IS THE GDPR?</span>
        </h4>
        <p>
          <span>
            The General Data Protection Regulation (GDPR) aims to strengthen data protection for
            people within the European Union, replacing the EU privacy directive known as Directive
            95/46/EC. This regulation came into force on 25 May 2018. For more information about the
            GDPR, please visit:{" "}
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-pro-tection_en"
              target="_blank"
              rel="noopener"
            >
              https://ec.europa.eu/info/law/law-topic/data-pro-tection_en
            </a>
          </span>
        </p>
        <h4>
          <span>ABOUT US</span>
        </h4>
        <p>
          <span>
            ComeTogether Private Company is registered in Greece and our registered office is
            located at Tompazi 32 Thessaloniki, 546 44, Greece. Our purpose is to connect
            individuals and businesses to easily organise, work at, sell products at, invest on,
            sponsor and attend events(matchmaking between all types of event stakeholders, or any
            subset of them).
          </span>
        </p>
        <p>
          <span>
            For the purpose of the Data Protection Legislation and this notice, we are the “Data
            Controller”, which means that we are responsible for deciding how we hold and use any
            personal data about you. We are required under the Data Protection Legislation to notify
            you of the information contained in this Privacy Notice.
          </span>
        </p>
        <h4>
          <span>WHAT DATA WE COLLECT</span>
        </h4>
        <p>
          <span>
            We collect information about you when you subscribe to our newsletter and when you email
            us through the email address provided in our website.
          </span>
        </p>
        <p>
          <span>
            We will process this data on the basis of your consent in signing up to our newsletters.
          </span>
        </p>
        <h4>
          <span>WHAT WE DO</span>
        </h4>
        <p>
          <span>We use the information that we collect as follows:</span>
        </p>
        <ul>
          <li>
            <span> Specific Reason</span>
          </li>
        </ul>
        <p>
          <span>
            If you provide personal data for a certain purpose, we may use the personal data solely
            in connection with the purpose for which it was provided. For instance, if you contact
            us by e-mail, we will use the Personal Data you provide to answer your question or
            resolve your problem.
          </span>
        </p>
        <ul>
          <li>
            <span>Fulfil the contract</span>
          </li>
        </ul>
        <p>
          <span>
            We use personal data of the service providers, venue holders, event organizers and
            attendees to connect them with each other, in order to fulfill the purpose of our
            contract with each of the aforementioned individuals.
          </span>
        </p>
        <ul>
          <li>
            <span> Internal Business Purposes-Legitimate interests</span>
          </li>
        </ul>
        <p>
          <span>
            We may use your personal data for internal business purposes, including to help us
            improve the content and functionality of our services, to better understand our users,
            to improve the Services, to manage your account and provide you with customer service,
            and to generally manage our services and business.
          </span>
        </p>
        <ul>
          <li>
            <span> Marketing</span>
          </li>
        </ul>
        <p>
          <span>
            We may use your personal data for our marketing and advertising purposes. We do this in
            order to inform you about services or events we believe might be of interest to you. You
            can always ”opt out” by sending as an email to info@cometogether.network
          </span>
        </p>
        <ul>
          <li>
            <span>Use of Interest-Based Data</span>
          </li>
        </ul>
        <p>
          <span>
            We use the information we have to make suggestions for you, such as events you may be
            interested in. To create personalized information that is unique and relevant to you, we
            use your connections, preferences, interests and activities based on the data we collect
            and learn from you.
          </span>
        </p>
        <ul>
          <li>
            <span>Comply with legal obligation</span>
          </li>
        </ul>
        <h4>
          <span>DATA STORAGE</span>
        </h4>
        <p>
          <span>
            We use MongoDB atlas, a fully automated cloud service engineered and run by the same
            team that builds the database to store your details. In the process of working with
            MongoDBatlas one must choose a cloud provider to store their data. We use
            AmazonWebServices having selected a database located in Frankfurt, Germany. MongoDBatlas
            handles the rest automatically as regards to configuring and managing our database.
            Consequently, your data are transferred to our database in Germany, and are accessed by
            MongoDBatlas and AmazonWebServices solely for the purpose of the aforementioned storage
            process. Of course, to fully understand how and with whom the latter two are going to
            use your personal data, you should check out their own Privacy Policies (MongoDBatlas,
            AmazonWebServices).
          </span>
        </p>
        <h4>
          <span>DO WE SHARE YOUR PERSONAL DATA</span>
        </h4>
        <p>
          <span>
            We don’t share your personal data with third parties without your explicit consent. We
            consider this information to be a vital part of our relationship with you.
          </span>
        </p>
        <h4>
          <span>CRASH AND DIAGNOSTIC REPORTS</span>
        </h4>
        <p>
          <span>
            We use Sentry (sentry.io) in order to find possible issues and crashes that exist in the
            app in order to improve it. We don’t use any data that can identify a user’s identity.
            The only data we use that has to do with the user are the mobile device(if mobile is
            used) and the software version.
          </span>
        </p>
        <h4>
          <span>POTENTIAL TRANSFER OF BUSINESS</span>
        </h4>
        <p>
          <span>
            As we continue to develop our business, we might sell or buy stores,subsidiaries or
            business units. In such transactions, customer informa-tion generally is one of the
            transferred business assets but remains subject to the promises made in any pre-existing
            Privacy Policy, un-less, of course, if you consent otherwise. Also, in the unlikely
            event that ComeTogether P.C. or substantially all of its assets are acquired,your
            information will of course be one of the transferred assets.
          </span>
        </p>
        <h4>
          <span>DATA RETENTION</span>
        </h4>
        <p>
          <span>
            We keep your information only so long as we need it to fulfil the pur-poses described in
            this policy. This is also the case for anyone that we share your information with and
            who carries out services on our behalf. When we no longer need to use your information
            and there is no need for us to keep it to comply with our legal or regulatory
            obliga-tions, we’ll either remove it from our systems or depersonalize it so that we
            can’t identify you. In any case, we won’t keep your personal data more than ten years.
          </span>
        </p>
        <h4>
          <span>YOUR RIGHTS</span>
        </h4>
        <p>
          <span>
            It is important that any and all personal data we hold about you is ac-curate and
            current. Should any of your personal information change,please notify us of such changes
            immediately by contacting us.
          </span>
        </p>
        <p>
          <span>
            You have several individual rights in relation to your personal data that we collect. In
            particular, you have the right to:
          </span>
        </p>
        <ul>
          <li>
            <span>Request access to your personal data.</span>
          </li>
          <li>
            <span>Request any inaccuracies in your personal information be corrected</span>
          </li>
          <li>
            <span>Request erasure of your personal data.</span>
          </li>
          <li>
            <span>
              You may ask to object or restrict the processing of your personal data where there is
              something about your particular circumstances which makes you want to object to
              processing even when we are relying on a legitimate interest for that processing.
            </span>
          </li>
        </ul>
        <p>
          <span>
            Additionally, where we collect your personal details for sending your newsletters,
            campaigns or updates related to our work, you have the right at any time to notify us
            that you no longer want to receive this information. To withdraw your consent you can
            use the “Unsubscribe”link present at the bottom of every email communication we send or
            you can contact us at info@cometogether.network.
          </span>
        </p>
        <h4>
          <span>LINKS TO THIRD-PARTY WEBSITES</span>
        </h4>
        <p>
          <span>
            This Privacy Policy applies only to the services provided by us. TheServices may contain
            links to other websites not operated or controlled by us (the “Third Party Sites”). The
            policies and procedures we described here do not apply to the Third Party Sites. The
            links from the services do not imply that we endorse or have reviewed the Third Party
            Sites. We suggest contacting those sites directly for information on their privacy
            policies.
          </span>
        </p>
        <h4>
          <span>USE OF SERVICES BY UNDERAGED PEOPLE</span>
        </h4>
        <p>
          <span>
            Registration and use of our services is not allowed to individuals under the age of 16,
            unless there is consent of the persons who have their parental responsibility.
          </span>
        </p>
        <h4>
          <span>PRIVACY POLICY UPDATES</span>
        </h4>
        <p>
          <span>
            We may change this privacy policy from time to time. We will post a privacy policy
            changes on this page and, if the changes are signifi-cant, we will provide a more
            prominent notice by adding a notice on the Services homepages, login screens, or by
            sending you an email notification. We will also keep prior versions of this Privacy
            Policy inan archive for your review. We encourage you to review our privacy policy
            whenever you use the Services to stay informed about our infor-mation practices and the
            ways you can help protect your privacy.
          </span>
        </p>
        <h4>
          <span>CONTACT DETAILS</span>
        </h4>
        <p>
          <span>
            If you have any questions concerns about how we have collected, processed and used your
            data please contact us by emailing at info@-cometogether.network
          </span>
        </p>
        <h4>
          <span>COOKIE POLICY</span>
        </h4>
        <p>
          <span>
            At ComeTogether, we respect your concerns about privacy and value the relationship that
            we have with you.
          </span>
        </p>
        <p>
          <span>
            Like many companies, we use technology on our website to collect in-formation that helps
            us enhance your experience and our services.The cookies that we use at ComeTogether
            allow our website to work and help us to understand what information and advertising is
            most useful to visitors.
          </span>
        </p>
        <p>
          <span>
            Please take a moment to familiarise yourself with our cookie practices and let us know
            if you have any questions by sending us an email at{" "}
            <a href="mailto:info@cometogether.network" target="_blank" rel="noopener">
              info@cometogether.network
            </a>
            .
          </span>
        </p>
        <p>
          <strong>Who is collecting it?</strong>
        </p>
        <p>
          <span>
            Any personal data provided to or collected by ComeTogether via cookies and other
            tracking technologies is controlled by ComeTogether Pri-vate Company. By using our
            website, you are consenting to our use of cookies in accordance with this Cookie Notice.
          </span>
        </p>
        <p>
          <strong>What does cookie mean?</strong>
        </p>
        <p>
          <span>
            Cookies are files containing small amounts of information which are downloaded to any
            internet enabled device – such as your computer,smartphone or tablet – when you visit a
            website.
          </span>
        </p>
        <p>
          <strong>What purpose do we use cookies for?</strong>
        </p>
        <p>
          <span>
            We use cookies to make the ComeTogether website easier to use, to deliver a personalised
            experience, and to better tailor our services and websites to your interests and needs.
            Cookies are used to help speedup your future activities and your experience on
            ComeTogether web-site.
          </span>
        </p>
        <p>
          <strong>What cookies do we use?</strong>
        </p>
        <p>
          <span>Strictly necessary cookies-essential cookies</span>
        </p>
        <p>
          <span>
            These are cookies that are strictly necessary for a service requested by the user and
            are exempt from the consent requirements. Strictly Necessary cookies include cookies
            used for, security, page load and remembering previous actions when returning to
            previously visited pages in the same session.
          </span>
        </p>
        <h4>
          <span>DURATION OF THE COOKIES WE USE</span>
        </h4>
        <p>
          <span>Session Cookies</span>
        </p>
        <p>
          <span>
            These cookies are temporary cookies that remain on your device until you log out of our
            website.
          </span>
        </p>
        <h4>
          <span>COOKIE POLICY UPDATES</span>
        </h4>
        <p>
          <span>
            We may change this cookie policy from time to time. We will post anycookie policy
            changes on this page and, if the changes are significant,we will provide a more
            prominent notice by adding a notice on the Ser-vices homepages, login screens, or by
            sending you an email notifica-tion. We encourage you to review our cookie policy
            whenever you use the Services to stay informed about our information practices andthe
            ways you can help protect your privacy.
          </span>
        </p>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
