import React, { useEffect } from "react";
import { useWindowSize } from "usehooks-ts";
import { useTheme } from "styled-components";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { APP_URL_LIST } from "@app/routes/urls";
import { Locale } from "@app/providers/store/settings/models";
import { closeGenericModalAction } from "@app/providers/store/genericModal";
import { getGenericModal } from "@app/providers/store/genericModal/selectors";
import { getSettingsLocal, updateUserLanguageAction } from "@app/providers/store/settings";
import { FooterProps } from "./Footer.interface";
import messages from "./messages";

import {
  Link,
  Language,
  Container,
  FooterText,
  LanguageWrapper,
  FooterRowWrapper,
} from "./Footer.styles";

const languageList = [Locale.ENGLISH, Locale.GREEK];

export const Footer: React.FC<FooterProps> = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { breakpoints } = useTheme();
  const { width: screen_width } = useWindowSize();

  const locale = useSelector(getSettingsLocal, shallowEqual);
  const { show: show_modal } = useSelector(getGenericModal, shallowEqual);

  const handleLanguage = (language: Locale) => () => {
    if (language !== locale) {
      dispatch(updateUserLanguageAction(language));
    }
  };

  const shouldTruncateLanguage =
    screen_width >=
      Number(breakpoints.MEDIUM_VIEWPORT.substring(0, breakpoints.MEDIUM_VIEWPORT.length - 2)) &&
    screen_width <=
      Number(
        breakpoints.LARGE_VIEWPORT_IPAD_PRO.substring(
          0,
          breakpoints.LARGE_VIEWPORT_IPAD_PRO.length - 2
        )
      );

  useEffect(() => {
    // remove modal if user click on terms or privacy on modal footer
    if (
      show_modal &&
      (pathname === APP_URL_LIST.PRIVACY_POLICY_URL ||
        pathname === APP_URL_LIST.TERMS_AND_CONDITION_URL)
    ) {
      dispatch(closeGenericModalAction());
    }
  }, [dispatch, pathname, show_modal]);

  return (
    <Container {...props}>
      <FooterText color="SPACE_LIGHT_COLOR" className="desktop_uniq_together">
        <FormattedMessage
          {...messages.uniq_together}
          values={{ year: new Date().getUTCFullYear() }}
        />
      </FooterText>

      <FooterRowWrapper>
        <Link to={APP_URL_LIST.PRIVACY_POLICY_URL}>
          <FooterText isActive={pathname === APP_URL_LIST.PRIVACY_POLICY_URL}>
            <FormattedMessage {...messages.privacy_policy} />
          </FooterText>
        </Link>

        <Link to={APP_URL_LIST.TERMS_AND_CONDITION_URL}>
          <FooterText isActive={pathname === APP_URL_LIST.TERMS_AND_CONDITION_URL}>
            <FormattedMessage {...messages.terms_and_conditions} />
          </FooterText>
        </Link>

        <Link
          to="#"
          onClick={(e) => {
            window.location.href = "mailto:support@cometogether.network?subject=Report A Bug!";
            e.preventDefault();
          }}
        >
          <FooterText>
            <FormattedMessage {...messages.report_a_bug} />
          </FooterText>
        </Link>
      </FooterRowWrapper>

      <LanguageWrapper>
        {languageList.map((language) => (
          <Language
            key={language}
            isSelected={locale === language}
            onClick={handleLanguage(language)}
          >
            <FooterText>
              <FormattedMessage {...messages[language]} values={{ shouldTruncateLanguage }} />
            </FooterText>
          </Language>
        ))}
      </LanguageWrapper>

      <FooterText color="SPACE_LIGHT_COLOR" className="mobile_uniq_together">
        <FormattedMessage
          {...messages.uniq_together}
          values={{ year: new Date().getUTCFullYear() }}
        />
      </FooterText>
    </Container>
  );
};
