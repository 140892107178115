import styled, { createGlobalStyle } from "styled-components";

import { Image as DefaultImage } from "@app/components/atoms";
import { INftTypes } from "@app/providers/store/nft/models";

export const Container = styled.div`
  height: 94vh;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    height: 92vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    width: 90%;
    height: 85vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_DESKTOP_VIEW}) {
    width: 85%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.DESKTOP_FULL_HD_VIEW}) {
    width: 80%;
  }
`;

export const GlobalStyle = createGlobalStyle`
    .footer__container {
      display: none;
    }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    .footer__container {
      display: flex;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(DefaultImage)<{ nftType: INftTypes | undefined }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_VIEWPORT}) {
    object-fit: ${({ nftType }) =>
      nftType === INftTypes["VIDEO"] || nftType === INftTypes["SOUND"] ? "contain" : "cover"};
  }
`;

export const ImageBackgroundOverlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  cursor: pointer;
  position: absolute;
  flex-direction: column;

  background: linear-gradient(
      180deg,
      rgba(15, 17, 30, 0) 69.04%,
      rgba(10, 11, 20, 0.61) 80.19%,
      #0a0b14 93.37%
    ),
    linear-gradient(
      180deg,
      #0a0b14 1.45%,
      rgba(10, 11, 20, 0.77) 15.08%,
      rgba(15, 17, 30, 0) 29.36%
    );

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    background: none;

    /* background: linear-gradient(
        180deg,
        rgba(15, 17, 30, 0) 69.04%,
        rgba(10, 11, 20, 0.1) 80.19%,
        rgba(10, 11, 20, 0.1) 93.37%
      ),
      linear-gradient(
        180deg,
        rgba(10, 11, 20, 0.1) 1.45%,
        rgba(10, 11, 20, 0.1) 15.08%,
        rgba(15, 17, 30, 0) 29.36%
      ); */
  }
`;

export const ImageBackgroundOverlayContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5rem 3.2rem 0rem 3.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    display: none;
  }
`;
