import styled, { css, createGlobalStyle } from "styled-components";

import { Footer as StyledFooter } from "@app/components/molecules";

export const GlobalStyle = createGlobalStyle<{ isRedeem: boolean }>`
  /* This fixes Safari browser overflow scroll problem 
    *  Solution found here [https://tousu.in/qa/?qa=1135152/]
    */
  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    body {
      overflow: hidden;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    height: inherit;
    overflow: hidden;
  }
`;

export const ContentWrapper = styled.div<{ isRedeem: boolean }>`
  flex: 1;
  width: 100%;
  padding-top: 5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    padding-top: 7rem;
  }

  ${({ isRedeem }) => {
    if (isRedeem) {
      return css`
        @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
          height: 100%;
          display: flex;
        }
      `;
    }
  }};
`;

export const ChildrenWrapper = styled.div<{ isRedeem: boolean }>`
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  ${({ isRedeem }) => {
    if (isRedeem) {
      return css`
        @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
          height: 92%;
        }
      `;
    }
  }};
`;

export const Footer = styled(StyledFooter)`
  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    bottom: 0;
    position: fixed;
  }
`;
