import ThemeProvider from "./theme";
import StoreProvider from "./store";
import StripeProvider from "./stripe";
import CookiesProvider from "./cookie";

import IntlProvider from "./internationalization";

const Providers: React.FC<{}> = ({ children }) => {
  return (
    <StripeProvider>
      <StoreProvider>
        <IntlProvider>
          <ThemeProvider>
            <CookiesProvider>{children}</CookiesProvider>
          </ThemeProvider>
        </IntlProvider>
      </StoreProvider>
    </StripeProvider>
  );
};

export default Providers;
