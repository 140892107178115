import QRCodeSVG from "qrcode.react";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    .footer__container {
      background-color: ${({ theme }) => theme.colors.ONIX_DEEP_COLOR};
    }

    #scrollable_div {
      height: 92vh;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.ONIX_DEEP_COLOR};
`;

export const Contents = styled.div`
  width: 100%;
  display: flex;
  max-width: 82.4rem;
  margin-bottom: 5rem;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    margin-bottom: 10rem;
  }
`;

export const ProfileHeader = styled.div`
  width: 100%;
  display: flex;
  height: 34.7rem;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  backdrop-filter: blur(105px);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 0px 0px 4rem 4rem;
  background-image: url("/assets/svg/profile-header.svg");
  box-shadow: inset 0px -3px 4px rgba(215, 222, 255, 0.08);

  ::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: 1;
    opacity: 0.3;
    display: flex;
    position: absolute;
    background: #10121f;
    backdrop-filter: blur(10.5rem);
    border-radius: 0px 0px 4rem 4rem;
  }
`;

export const QRCodeContainer = styled.div`
  display: flex;
  padding: 2rem;
  width: 22.2rem;
  height: 22.2rem;
  position: relative;
  align-items: center;
  border-radius: 4rem;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-shadow: inset 0px -3px 4px rgba(215, 222, 255, 0.08);
  background-image: url("/assets/svg/qrcode-background.svg");

  ::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    border-radius: 4rem;
    backdrop-filter: blur(2rem);
    background: rgba(80, 81, 159, 0.12);
    box-shadow: inset 0px -5px 4px rgba(255, 255, 255, 0.07),
      inset 0px 0px 4px rgba(215, 222, 255, 0.22);
  }
`;

export const QRCodeBackground = styled.div`
  z-index: 2;
  padding: 2rem;
  position: absolute;
  align-items: center;
  border-radius: 3.3rem;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.WHITE_COLOR};
`;

export const QRCode = styled(QRCodeSVG)`
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 7rem;
  padding: 0 2.2rem;

  .content_wrapper_for_desktop {
    flex: 1;
    display: none;
    margin-left: 1.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_PIXEL_XL}) {
    padding: 0 4.2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    display: flex;
    margin-top: 9.8rem;

    .content_wrapper_for_desktop {
      flex: 1;
      display: block;

      > div {
        :nth-child(1) {
          margin-bottom: 1.6rem;

          p:first-child {
            font-size: 2rem;
            font-weight: 600;
            line-height: 2.5rem;
            letter-spacing: -0.00642857px;
            font-family: "Signika Negative";
            color: ${({ theme }) => theme.colors.SPACE_DEEP_COLOR};
          }

          .account_title {
            display: none;
          }

          div {
            margin: 0;
          }
        }
      }
    }

    /* TURN OFF MOBILE LAYOUTS */
    > div {
      :nth-child(1),
      :nth-child(3) {
        display: none;
      }
    }
  }
`;
