import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Audio = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="33"
      height="25"
      fill="none"
      viewBox="0 0 33 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_b_501:21268)">
        <path d="M0.8125 12.5C0.8125 5.59644 6.40894 0 13.3125 0H20.3125C27.2161 0 32.8125 5.59644 32.8125 12.5V12.5C32.8125 19.4036 27.2161 25 20.3125 25H13.3125C6.40894 25 0.8125 19.4036 0.8125 12.5V12.5Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5523 7.69516C15.2033 7.69516 14.9039 7.9442 14.8404 8.28742L14.5267 9.98266H22.1666L22.4181 8.54384C22.4955 8.10084 22.1546 7.69516 21.7049 7.69516H15.5523ZM21.9135 11.4307L21.1948 15.542C21.0213 15.4827 20.8352 15.4505 20.6416 15.4505C19.6971 15.4505 18.9315 16.2162 18.9315 17.1606C18.9315 18.1051 19.6971 18.8708 20.6416 18.8708C21.4968 18.8708 22.2054 18.2429 22.3317 17.423C22.3389 17.3981 22.3448 17.3726 22.3493 17.3465L23.8445 8.79318C24.0768 7.46419 23.0541 6.24713 21.7049 6.24713H15.5523C14.5052 6.24713 13.6071 6.99426 13.4166 8.02392L12.0242 15.5478C11.8459 15.4848 11.6541 15.4505 11.4543 15.4505C10.5098 15.4505 9.74414 16.2162 9.74414 17.1606C9.74414 18.1051 10.5098 18.8708 11.4543 18.8708C12.3048 18.8708 13.0104 18.2498 13.1422 17.4364C13.1505 17.4095 13.1574 17.3819 13.1626 17.3535L14.2587 11.4307H21.9135Z"
          fill={colors[color || "SPACE_PURE_COLOR"]}
        />
        <rect
          x="0.92"
          y="1.11141"
          width="30.16"
          rx="11.3882"
          height="22.7764"
          stroke={colors[color || "SPACE_PURE_COLOR"]}
          strokeWidth="1.84"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_501:21268"
          x="-12.1875"
          y="-13"
          width="58"
          height="51"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="6.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_501:21268" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_501:21268"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Audio;
