import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";

export const GlobalStyle = createGlobalStyle`
  body {
    .footer__container {
      background-color: ${({ theme }) => theme.colors.ONIX_DEEP_COLOR};
    }

    #scrollable_div {
      height: 92vh;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  margin-top: 4.44rem;
  align-items: center;
  flex-direction: column;
`;

export const Contents = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2rem;
  max-width: 75.2rem;
  margin-bottom: 5rem;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    padding: 0 3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    padding: 0 4.8rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    margin-bottom: 10rem;
  }
`;

export const HeaderBackButton = styled(Link)`
  display: none;
  width: fit-content;
  text-decoration: none;
  align-items: flex-start;

  &:link,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: flex;
  }
`;

export const HeaderBackTitle = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-left: 0.8rem;
  text-decoration: none;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.CARDINAL_TEAL_COLOR};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CheckoutTitle = styled.p`
  font-weight: 700;
  font-size: 3.4rem;
  font-style: normal;
  line-height: 3.8rem;
  margin-right: 1.6rem;
  font-family: "Philosopher";
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 5.6rem;
    line-height: 6.3rem;
  }
`;
