import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Share = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m19.405 9.325.65.65a.92.92 0 0 0 0-1.3l-.65.65Zm-7.629 7.629h-.92a.92.92 0 0 0 1.57.65l-.65-.65Zm0-15.258.65-.65a.92.92 0 0 0-1.57.65h.92Zm0 4.524.047.919a.92.92 0 0 0 .873-.92h-.92ZM1.293 19.33h-.92a.92.92 0 0 0 1.812.224l-.892-.223Zm10.483-6.991h.92a.92.92 0 0 0-.851-.918l-.069.918Zm6.978-3.664-7.629 7.628 1.302 1.301 7.628-7.628-1.3-1.301Zm-7.629-6.328 7.63 7.629 1.3-1.301-7.628-7.63-1.301 1.302Zm1.571 3.873V1.696h-1.84V6.22h1.84ZM2.213 19.33c0-4.213 1.215-7.186 2.978-9.128 1.766-1.945 4.149-2.935 6.632-3.063L11.73 5.3c-2.915.15-5.773 1.32-7.9 3.664-2.13 2.346-3.456 5.79-3.456 10.366h1.84Zm9.631-7.908c-1.717-.129-4.127.093-6.34 1.193C3.258 13.732 1.241 15.746.4 19.108l1.785.446c.698-2.789 2.326-4.39 4.138-5.291 1.846-.918 3.908-1.117 5.384-1.007l.137-1.835Zm.852 5.532v-4.615h-1.84v4.615h1.84Z"
        fill={colors[color || "WHITE_COLOR"]}
      />
    </svg>
  );
};

export default Share;
