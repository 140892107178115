import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Copy = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.959 18.6773L3.65298 18.6773C3.10069 18.6773 2.65298 18.2296 2.65298 17.6773V7.10208C2.65298 6.54979 3.10069 6.10208 3.65298 6.10208L13.959 6.10208C14.5113 6.10208 14.959 6.54979 14.959 7.10208L14.959 17.6773C14.959 18.2296 14.5113 18.6773 13.959 18.6773ZM16.959 17.6773C16.959 19.3341 15.6158 20.6773 13.959 20.6773L3.65298 20.6773C1.99613 20.6773 0.652979 19.3341 0.652979 17.6773V7.10208C0.652979 5.44522 1.99612 4.10208 3.65298 4.10208L13.959 4.10208C15.6158 4.10208 16.959 5.44522 16.959 7.10208V17.6773ZM18.6543 12.7895C18.6543 13.3418 19.102 13.7895 19.6543 13.7895C20.2066 13.7895 20.6543 13.3418 20.6543 12.7895V6.48218C20.6543 3.16848 17.968 0.482178 14.6543 0.482178H8.07167C7.51939 0.482178 7.07167 0.929893 7.07167 1.48218C7.07167 2.03446 7.51939 2.48218 8.07167 2.48218H14.6543C16.8634 2.48218 18.6543 4.27304 18.6543 6.48218V12.7895Z"
        fill={colors[color || "CARDINAL_TEAL_COLOR"]}
      />
    </svg>
  );
};

export default Copy;
