import * as Sentry from "@sentry/react";

/**
 *
 * @description Reports any thrown error to our error service example Sentry.
 * @function reportError
 * @property Error
 * @returns void
 */

const reportError = (error: Error, extraData?: Record<string, unknown>): void => {
  Sentry.captureException(error, extraData);
};

export default reportError;
