import styled from "styled-components";
import { Link } from "react-router-dom";

export * from "../redeem/Redeem.styles";
import { Container as RedeemContainer, Skeleton as RedeemSkeleton } from "../redeem/Redeem.styles";

export const Container = styled(RedeemContainer)`
  margin-top: 3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    margin-top: 5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    margin-top: 7rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    width: 90%;
  }
`;

export const HeaderBackButton = styled(Link)`
  display: flex;
  width: fit-content;
  align-items: center;
  text-decoration: none;

  &:link,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    display: none;
  }
`;

export const HeaderBackTitle = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-left: 0.8rem;
  text-decoration: none;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.CARDINAL_TEAL_COLOR};
`;

export const Skeleton = styled(RedeemSkeleton)`
  margin-top: 0rem;
  margin-bottom: 2rem;
`;

export const HeaderTitleWrapper = styled.div`
  div:nth-child(2) {
    margin: auto;
    margin-top: 3.8rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_VIEWPORT}) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    div:nth-child(2) {
      margin: 0;
      margin-right: 2rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    width: 90%;
  }
`;

export const CollectionContent = styled.div<{ isMinimized: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: ${({ isMinimized }) => (isMinimized ? "space-evenly" : "center")};

  .nft__min__redeem__card {
    margin: 0.7rem 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X_PLUS}) {
    justify-content: center;

    .nft__min__redeem__card {
      margin: 0.7rem 1.29rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE6_7_8_HEIGHT}) {
    .nft__max__redeem__card {
      width: 30rem;

      :nth-child(even) {
        margin-right: 0rem;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    .nft__max__redeem__card {
      width: 34rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    margin-top: 9.98rem;
    justify-content: flex-start;

    .nft__max__redeem__card {
      width: 30rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    padding-right: ${({ isMinimized }) => (isMinimized ? 10 : 0)}rem;

    .nft__max__redeem__card {
      width: 34rem;

      :nth-child(even) {
        margin-right: 1.2rem;
      }
    }
  }
`;

export const LoadingMoreWrapper = styled.div`
  display: flex;
  margin: 5rem 0rem;
  padding: 0rem 5rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  p {
    opacity: 0.7;
    font-weight: 500;
    line-height: 2rem;
    font-size: 1.6rem;
    margin-top: 1.5rem;
    word-break: break-word;
    letter-spacing: 0.025em;
    color: ${({ theme }) => theme.colors.SPACE_LIGHT_COLOR};
  }
`;

export const EmptyListWrapper = styled(LoadingMoreWrapper)`
  p {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.7rem;
    font-family: "Philosopher";
  }
`;
