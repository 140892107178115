import styled from "styled-components";

import {
  ZoomOutContainer as __ZoomOutContainer,
  ImageTransformComponentContainer as __ImageTransformComponentContainer,
} from "../ImageModal/ImageModal.style";

export { TransformImage } from "../ImageModal/ImageModal.style";

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  .nft_audio_player {
    background-color: ${({ theme }) => theme.colors.ONIX_DARK_COLOR};

    .rhap_time,
    .rhap_button-clear {
      color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};
    }
  }
`;

export const ImageTransformComponentContainer = styled(__ImageTransformComponentContainer).attrs({
  isNftDetailImageOverviewInstructionDone: true,
})`
  height: 90vh;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    height: 70vh;
  }
`;

export const ZoomOutContainer = styled(__ZoomOutContainer)`
  padding: 10px;
  border-radius: 50%;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    padding: 15px;
  }
`;
