import React from "react";

import { Icon } from "@app/components/atoms";

import { Container, GridButton } from "./PresentationButtons.style";

export const PresentationButtons: React.FC<{
  setGrid: VoidFunction;
  gridPresentation: boolean;
}> = ({ setGrid, gridPresentation }) => (
  <Container>
    <GridButton onClick={setGrid}>
      <Icon name="grid-particles" color={gridPresentation ? "CARDINAL_TEAL_COLOR" : undefined} />
    </GridButton>
    <GridButton onClick={setGrid}>
      <Icon name="grid-tiles" color={!gridPresentation ? "CARDINAL_TEAL_COLOR" : undefined} />
    </GridButton>
  </Container>
);
