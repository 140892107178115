import styled from "styled-components";

import { Button } from "@app/components/atoms";

export const GoogleButton = styled(Button).attrs({ icon: "google" })`
  background: linear-gradient(
      0deg,
      ${({ theme }) => theme.colors.LINEAR_GOOGLE_RED_COLOR},
      ${({ theme }) => theme.colors.LINEAR_GOOGLE_RED_COLOR}
    ),
    #a32d2d;
  box-shadow: 0px -2px 9px rgba(255, 226, 122, 0.47),
    inset 0px 0px 8px 1px rgba(255, 240, 107, 0.54);

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.LINEAR_GOOGLE_RED_COLOR};
      }
    }
  }

  &:focus,
  &:active {
    background: linear-gradient(
        0deg,
        ${({ theme }) => theme.colors.LINEAR_GOOGLE_RED_COLOR},
        ${({ theme }) => theme.colors.LINEAR_GOOGLE_RED_COLOR}
      ),
      #a32d2d !important;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.WHITE_COLOR};
      }
    }
  }
`;
