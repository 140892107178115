/**
 *
 * @description webShareFiles a method for sharing NFT files [images | videos | audios]
 * @function webShareFiles
 * @property source {string}
 * @returns Promise<void>
 */

const webShareFiles = async (shareData: ShareData): Promise<void> => {
  if (navigator.canShare && navigator.canShare(shareData)) {
    navigator.share(shareData);
  }
};

export default webShareFiles;
