import { defineMessages } from "react-intl";

const messages = defineMessages({
  number_of_nfts: {
    id: "redeem_overview.redeem_nft_title.number_of_nfts",
    defaultMessage: `{numberOfNfts, plural, =0 {# NFT} one {# NFT} other {# NFTs}}`,
  },
  readMore: {
    id: "redeem_overview.redeem_nft_title.readMore",
    defaultMessage: "Read More",
  },
  readLess: {
    id: "redeem_overview.redeem_nft_title.readLess",
    defaultMessage: "Read Less",
  },
  collection: {
    id: "redeem_overview.redeem_nft_title.collection",
    defaultMessage: "Collection",
  },
  collectionPromo: {
    id: "redeem_overview.redeem_nft_title.collection",
    defaultMessage: "Your collectibles all in one place",
  },
});

export default messages;
