import { defineMessages } from "react-intl";

const messages = defineMessages({
  redeem: {
    id: "sidedrawer.redeem",
    defaultMessage: "MarketPlace",
  },
  collection: {
    id: "sidedrawer.collection",
    defaultMessage: "Collection",
  },
  go_back_to_redeem: {
    id: "sidedrawer.go_back_to_redeem",
    defaultMessage: "Back To MarketPlace",
  },
  go_back_to_list: {
    id: "sidedrawer.go_back_to_list",
    defaultMessage: "Back To List",
  },
});

export default messages;
