/**
 *
 * @description arrayBufferToBlob a method for converting array buffer to blobs
 * @function arrayBufferToBlob
 * @property buffer: ArrayBuffer
 * @returns void
 */

const arrayBufferToBlob = (buffer: ArrayBuffer) => {
  return new Blob([buffer], { type: "application/octet-stream" });
};

export default arrayBufferToBlob;
