import styled, { DefaultTheme } from "styled-components";

import { Button } from "@app/components/atoms";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    flex: 1;
    width: auto;
    margin: 0px;
    padding: 4.3rem;
    border-radius: 24px;
    backdrop-filter: blur(33px);
    border: 6px solid ${({ theme }) => theme.colors.ONIX_DARK_COLOR};
  }
`;

export const Contents = styled.div`
  display: flex;
  margin-top: 1.6rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CopyButton = styled(Button)`
  width: auto;
  height: auto;
  background: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0 0 0 2rem;
  color: ${({ theme }) => theme.colors.CARDINAL_TEAL_COLOR};

  &:hover {
    border: none;
    background: none;
  }
`;

export const Title = styled.p`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: -0.00642857px;
  font-family: "Signika Negative";
  color: ${({ theme }) => theme.colors.SPACE_DEEP_COLOR};
`;

export const SubTitle = styled(Title)<{ color?: keyof DefaultTheme["colors"] }>`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: "Raleway";
  letter-spacing: 0.02em;
  font-weight: ${({ color }) => (color ? 600 : 800)};
  color: ${({ theme, color }) => theme.colors[color || "SPACE_SHADE_COLOR"]};

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
`;
