import { INftState, NftsActionTypes } from "../models";
import {
  SET_USER_NFTS,
  SET_NFT_FAILURE,
  SET_NFT_LOADING,
  SET_USER_SELECTED_NFT_DETAILS,
} from "../types";

const initialState: INftState = {
  error: null,
  userNfts: [],
  isLoading: false,
  userSelectedNftDetails: null,
};

export const nftReducer = (state: INftState = initialState, action: NftsActionTypes): INftState => {
  switch (action.type) {
    case SET_USER_NFTS:
      return {
        ...state,
        isLoading: false,
        userNfts: action.payload,
      };

    case SET_USER_SELECTED_NFT_DETAILS:
      return {
        ...state,
        isLoading: false,
        userSelectedNftDetails: action.payload,
      };

    case SET_NFT_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };

    case SET_NFT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
