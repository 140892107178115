import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "header.title",
    defaultMessage: "UniqTogether",
  },
  redeem: {
    id: "header.redeem",
    defaultMessage: "MarketPlace",
  },
  collection: {
    id: "header.collection",
    defaultMessage: "Collection",
  },
  profile: {
    id: "header.profile",
    defaultMessage: "Profile",
  },
});

export default messages;
