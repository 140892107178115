import styled from "styled-components";

import { Button } from "@app/components/atoms";

export const Container = styled.div`
  display: flex;
  width: 7.3rem;
  height: 3.2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const GridButton = styled(Button).attrs({ variant: "tertiary" })`
  flex: 1;
  padding: 0;
  display: flex;
  height: inherit;
  align-items: center;
  border-radius: none;
  flex-direction: row;
  justify-content: center;
  border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR} !important;

  :hover,
  :active,
  :focus {
    background: none;
    border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR} !important;
  }

  svg {
    margin-right: 0;
  }
`;
