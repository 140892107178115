import styled from "styled-components";
import { Dropdown as BaseDropdown } from "react-bootstrap";

export {
  PriceTag,
  NFTButton,
  NFTFreeButton,
  NFTSellButton,
  NFTPriceButton,
  NFTTransferButton,
  NFTWithdrawButton,
} from "@app/components/molecules/NftRedeemCard/NftRedeemCard.styles";
import {
  HeaderBackTitle as RedeemHeaderBackTitle,
  HeaderBackButton as RedeemHeaderBackButton,
} from "@app/pages/redeem-overview/RedeemOverview.styles";
import { Button, Icon, Image } from "@app/components/atoms";

export const HeaderBackButton = styled(RedeemHeaderBackButton)``;

export const HeaderBackTitle = styled(RedeemHeaderBackTitle)``;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderContainer = styled(RowContainer)`
  justify-content: space-between;
`;

export const Dropdown = styled(BaseDropdown)`
  #dropdown-button {
    border-color: transparent;
    background-color: transparent;

    .dropdown-more-icon {
      background-color: white;
      width: 5px;
      height: 5px;
      margin: 3px;
      border-radius: 5px;
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;

    &hover {
      color: transparent;
      background-color: transparent;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: none;
  }
`;

export const NFTTitle = styled.h1`
  font-weight: 700;
  font-weight: bold;
  font-size: 3.4rem;
  margin-top: 0.7rem;
  line-height: 3.8rem;
  margin-bottom: 1.6rem;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.WHITE_COLOR};
`;

export const NFTUserName = styled(HeaderBackTitle)`
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-family: "Raleway";
  color: ${({ theme }) => theme.colors.WHITE_COLOR};
`;

export const NFTUserImage = styled(Image).attrs({
  wrapperProps: { style: { height: "100%" } },
})`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.colors.WHITE_COLOR};
`;

export const BottomContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    flex: none;
  }
`;

export const NFTButtonWrapper = styled.div`
  display: flex;
  margin-top: 7.2rem;
`;

export const VewMoreDetailButton = styled(Button).attrs({ variant: "tertiary" })`
  padding: 0;
  margin-top: 2.5rem;
  border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR} !important;

  :hover,
  :active,
  :focus {
    background: none;
    border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR} !important;
  }

  div {
    justify-content: space-between;
  }

  svg {
    margin: 0;
  }
`;

export const HRLine = styled.span`
  flex: 1;
  margin: 0rem 1.7rem;
  border: 1px solid ${({ theme }) => theme.colors.SPACE_DEEP_COLOR};
`;

export const DetailText = styled(NFTUserName)`
  margin: 0;
  text-align: left;
  font-weight: normal;
`;

export const DetailArrow = styled(Icon).attrs({
  name: "right-arrow",
  color: "SPACE_LIGHT_COLOR",
})<{ showdetails: string }>`
  width: 1.4rem;
  height: 1.4rem;
  transform: rotate(${({ showdetails }) => (showdetails === "true" ? 90 : 0)}deg);
`;
