import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Config } from "@app/configs";

/**
 * The stripePromise | An object that returns the stripeObject when its created.
 * It needs the PK for stripe
 * We need only to load stripe once at first time, in order not to create it every time!
 */
const stripePromise = loadStripe(String(Config.STRIPE_PUBLISHABLE_KEY));

/**
 * Component that renders the Stripe Provided Elements in the App.tsx level in order to be
 * accessible from everywhere stripe is needed in the APP
 * @param children | The children React Element
 */
const StripeProvider: React.FC<{}> = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);

export default StripeProvider;
