import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Redeem = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={colors[color || "SPACE_DARK_COLOR"]}
        d="M7.48039 1.2207C6.97229 1.2207 6.56039 1.6326 6.56039 2.1407V3.61106H5.39258C3.73572 3.61106 2.39258 4.9542 2.39258 6.61106V19.0669C2.39258 20.7237 3.73572 22.0669 5.39258 22.0669H19.4074C21.0643 22.0669 22.4074 20.7237 22.4074 19.0669V6.61106C22.4074 4.9542 21.0643 3.61106 19.4074 3.61106H18.2402V2.1407C18.2402 1.6326 17.8283 1.2207 17.3202 1.2207C16.8121 1.2207 16.4002 1.6326 16.4002 2.1407V3.61106H8.40039V2.1407C8.40039 1.6326 7.98849 1.2207 7.48039 1.2207ZM6.56039 5.61106V5.67143C6.56039 6.17954 6.97229 6.59143 7.48039 6.59143C7.98849 6.59143 8.40039 6.17953 8.40039 5.67143V5.61106H16.4002V5.67143C16.4002 6.17954 16.8121 6.59143 17.3202 6.59143C17.8283 6.59143 18.2402 6.17953 18.2402 5.67143V5.61106H19.4074C19.9597 5.61106 20.4074 6.05877 20.4074 6.61106V19.0669C20.4074 19.6191 19.9597 20.0669 19.4074 20.0669H5.39258C4.84029 20.0669 4.39258 19.6191 4.39258 19.0669V6.61106C4.39258 6.05877 4.84029 5.61106 5.39258 5.61106H6.56039ZM12.8217 8.75939C12.6253 8.4497 12.1735 8.4497 11.9771 8.75939L10.8127 10.596C10.7445 10.7035 10.6379 10.781 10.5146 10.8126L8.40807 11.3525C8.05286 11.4435 7.91323 11.8733 8.14709 12.1557L9.53395 13.8306C9.61516 13.9287 9.65589 14.0541 9.64784 14.1811L9.51035 16.3514C9.48716 16.7173 9.85273 16.9829 10.1936 16.8478L12.2151 16.0464C12.3335 15.9995 12.4653 15.9995 12.5837 16.0464L14.6052 16.8478C14.9461 16.9829 15.3117 16.7173 15.2885 16.3514L15.151 14.1811C15.1429 14.0541 15.1837 13.9287 15.2649 13.8306L16.6517 12.1557C16.8856 11.8733 16.746 11.4435 16.3908 11.3525L14.2842 10.8126C14.1609 10.781 14.0543 10.7035 13.9861 10.596L12.8217 8.75939Z"
      />
    </svg>
  );
};

export default Redeem;
