import styled from "styled-components";
import ReactLoadingSkeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SkeletonContainer = styled(SkeletonTheme).attrs(({ theme }) => ({
  baseColor: theme.colors.ONIX_SHADE_COLOR,
  highlightColor: theme.colors.SPACE_DEEP_COLOR,
}))``;

export const Skeleton = styled(ReactLoadingSkeleton)`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  backdrop-filter: blur(33px);
  box-shadow: 5px 3px 8px rgba(28, 30, 45, 0.44), inset 0px 0px 3px rgba(50, 59, 92, 0.47),
    inset 0px 0px 6px rgba(137, 148, 196, 0.1);
`;

export const LoadingContainer = styled.div`
  position: sticky !important;
`;
