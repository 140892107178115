import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { Config } from "@app/configs";
import { getSettingsLocal } from "@app/providers/store/settings";
import { loginWithFacebookAction } from "@app/providers/store/session";
import { reportError } from "@app/helpers";

import { FacebookButton } from "./Facebook.style";

/**
 * Component that renders the Facebook Login button
 * in signup {@see Signup}
 * along with facebook login functionality
 */
const Facebook: React.FC = () => {
  const dispatch = useDispatch();
  const locale = useSelector(getSettingsLocal);

  /**
   * Helper function that is called with google
   * redirect to complete the login with Google request
   */
  const loginWithFacebookRedirect = useCallback(() => {
    try {
      const url = window.location.href;
      //eslint-disable-next-line
      let access_token: any = url.match(/\#(?:access_token)\=([\S\s]*?)\&/);

      if (access_token && access_token.length > 0) {
        access_token = access_token[1];
      }

      if (access_token) {
        dispatch(loginWithFacebookAction({ access_token: access_token, language: locale }));
      }
    } catch (error) {
      reportError(error as Error);
    }
  }, [dispatch, locale]);

  /**
   * Use effect that runs the login with google redirect in
   * the signup screen every time the component mounts
   */
  React.useEffect(() => {
    loginWithFacebookRedirect();
  }, [loginWithFacebookRedirect]);

  const facebookCallback = (response: Record<string, never>) => {
    dispatch(loginWithFacebookAction({ access_token: response.accessToken, language: locale }));
  };

  return (
    <FacebookLogin
      autoLoad={false}
      responseType="token"
      fields="name,email,picture"
      scope="public_profile email"
      callback={facebookCallback}
      appId={String(Config.FACEBOOK_ID)}
      redirectUri={`${Config.FRONTEND_URL}/signup`}
      render={(props: any) => <FacebookButton {...props} />}
    />
  );
};

export default Facebook;
