/**
 * extending app global constants
 * @constant APP_URL_LIST default value
 */
export enum APP_URL_LIST {
  BASE_URL = "/",
  NOT_FOUND_URL = "*",
  BUY_NFTS = "/buynfts",
  REDEEM_URL = "/redeem",
  SIGNUP_URL = "/signup",
  PROFILE_URL = "/profile",
  DOWNLOAD_APP_URL = "/download",
  PRIVACY_POLICY_URL = "/policy",
  COLLECTION_URL = "/collections",
  TERMS_AND_CONDITION_URL = "/terms",
}

export const PUBLIC_ROUTES = [
  APP_URL_LIST.SIGNUP_URL,
  APP_URL_LIST.NOT_FOUND_URL,
  APP_URL_LIST.PRIVACY_POLICY_URL,
  APP_URL_LIST.TERMS_AND_CONDITION_URL,
];

export const PRIVATE_ROUTES = [
  APP_URL_LIST.BUY_NFTS,
  APP_URL_LIST.PROFILE_URL,
  APP_URL_LIST.COLLECTION_URL,
];

export const PUBLIC_AND_PRIVATE_ROUTES = [APP_URL_LIST.REDEEM_URL, APP_URL_LIST.DOWNLOAD_APP_URL];
