import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "error_fallback.title",
    defaultMessage: "Something went wrong",
  },
  click_to_reset: {
    id: "error_fallback.click_to_reset",
    defaultMessage: "Click here to reset!",
  },
});

export default messages;
