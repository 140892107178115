import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  getUserRedeemNftsAction,
  getUserSelectedRedeemNftDetails,
  setUserSelectedRedeemNftDetailsAction,
} from "@app/providers/store/redeem";
import messages from "./messages";

import { Container, Title, NFTNumber, Description, Collection } from "./RedeemNFTTitle.style";

const RedeemNFTTitleInfo: React.FC = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const { state } = useLocation() as CTLocation;
  const [description, setDescription] = useState("");
  const redeem = useSelector(getUserSelectedRedeemNftDetails, shallowEqual);

  const [, month, day] = new Date(String(redeem?.startDate)).toDateString().split(" ");

  const collection = redeem?.collections?.find(
    (col) => Number(col.collectionId) === Number(collectionId)
  );

  const numberOfNfts = collection?.numberOfNfts || 0;
  const readMore =
    description.length <= 155 && `${collection?.nftCategoryDescription}`?.length > 150;

  useEffect(() => {
    if (!description && collection?.nftCategoryDescription) {
      setDescription(
        `${collection?.nftCategoryDescription}`?.length > 150
          ? `${collection?.nftCategoryDescription.substring(0, 150)}...`
          : collection?.nftCategoryDescription
      );
    }
  }, [collection, description]);

  const handleShowMore = () => {
    setDescription(
      readMore
        ? `${collection?.nftCategoryDescription}`
        : `${collection?.nftCategoryDescription?.substring(0, 150)}...`
    );
  };

  useEffect(() => {
    if (!redeem) {
      dispatch(getUserRedeemNftsAction());
    }
  }, [dispatch, redeem]);

  useEffect(() => {
    if (Number(collectionId) !== Number(collection?.collectionId)) {
      dispatch(setUserSelectedRedeemNftDetailsAction(null));
    }
  }, [dispatch, collection, collectionId]);

  return Number(collectionId) === Number(collection?.collectionId) ? (
    <Container>
      {state?.isRedeem ? (
        <Fragment>
          <Title>{collection?.collectionName}</Title>
          <NFTNumber>
            <FormattedMessage {...messages.number_of_nfts} values={{ numberOfNfts }} />
          </NFTNumber>

          <Collection>
            {redeem?.eventName}
            {redeem?.startDate && ` - ${day} ${month}`}
          </Collection>

          <Description>
            {description}

            {`${collection?.nftCategoryDescription}`?.length > 150 ? (
              <Description color="CARDINAL_TEAL_COLOR" onClick={handleShowMore}>
                <FormattedMessage {...messages[readMore ? "readMore" : "readLess"]} />
              </Description>
            ) : null}
          </Description>
        </Fragment>
      ) : (
        <Fragment>
          <Title>
            <FormattedMessage {...messages.collection} />
          </Title>
          <Description>
            <FormattedMessage {...messages.collectionPromo} />
          </Description>
        </Fragment>
      )}
    </Container>
  ) : null;
};

export default RedeemNFTTitleInfo;
