import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

const Error = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="33"
      height="25"
      fill="none"
      viewBox="0 0 33 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_b_502:41229)">
        <path
          d="M11.4769 12.2865L13.2423 12.2723C13.2499 12.2723 13.2575 12.2723 13.2651 12.2723C13.9181 12.2723 14.2446 12.6563 14.2446 13.4243V17.1362C13.3562 17.544 12.3729 17.7478 11.2946 17.7478C10.2164 17.7478 9.4039 17.2927 8.85718 16.3825C8.31047 15.4628 8.03711 14.216 8.03711 12.642C8.03711 11.0586 8.37501 9.83079 9.05081 8.9585C9.73421 8.07672 10.5657 7.63583 11.5452 7.63583C12.5323 7.63583 13.4245 7.98191 14.2218 8.67405C14.1991 8.93005 14.1193 9.18131 13.9826 9.42783C13.8536 9.67435 13.7093 9.86398 13.5498 9.99672C12.912 9.43731 12.2628 9.15761 11.6022 9.15761C10.9491 9.15761 10.4404 9.45153 10.0759 10.0394C9.71143 10.6178 9.52919 11.528 9.52919 12.77C9.52919 15.112 10.2202 16.2829 11.6022 16.2829C12.0046 16.2829 12.426 16.2213 12.8664 16.098V14.7469C12.8664 14.3203 12.874 14.0074 12.8892 13.8083H12.0578C11.8224 13.8083 11.6477 13.7514 11.5338 13.6376C11.4275 13.5143 11.3744 13.3247 11.3744 13.0687C11.3744 12.8032 11.4085 12.5425 11.4769 12.2865Z"
          fill={colors[color || "SPACE_PURE_COLOR"]}
        />
        <path
          d="M15.9239 17.5487V7.83494C16.1062 7.78754 16.3453 7.76383 16.6415 7.76383C16.9376 7.76383 17.1806 7.78754 17.3704 7.83494V17.5487C17.1806 17.5961 16.9376 17.6198 16.6415 17.6198C16.3453 17.6198 16.1062 17.5961 15.9239 17.5487Z"
          fill={colors[color || "SPACE_PURE_COLOR"]}
        />
        <path
          d="M20.773 9.37094L20.7844 10.8216V11.9309H23.4269C23.4724 12.1585 23.4952 12.4097 23.4952 12.6847C23.4952 12.9502 23.4724 13.1967 23.4269 13.4243H20.7844V17.5487C20.587 17.5961 20.344 17.6198 20.0554 17.6198C19.7745 17.6198 19.5353 17.5961 19.3379 17.5487V7.92028L19.4176 7.83494H24.0647C24.1102 8.03405 24.133 8.27109 24.133 8.54605C24.133 9.10546 23.8673 9.38516 23.3357 9.38516H21.7639C21.3007 9.38516 20.9704 9.38042 20.773 9.37094Z"
          fill={colors[color || "SPACE_PURE_COLOR"]}
        />
        <rect
          x="1.00594"
          y="1.30361"
          width="30.16"
          rx="11.3882"
          height="22.7764"
          strokeWidth="1.84"
          stroke={colors[color || "SPACE_PURE_COLOR"]}
        />
      </g>
      <defs>
        <filter
          width="82"
          x="-24.9141"
          y="-24.6164"
          height="74.6164"
          id="filter0_b_502:41229"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="12.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_502:41229" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_502:41229"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Error;
