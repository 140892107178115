import ReactPlayer from "react-player";
import { useSelector, shallowEqual } from "react-redux";

import { Icon } from "@app/components/atoms";
import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import { VideoModalProps } from "./VideoModal.interface";

import { Container, ZoomOutContainer } from "./VideoModal.style";

const VideoModal: React.FC<VideoModalProps> = ({ setShowVideoScreen }) => {
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);

  return (
    <Container>
      <div className="player-wrapper">
        <ReactPlayer
          width="100%"
          height="100%"
          playing={true}
          controls={true}
          url={userSelectedNftDetails?.nftAsset}
        />
      </div>

      <ZoomOutContainer onClick={setShowVideoScreen}>
        <Icon name="close" />
      </ZoomOutContainer>
    </Container>
  );
};

export default VideoModal;
