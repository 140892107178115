/**
 * Constant that denotes the Base auth url for authentication route
 */
export const AUTH_URL = "/register/api/v1/auth";

/**
 * Constant that denotes the url to login with google redirect endpoint
 */
export const LOGIN_GOOGLE_URL = `${AUTH_URL}/googleRedirect`;

/**
 * URL to login with Facebook
 */
export const LOGIN_FACEBOOK_URL = `${AUTH_URL}/facebook`;

/**
 * URL to login with apple
 */
export const LOGIN_APPLE_URL = `${AUTH_URL}/appleWeb`;

/**
 * URL to logout
 */
export const LOGOUT_A_USER_URL = "/logout";
