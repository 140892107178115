import { defineMessages } from "react-intl";

const messages = defineMessages({
  buy_now: {
    id: "nft_redeem_card.buy_now",
    defaultMessage: "Buy Now",
  },
  free: {
    id: "nft_redeem_card.free",
    defaultMessage: "Free",
  },
  price: {
    id: "nft_redeem_card.price",
    defaultMessage: "€{priceValue}",
  },
  withdraw: {
    id: "nft_redeem_card.withdraw",
    defaultMessage: "Withdraw",
  },
  transfer: {
    id: "nft_redeem_card.transfer",
    defaultMessage: "Transfer",
  },
  sell: {
    id: "nft_redeem_card.sell",
    defaultMessage: "Sell",
  },
  add_to_collection: {
    id: "nft_redeem_card.add_to_collection",
    defaultMessage: "Add to Collection",
  },
  modal_primary_label: {
    id: "nft_redeem_card.modal_primary_label",
    defaultMessage: "Redeem More",
  },
  modal_bottomLink_label: {
    id: "nft_redeem_card.modal_bottomLink_label",
    defaultMessage: "See My Collection",
  },
  modal_title: {
    id: "nft_redeem_card.modal_title",
    defaultMessage: "You’ve successfully collected your NFT.",
  },
  not_white_listed: {
    id: "nft_redeem_card.not_white_listed",
    defaultMessage: "You're not in the whitelist for this category!",
  },
});

export default messages;
