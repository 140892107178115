import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session"; // defaults to sessionStorage for web
import { composeWithDevTools } from "@redux-devtools/extension";
import { Middleware, createStore, compose, combineReducers, applyMiddleware } from "redux";

import { CACHE_VERSION, __ROOT_REDUX_STATE_KEY__ } from "@app/constants";
import { nft, __NFT__REDUX__STATE_KEY__ } from "./nft";
import { redeem, __REDEEM__REDUX__STATE_KEY__ } from "./redeem";
import { session, __SESSION__REDUX__STATE_KEY__ } from "./session";
import { settings, __SETTINGS__REDUX__STATE_KEY__ } from "./settings";
import { genericModal, __GENRIC_MODAL__REDUX__STATE_KEY__ } from "./genericModal";

const rootPersistConfig = {
  storage,
  timeout: 10000,
  version: CACHE_VERSION,
  key: __ROOT_REDUX_STATE_KEY__,
  blacklist: [__SESSION__REDUX__STATE_KEY__, __GENRIC_MODAL__REDUX__STATE_KEY__],
  whitelist: [
    __NFT__REDUX__STATE_KEY__,
    __REDEEM__REDUX__STATE_KEY__,
    __SETTINGS__REDUX__STATE_KEY__,
  ],
};

const sessionPersistConfig = {
  whitelist: ["data"],
  storage: sessionStorage,
  key: __SESSION__REDUX__STATE_KEY__,
};

const appReducers = combineReducers({
  [__NFT__REDUX__STATE_KEY__]: nft,
  [__REDEEM__REDUX__STATE_KEY__]: redeem,
  [__SETTINGS__REDUX__STATE_KEY__]: settings,
  [__GENRIC_MODAL__REDUX__STATE_KEY__]: genericModal,
  [__SESSION__REDUX__STATE_KEY__]: persistReducer(sessionPersistConfig, session),
});

export const reducers = persistReducer(rootPersistConfig, appReducers);

const middlewares: Middleware[] = [thunk]; // create the saga middleware

const composable = () => {
  const state = [composeWithDevTools(applyMiddleware(...middlewares))];

  if (process.env.NODE_ENV === "development") {
    const { name } = require("../../../package.json");
    const { reactotronRedux } = require("reactotron-redux");
    const Reactotron = require("reactotron-react-js").default;

    Reactotron.configure({ name }) // <- controls connection & communication settings
      .use(reactotronRedux()) // <- Listen to redux store updates
      .connect(); //  <- let's connect!

    // <- Extend app console
    console.tron = Reactotron.log;

    state.push(Reactotron.createEnhancer());
    state.push(Sentry.createReduxEnhancer());
  }

  return state;
};

const store = createStore(reducers, compose(...composable()));
const persistor = persistStore(store);

export { store, persistor };
