import { FormattedMessage } from "react-intl";
import { FallbackRender } from "@sentry/react/dist/errorboundary";

import messages from "./messages";
import { Button } from "../Button";

import { Container, ErrorText } from "./ErrorFallback.styles";

export const ErrorFallback: FallbackRender = ({ resetError }) => {
  return (
    <Container>
      <ErrorText>
        <FormattedMessage {...messages.title} />
      </ErrorText>
      <Button onClick={resetError}>
        <FormattedMessage {...messages.click_to_reset} />
      </Button>
    </Container>
  );
};
