import { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Icon } from "@app/components/atoms";
import { APP_URL_LIST } from "@app/routes/urls";
import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import { BottomDetails, TopOverlay } from "@app/pages/nft-detail/components";
import { getUserSelectedRedeemNftDetails } from "@app/providers/store/redeem";

import messages from "./messages";

import {
  Title,
  Container,
  ContentWrapper,
  HeaderBackTitle,
  HeaderBackButton,
} from "./SideDrawer.styles";

export const SideDrawer: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { collectionId, nftId } = useParams();
  const { state, pathname } = useLocation() as CTLocation;
  const [showDetails, setShowDetails] = useState<boolean>(true); // initial state false
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);
  const userSelectedRedeemNftDetails = useSelector(getUserSelectedRedeemNftDetails, shallowEqual);

  const isNftDetailScreen = Boolean(nftId && userSelectedNftDetails);

  const isRedeemCollectionScreen = Boolean(
    !isNftDetailScreen && collectionId && userSelectedRedeemNftDetails
  );

  return (
    <Container isNftDetailScreen={isNftDetailScreen} className="side__drawer__container">
      <ContentWrapper>
        {userSelectedRedeemNftDetails || userSelectedNftDetails ? (
          <HeaderBackButton
            to={APP_URL_LIST.REDEEM_URL}
            onClick={(event) => {
              // stop the browser from changing the URL
              event.preventDefault();
              // navigate a step back to previous screen
              const routeParams = pathname.split("/");
              const previousRoutePaths = routeParams.splice(0, routeParams.length - 1);
              const previousRoute = previousRoutePaths.join("/");
              navigate(previousRoute);
            }}
          >
            <Icon name="go-back" />
            <HeaderBackTitle>
              {isNftDetailScreen && <FormattedMessage {...messages.go_back_to_list} />}
              {isRedeemCollectionScreen && <FormattedMessage {...messages.go_back_to_redeem} />}
            </HeaderBackTitle>
          </HeaderBackButton>
        ) : null}

        {!isNftDetailScreen && (
          <Title>
            {userSelectedRedeemNftDetails?.eventName || (
              <FormattedMessage {...messages[state?.isCollection ? "collection" : "redeem"]} />
            )}
          </Title>
        )}

        {isNftDetailScreen ? (
          <Fragment>
            <TopOverlay
              showDetails={showDetails}
              setShowDetails={() => setShowDetails(!showDetails)}
            />
            {showDetails && <BottomDetails showOnDesktop={true} />}
          </Fragment>
        ) : null}
      </ContentWrapper>
    </Container>
  );
};
