import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";

import { formatDate as formatNFTCreatedDate } from "@app/helpers";
import { getUserSelectedNftDetails } from "@app/providers/store/nft";
import messages from "./messages";

import {
  HRLine,
  Container,
  Description,
  NFTUserImage,
  RowContainer,
  DetailRowText,
  DetailRowLabel,
  CopyEventContainer,
  DetailRowContainer,
} from "./BottomDetails.style";

const BottomDetails: React.FC<{ showOnDesktop?: boolean }> = (props) => {
  const { formatMessage } = useIntl();
  const { state } = useLocation() as CTLocation;

  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);

  const onCopy = () => {
    navigator.clipboard.writeText(userSelectedNftDetails?.tokenId || "");
    toast(formatMessage(messages.token_id_copied));
  };

  return (
    <Container {...props}>
      <Description>{userSelectedNftDetails?.nftDescription}</Description>

      <DetailRowContainer>
        <DetailRowLabel>
          <FormattedMessage {...messages.token_id} />
        </DetailRowLabel>
        <CopyEventContainer onClick={onCopy}>
          <DetailRowText>
            <FormattedMessage {...messages.copy} />
          </DetailRowText>
        </CopyEventContainer>
      </DetailRowContainer>
      <HRLine />

      <DetailRowContainer>
        <DetailRowLabel>
          <FormattedMessage {...messages.event} />
        </DetailRowLabel>
        <DetailRowText color="WHITE_COLOR">{userSelectedNftDetails?.eventName}</DetailRowText>
      </DetailRowContainer>
      <HRLine />

      <DetailRowContainer>
        <DetailRowLabel>
          <FormattedMessage {...messages.date_received} />
        </DetailRowLabel>
        <DetailRowText color="WHITE_COLOR">
          {formatNFTCreatedDate(userSelectedNftDetails?.dateReceived.toString())}
        </DetailRowText>
      </DetailRowContainer>
      <HRLine />

      <DetailRowContainer>
        <DetailRowLabel>
          <FormattedMessage {...messages.organizer} />
        </DetailRowLabel>

        <RowContainer>
          <NFTUserImage
            alt="Event organizer picture"
            srcList={[
              String(userSelectedNftDetails?.eventOrganizerImage),
              "/assets/images/default-user-pic.png",
            ]}
          />
          <DetailRowText color="WHITE_COLOR">
            {userSelectedNftDetails?.eventOrganizer}
          </DetailRowText>
        </RowContainer>
      </DetailRowContainer>
      <HRLine />

      <DetailRowContainer>
        <DetailRowLabel>
          <FormattedMessage {...messages.creator} />
        </DetailRowLabel>

        <RowContainer>
          <NFTUserImage
            alt="NFT creator picture"
            srcList={[
              String(userSelectedNftDetails?.nftCreatorImage),
              "/assets/images/default-user-pic.png",
            ]}
          />
          <DetailRowText color="WHITE_COLOR">{userSelectedNftDetails?.nftCreator}</DetailRowText>
        </RowContainer>
      </DetailRowContainer>
      {state?.isRedeem &&
        userSelectedNftDetails?.preOwnerImage &&
        userSelectedNftDetails?.preOwner && (
          <Fragment>
            <HRLine />
            <DetailRowContainer>
              <DetailRowLabel>
                <FormattedMessage {...messages.preOwner} />
              </DetailRowLabel>

              <RowContainer>
                <NFTUserImage
                  alt="NFT pre-user picture"
                  srcList={[
                    `https://${userSelectedNftDetails?.preOwnerImage}`,
                    "/assets/images/default-user-pic.png",
                  ]}
                />
                <DetailRowText color="WHITE_COLOR">
                  {userSelectedNftDetails?.preOwner}
                </DetailRowText>
              </RowContainer>
            </DetailRowContainer>
          </Fragment>
        )}
    </Container>
  );
};

export default BottomDetails;
