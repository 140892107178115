import { defineMessages } from "react-intl";

const messages = defineMessages({
  accept: {
    id: "cookies.accept",
    defaultMessage: "Accept",
  },
  title: {
    id: "cookies.title",
    defaultMessage: "We use cookies",
  },
  description: {
    id: "cookies.description",
    defaultMessage: `This website uses cookies to ensure you get the best experinces on our website`,
  },
});

export default messages;
