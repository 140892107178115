import { IRedeemState, RedeemActionTypes } from "../models";
import {
  SET_REDEEM_LOADING,
  SET_REDEEM_COLLECTION,
  SET_REDEEM_COLLECTION_NFTS,
  RESET_REDEEM_COLLECTION_NFTS,
  SET_USER_SELECTED_REDEEM_NFT_DETAILS,
} from "../types";

const initialState: IRedeemState = {
  error: null,
  redeemNFTs: [],
  isLoading: false,
  collectionNFTs: [],
  userSelectedRedeemNFTDetails: null,
};

export const redeemReducer = (
  state: IRedeemState = initialState,
  action: RedeemActionTypes
): IRedeemState => {
  switch (action.type) {
    case SET_REDEEM_COLLECTION:
      return {
        ...state,
        isLoading: false,
        redeemNFTs: action.payload,
      };

    case SET_REDEEM_COLLECTION_NFTS:
      return {
        ...state,
        collectionNFTs: action.payload,
      };

    case RESET_REDEEM_COLLECTION_NFTS:
      return {
        ...state,
        collectionNFTs: [],
      };

    case SET_USER_SELECTED_REDEEM_NFT_DETAILS:
      return {
        ...state,
        isLoading: false,
        userSelectedRedeemNFTDetails: action.payload,
      };

    case SET_REDEEM_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    default:
      return state;
  }
};
