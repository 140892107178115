import styled from "styled-components";

import { CopyButton } from "../Account/Account.style";
import {
  Divider as UserInformationDivider,
  Title as UserInformationTitle,
} from "../UserInformation/UserInformation.style";

export { SubTitle, Container } from "../Account/Account.style";

export const Title = styled(UserInformationTitle)`
  margin-bottom: 1rem;
`;

export const LogoutButton = styled(CopyButton)`
  padding: 0;
  width: 100%;
  height: 4rem;
  color: ${({ theme, color }) => theme.colors[color || "CARDINAL_TEAL_COLOR"]};

  div {
    justify-content: flex-start;
  }
`;

export const Divider = styled(UserInformationDivider)`
  margin: 1.2rem 0;
`;
