export enum IColorMode {
  DARK = "dark",
  LIGHT = "light",
}

export enum Locale {
  GREEK = "el",
  ENGLISH = "en",
}

export interface ISettingsState {
  locale: Locale;
  colorMode: IColorMode;
  isNftDetailImageOverviewInstructionDone: boolean;
}
