import { useEffect, FC } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CookiesProvider as ReactCookiesProvider, useCookies } from "react-cookie";

import { IStateTree } from "typings/store";
import { openGenericModalAction } from "@app/providers/store/genericModal";
import messages from "./messages";

const CookiesProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [cookies, setCookie] = useCookies(["user_auth_cookie"]);
  const { data } = useSelector(({ session }: IStateTree) => session);

  useEffect(() => {
    if (!cookies?.user_auth_cookie) {
      dispatch(
        openGenericModalAction({
          icon: "🍪",
          size: "lg",
          isCookies: true,
          isTransparent: false,
          title: formatMessage(messages.title),
          primaryLabel: formatMessage(messages.accept),
          description: formatMessage(messages.description),
          primaryOnPress: () =>
            setCookie("user_auth_cookie", {
              jwtToken: data?.jwtToken,
              refreshToken: data?.refreshToken,
            }),
        })
      );
    }
  }, [cookies, data?.jwtToken, data?.refreshToken, dispatch, formatMessage, setCookie]);

  return <ReactCookiesProvider>{children}</ReactCookiesProvider>;
};

export default CookiesProvider;
