import React from "react";
import { ImgProps, useImage } from "react-image";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";

import { Skeleton, LoadingContainer, SkeletonContainer } from "./Image.styles";

type ImageProps = Pick<ImgProps, "container"> & LazyLoadImageProps & { srcList: ImgProps["src"] };

const passthroughContainer = (x: React.ReactElement) => x;

export const Image: React.FC<ImageProps> = React.memo(
  ({ srcList, container = passthroughContainer, ...props }) => {
    const { src, isLoading } = useImage({ srcList, useSuspense: false });

    return isLoading ? (
      <LoadingContainer {...(props as unknown)}>
        <SkeletonContainer>
          <Skeleton count={1} containerClassName="skeleton__holder" />
        </SkeletonContainer>
      </LoadingContainer>
    ) : (
      container(
        <LazyLoadImage
          effect="blur"
          wrapperProps={{
            style: {
              width: "100%",
              height: "100%",
            },
          }}
          {...props}
          src={src}
        />
      )
    );
  }
);
