import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  getUserRedeemNfts,
  getRedeemCollectionNfts,
  getUserRedeemNftsAction,
  resetRedeemCollectionAction,
  getUserSelectedRedeemNftDetails,
  setUserSelectedRedeemNftDetailsAction,
} from "@app/providers/store/redeem";
import { NftCollection } from "@app/components/organisms";
import {
  getUserSelectedNftDetails,
  setUserSelectedNftDetailsAction,
} from "@app/providers/store/nft";
import messages from "./messages";

import {
  Title,
  Skeleton,
  Container,
  CollectionWrapper,
  SkeletonContainer,
  CollectionContentLoader,
} from "./Redeem.styles";
import ReactGA from "react-ga";
import { APP_URL_LIST } from "@app/routes/urls";

const Redeem: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const { collectionNFTs } = useSelector(getRedeemCollectionNfts, shallowEqual);
  const { redeemNFTs, isLoading } = useSelector(getUserRedeemNfts, shallowEqual);
  const { userSelectedNftDetails } = useSelector(getUserSelectedNftDetails, shallowEqual);
  const userSelectedRedeemNftDetails = useSelector(getUserSelectedRedeemNftDetails, shallowEqual);

  React.useEffect(() => {
    ReactGA.pageview(`${APP_URL_LIST.REDEEM_URL}`);
  }, []);

  useEffect(() => {
    dispatch(getUserRedeemNftsAction());
  }, [dispatch]);

  useEffect(() => {
    // THIS HELPS EMPTY THE LSIT SO IT DOESN"T CAUSE FLICKERING ON RENDER FROM THE PREVOIUS COLLECTION ON THE STORE

    if (collectionNFTs.length) {
      dispatch(resetRedeemCollectionAction());
    }

    if (userSelectedRedeemNftDetails) {
      dispatch(setUserSelectedRedeemNftDetailsAction(null));
    }

    if (userSelectedNftDetails) {
      dispatch(setUserSelectedNftDetailsAction(null));
    }
  }, [collectionNFTs.length, dispatch, userSelectedNftDetails, userSelectedRedeemNftDetails]);

  return (
    <Container>
      <Title>
        <FormattedMessage {...messages.title} />
      </Title>

      <CollectionWrapper>
        {!redeemNFTs.length && isLoading ? (
          <CollectionContentLoader>
            <SkeletonContainer>
              <Skeleton count={2} containerClassName="skeleton__holder" />
              <Skeleton count={2} containerClassName="skeleton__holder" />
            </SkeletonContainer>
          </CollectionContentLoader>
        ) : null}

        {redeemNFTs?.map((redeemNFT) => (
          <NftCollection key={redeemNFT?.eventId} {...redeemNFT} />
        ))}
      </CollectionWrapper>
    </Container>
  );
};

export default Redeem;
