import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-h5-audio-player/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-lazy-load-image-component/src/effects/blur.css";

// Read app environment configs before initalizing application
import { Config } from "./configs";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";

Sentry.init({
  dsn: Config.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [new BrowserTracing()],
  enabled: process.env.NODE_ENV === "production",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

if (Config.GOOGLE_GA) {
  ReactGA.initialize(Config.GOOGLE_GA, {
    debug: process.env.NODE_ENV === "development",
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
