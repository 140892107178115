import { css, createGlobalStyle } from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Compagnon from "./fonts/Compagnon.otf";

// prettier-ignore
export const reset = css`
/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  border: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
  line-height: 1;
}
menu, ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
`

export const customScrollBars = css`
  /* Customize website's scrollbar like Mac OS Not supports in Firefox and IE */

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    /* width */
    *::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    *::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* Handle */
    *::-webkit-scrollbar-thumb {
      border-radius: 3px;
      border: 3px solid transparent;
      background-color: ${({ theme }) => theme.colors.BUTTON_ALTERED_COLOR};
    }

    /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
      background-color: rgba(133, 122, 255, 0.47);
    }
  }
`;

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${customScrollBars}

    @font-face {
      font-family: Compagnon;
      src: url(${Compagnon});
      font-style: normal;
      font-weight: 400;
    }


  html, body, #root{
    /* Set the width & height fallback for older browsers. */
    width: 100%;
    height: 100%;
    /* Set the width & height to match that of the viewport. */
    width: 100vw;
    height: 100vh;
    font-size: 62.5%; // 1rem = 10px
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: ${({ theme }) => theme.colors.ONIX_DEEP_COLOR};
    color: ${({ theme }) => theme.colors.METALLIC_PEARL_WHITE_COLOR};
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    input:focus,
    select:focus,
    button:focus,
    textarea:focus,
    input[type=button]:focus,
    input.form-control:focus {
      box-shadow: none;
      -moz-box-shadow: none;
      outline:none !important;
      -webkit-box-shadow: none;
      outline-width: 0 !important;
      color: ${({ theme }) => theme.colors.METALLIC_PEARL_WHITE_COLOR};
    }

    /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: ${({ theme }) => theme.colors.METALLIC_PEARL_WHITE_COLOR};
      -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.TRANSPARENT_COLOR} inset;
    }
  }

    .toast_container {
      font-size: 1.5rem;
    }

    .Toastify__toast-theme--dark {
      background: ${({ theme }) => theme.colors.ONIX_DEEP_COLOR};
    }

`;

export default GlobalStyle;
