import {
  SET_LOCALE,
  SET_COLOR_MODE,
  SET_IS_NFT_DETAIL_IMAGE_OVERVIEW_INSTRUCTION_DONE,
} from "../types";
import { IColorMode, ISettingsState, SettingsActionTypes, Locale } from "../models";

const initialState: ISettingsState = {
  colorMode: IColorMode.LIGHT,
  isNftDetailImageOverviewInstructionDone: false,
  locale: (window?.navigator?.language.trim().split(/-|_/)[0] || Locale.ENGLISH) as Locale,
};

const settingReducer = (
  state: ISettingsState = initialState,
  action: SettingsActionTypes
): ISettingsState => {
  switch (action.type) {
    case SET_COLOR_MODE:
      return { ...state, colorMode: action.payload };

    case SET_LOCALE:
      return { ...state, locale: action.payload };

    case SET_IS_NFT_DETAIL_IMAGE_OVERVIEW_INSTRUCTION_DONE:
      return { ...state, isNftDetailImageOverviewInstructionDone: true };

    default:
      return state;
  }
};

export default settingReducer;
