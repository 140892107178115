import { ResponseInterface } from "typings";
import { DEFAULT_PAGINATION } from "@app/constants";
import { IGetSelectedNftDetailsParamsType, IUserNftType } from "@app/providers/store/nft/models";
import { IGetSelectedRedeemNftDetailsParamsType } from "@app/providers/store/redeem/models";
import { GET_USER_NFTS_URL, GET_USER_SELECTED_USER_NFT_DETAIL_URL } from "@app/network/urls/nft";
import { network } from "./api";

/**
 *
 * @description get all cometogether user bought nfts collections
 * @function getUserNftsApi
 * @returns Promise<ResponseInterface<IUserNftType[]>
 */
export const getUserNftsApi = async (
  payload: Omit<IGetSelectedRedeemNftDetailsParamsType, "nftCategoryId">
): Promise<ResponseInterface<IUserNftType[]>> => {
  const { data } = await network.get<ResponseInterface<IUserNftType[]>>(GET_USER_NFTS_URL, {
    params: {
      limit: DEFAULT_PAGINATION.LIMIT,
      offset: DEFAULT_PAGINATION.OFF_SET,
      ...payload,
    },
  });

  return data;
};

/**
 *
 * @description add user nft to a cometogether user nft wallet collections
 * @function getUserSelectedUserNftDetails
 * @param IGetSelectedNftDetailsParamsType
 * @returns Promise<ResponseInterface<IUserNftType>
 */
export const getUserSelectedUserNftDetails = async (
  payload: IGetSelectedNftDetailsParamsType
): Promise<ResponseInterface<IUserNftType>> => {
  const { data } = await network.get<ResponseInterface<IUserNftType>>(
    GET_USER_SELECTED_USER_NFT_DETAIL_URL,
    { params: payload }
  );

  return data;
};
