import styled from "styled-components";

export const PriceSectionContainer = styled.div`
  flex-direction: column;
  margin-top: 3.2rem;
  width: 100%;
`;

export const SellingPriceTitle = styled.p`
  font-family: Raleway;
  width: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};
  letter-spacing: -0.02em;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.9rem;
  }
`;

export const ServiceFeeTitle = styled.p`
  font-family: "Raleway";
  font-style: normal;
  width: auto;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.SPACE_DARK_COLOR};
  letter-spacing: -0.02em;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`;

export const ServiceFeesValue = styled.p`
  font-family: Signika Negative;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.SPACE_DARK_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
`;

export const SellingPriceValue = styled.p`
  font-family: Signika Negative;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: right;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 1.8rem;
    line-height: 118.9%;
  }
`;

export const PriceDetailsWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
`;

export const GrandTotalTitle = styled.p`
  margin-top: 0.7rem;
  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1.9rem;

  color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 600;
    letter-spacing: -0.00642857px;
  }
`;

export const GrandTotalValue = styled.p`
  font-family: Signika Negative;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 118.9%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.SPACE_PURE_COLOR};
  margin-bottom: 4.2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    /* identical to box height */

    letter-spacing: -0.00642857px;
  }
`;

export const Divider = styled.div`
  opacity: 0.1;
  margin: 2rem 0;
  border: 1px solid ${({ theme }) => theme.colors.IRIS_DARK_COLOR};
`;
