import { IStateTree } from "typings/store";

import { __NFT__REDUX__STATE_KEY__ } from "../types";

export const getReduxStateSlice = (state: IStateTree) => state[__NFT__REDUX__STATE_KEY__];

export const getUserNfts = (
  state: IStateTree
): Omit<IStateTree["nft"], "userSelectedNftDetails"> => {
  const { userNfts, isLoading, error } = getReduxStateSlice(state);
  return { userNfts, isLoading, error };
};

export const getUserSelectedNftDetails = (
  state: IStateTree
): Omit<IStateTree["nft"], "userNfts"> => {
  const { userSelectedNftDetails, isLoading, error } = getReduxStateSlice(state);
  return { userSelectedNftDetails, isLoading, error };
};
