import styled, { DefaultTheme } from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 8.829rem;
`;

export const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  font-family: Philosopher;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    font-size: 2.4rem;
    line-height: 2.7rem;
  }
`;

export const CollectionDate = styled(Title)`
  font-weight: 600;
  font-size: 1.6rem;
  margin-right: 0.8rem;
  font-family: Signika Negative;
  letter-spacing: -0.00642857px;

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const CollectionNumber = styled(CollectionDate)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.SPACE_DARK_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
`;

export const CollectionContent = styled.div`
  display: flex;
  overflow-x: scroll;
  padding-bottom: 2rem;
`;

export const Description = styled.span<{ color?: keyof DefaultTheme["colors"] }>`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 3rem;
  padding-right: 3rem;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  margin-left: ${({ color }) => (color ? 0.5 : 0)}rem;
  color: ${({ theme, color }) => theme.colors[color || "SPACE_PURE_COLOR"]};
`;
