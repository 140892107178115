import { defineMessages } from "react-intl";

const messages = defineMessages({
  close_modal: {
    id: "generic_modal.close_modal",
    defaultMessage: "Close",
  },
  server_error_description: {
    id: "generic_modal.server_error_description",
    defaultMessage: "Please try again",
  },
  server_error_title: {
    id: "generic_modal.server_error_title",
    defaultMessage: "Oops, something went wrong",
  },
  delete_account_confirmation_title: {
    id: "generic_modal.delete_account_confirmation.title",
    defaultMessage: "Are you sure you want to delete your account?",
  },
  delete_account_confirmation_description: {
    id: "generic_modal.delete_account_confirmation.description",
    defaultMessage: "Heads up! This action can not be undone.",
  },
  delete_account_confirmation_primary_label: {
    id: "generic_modal.delete_account_confirmation.primary_label",
    defaultMessage: "Confirm",
  },
  delete_account_confirmation_secondary_label: {
    id: "generic_modal.delete_account_confirmation.secondary_label",
    defaultMessage: "Cancel",
  },
  delete_account_completion_title: {
    id: "generic_modal.delete_account_completion.title",
    defaultMessage:
      "An email containing a deletion confirmation link has been sent to your email address.",
  },
  delete_account_completion_description: {
    id: "generic_modal.delete_account_completion.description",
    defaultMessage: "You will be redirected to login page shortly.",
  },
  successful_buy_nft_title: {
    id: "generic_modal.successful_buy_nft.title",
    defaultMessage: "You’ve successfuly collected your NFT.",
  },
  successful_buy_nft_primary_label: {
    id: "generic_modal.successful_buy_nft.primary_label",
    defaultMessage: "Redeem More",
  },
  successful_buy_nft_bottom_link_label: {
    id: "generic_modal.successful_buy_nft.bottom_link_label",
    defaultMessage: "See My Collection",
  },
  non_functional_confirmation_title: {
    id: "generic_modal.non_functional_confirmation_title.title",
    defaultMessage: "This action is not yet available",
  },
  authenticated_modal_primary_label: {
    id: "generic_modal.authenticated_modal_primary_label",
    defaultMessage: "Log in",
  },
  authenticated_modal_title: {
    id: "generic_modal.authenticated_modal_title",
    defaultMessage: "You're not logged in",
  },
  authenticated_modal_subtitle: {
    id: "generic_modal.authenticated_modal_subtitle",
    defaultMessage: "In order to continue you need to log in first",
  },
});

export default messages;
