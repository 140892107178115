import { ISessionState, SessionActionTypes } from "../models";
import { LOGOUT, SESSION_REQUEST, SESSION_SUCCESS, SESSION_FAILURE } from "../types";

const initialState: ISessionState = {
  data: null,
  error: null,
  isLoading: false,
};

const sessionReducer = (
  state: ISessionState = initialState,
  action: SessionActionTypes
): ISessionState => {
  switch (action.type) {
    case SESSION_REQUEST:
      return { ...state, isLoading: !state.isLoading };

    case SESSION_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        data: action.payload,
      };

    case SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default sessionReducer;
