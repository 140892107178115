import { ConfigurationType } from "typings";

const common = {
  GOOGLE_ID: process.env.REACT_APP_GOOGLE_ID,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  FACEBOOK_ID: process.env.REACT_APP_FACEBOOK_ID,
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  APPLE_ID: process.env.REACT_APP_APPLE_CLIENT_ID,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  STRIPE_SECRET_KEY: process.env.REACT_APP_STRIPE_SECRET_KEY,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  GOOGLE_GA: process.env.REACT_APP_GOOGLE_GA,
};

const configurations: ConfigurationType = {
  development: {
    info: {
      iosAppLink: "",
      androidAppLink: "",
      name: "UniqTogetherDev",
      telephone: "+306982628781",
      email: "nc@cometogether.network",
    },
    ...common,
    RECAPTCHA_SITE_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  },

  production: {
    info: {
      iosAppLink: "",
      androidAppLink: "",
      name: "UniqTogether",
      telephone: "+306982628781",
      email: "nc@cometogether.network",
    },
    ...common,
  },

  test: {
    info: {
      iosAppLink: "",
      androidAppLink: "",
      name: "UniqTogether",
      telephone: "+306982628781",
      email: "nc@cometogether.network",
    },
    ...common,
    RECAPTCHA_SITE_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  },
};

export const Config = { ...configurations[process.env.NODE_ENV] };
