import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "signup.title",
    defaultMessage: "UniqTogether",
  },
  subtitle: {
    id: "signup.subtitle",
    defaultMessage: "NFT Collectibles Portal",
  },
  login: {
    id: "signup.login",
    defaultMessage: "Log In",
  },
  send_magic_link: {
    id: "signup.send_magic_link",
    defaultMessage: "Send Me the Magic Link",
  },
  or: {
    id: "signup.or",
    defaultMessage: "Or",
  },
  inAppBrowserError: {
    id: "signup.inAppBrowserError",
    defaultMessage: `The {inAppBrowserName} in-app browser does not support Sign in with Google. Please use your default browser (e.g Google Chrome, Safari, Mozilla).`,
  },
});

export default messages;
