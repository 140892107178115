import { defineMessages } from "react-intl";
import nftRedeemCardMessages from "@app/components/molecules/NftRedeemCard/messages";

const messages = defineMessages({
  ...nftRedeemCardMessages,
  title: {
    id: "nft_details.top_overlay.title",
    defaultMessage: "Marketplace",
  },
  go_back_to_list: {
    id: "nft_details.top_overlay.go_back_to_list",
    defaultMessage: "Back To List",
  },
  details: {
    id: "nft_details.top_overlay.details",
    defaultMessage: "Details",
  },
});

export default messages;
