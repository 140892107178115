import React, { Fragment } from "react";

import { Icon } from "../Icon/Icon";
import { ButtonProps } from "./Button.interface";

import { Content, PrimaryButton, SecondaryButton, TertiaryButton } from "./Button.styles";

export const Button: React.FC<ButtonProps> = ({
  icon,
  children,
  isLoading = false,
  variant = "primary",
  iconDirection = "row",
  ...props
}) => {
  switch (variant) {
    case "primary": {
      return (
        <PrimaryButton disabled={isLoading} isLoading={isLoading} {...(props as never)}>
          <Content iconDirection={iconDirection}>
            {isLoading ? (
              <Icon name="loading" />
            ) : (
              <Fragment>
                {icon && <Icon name={icon} className="button__icon" />}
                {children}
              </Fragment>
            )}
          </Content>
        </PrimaryButton>
      );
    }

    case "secondary": {
      return (
        <SecondaryButton isLoading={isLoading} disabled={isLoading} {...(props as never)}>
          <Content iconDirection={iconDirection}>
            {isLoading ? (
              <Icon name="loading" />
            ) : (
              <Fragment>
                {icon && <Icon name={icon} className="button__icon" />}
                {children}
              </Fragment>
            )}
          </Content>
        </SecondaryButton>
      );
    }

    case "tertiary": {
      return (
        <TertiaryButton isLoading={isLoading} disabled={isLoading} {...(props as never)}>
          <Content iconDirection={iconDirection}>
            {isLoading ? (
              <Icon name="loading" />
            ) : (
              <Fragment>
                {icon && <Icon name={icon} className="button__icon" />}
                {children}
              </Fragment>
            )}
          </Content>
        </TertiaryButton>
      );
    }
  }
};
