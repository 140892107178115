import styled from "styled-components";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
import { Button } from "@app/components/atoms";

type InputType = "cardNumber" | "CVC" | "CardExpiry";

const cardInputTypeResolver = (inputType: InputType, mode: "desktop" | "mobile") => {
  if (mode === "mobile") {
    switch (inputType) {
      case "cardNumber":
        return "22.6rem";
      case "CardExpiry":
        return "8.8rem";
      case "CVC":
        return "8.8rem";
    }
  } else {
    switch (inputType) {
      case "cardNumber":
        return "32.6rem";
      case "CardExpiry":
        return "12.7rem";
      case "CVC":
        return "10.3rem";
    }
  }
};

export const Container = styled.div`
  flex-direction: column;
  margin-top: 4.2rem;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    margin-top: 1rem;
  }
`;

export const CardInfo = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
  margin-top: 1.6rem;
  color: ${({ theme }) => theme.colors.SPACE_DEEP_COLOR};
`;

export const CardTitle = styled.p`
  font-family: Signika Negative;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -0.00642857px;
  color: ${({ theme }) => theme.colors.SPACE_SHADE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-family: Philosopher;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.7rem;
  }
`;

export const PaymentCardContainer = styled.div`
  flex-direction: column;
  margin-top: 2.6rem;
  max-width: 22.6rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    max-width: 32.6rem;
  }
`;

export const CardNumberWrapper = styled.div<{ inputType: InputType }>`
  border: 3px solid ${({ theme }) => theme.colors.ONIX_SHADE_COLOR};
  box-sizing: border-box;
  border-radius: 2.1rem;
  background: ${({ theme }) => theme.colors.ONIX_DARK_COLOR};
  padding: 1.55rem 4rem 1.55rem 2rem;
  width: ${({ inputType }) => cardInputTypeResolver(inputType, "mobile")};
  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    width: ${({ inputType }) => cardInputTypeResolver(inputType, "desktop")};
  }
`;

export const EmptyDiv = styled.div`
  width: 4.2rem; //6.2rem
  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    width: 6.2rem;
  }
`;

export const CardMonthAndCVVWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-top: 1.6rem;
  align-items: center;
`;

const StripeCVCCardBased = {
  color: "#FFFFFF",
  fontWeight: "600",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#FFFFFF",
  },
  "::placeholder": {
    color: "#1E2235",
  },
  ":focus": {
    color: "#FFFFFF",
  },
};

const StripeExpiryCardBased = {
  color: "#FFFFFF",
  fontWeight: "600",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#FFFFFF",
  },
  "::placeholder": {
    color: "#1E2235",
  },
  ":focus": {
    color: "#FFFFFF",
  },
};

const StripeCardBased = {
  iconColor: "#1E2235",
  color: "#FFFFFF",
  fontWeight: "600",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#FFFFFF",
  },
  "::placeholder": {
    color: "#1E2235",
  },
  ":focus": {
    color: "#FFFFFF",
    iconColor: "#424770",
  },
};

export const StripeExpiryDateStyleOptions: StripeCardNumberElementOptions | undefined = {
  placeholder: "Exp. 08 / 25",
  style: {
    base: StripeExpiryCardBased,
  },
};

export const StripeCardStyleOptions: StripeCardNumberElementOptions | undefined = {
  showIcon: true,
  iconStyle: "solid",
  placeholder: "1111 2222 3333 4444",
  style: {
    base: StripeCardBased,
  },
};

export const StripeCVCStyleOptions: StripeCardNumberElementOptions | undefined = {
  placeholder: "CVV ...",
  style: {
    base: StripeCVCCardBased,
  },
};

export const Divider = styled.div`
  opacity: 0.1;
  margin: 4rem 0;
  border: 1px solid ${({ theme }) => theme.colors.IRIS_DARK_COLOR};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    justify-content: flex-end;
  }
`;

export const CheckoutButton = styled(Button)`
  max-width: 15.2rem;
  font-size: 1.6rem;
  height: 4.8rem;
`;

export const CancelButton = styled(Button).attrs({ variant: "tertiary" })`
  max-width: 15.2rem;
  margin-right: 2rem;
  font-size: 1.6rem !important;
  height: 4.8rem;
`;
